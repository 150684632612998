import React from 'react';
import {
  PDFViewer,
  Page,
  Text,
  View,
  Document,
  Image,
} from '@react-pdf/renderer';
import EmergencySVG from '../../assets/img/emergency-image.png';
import PanicSVG from '../../assets/img/panic-image.png';
import Logo from '../../assets/img/ipanicoPDF.png';
import { Styles } from './styles';
import OcurrencePerson from '../OcurrencePerson';
import { OccurrenceE } from '../../utils/entities';
import { getStorage } from '../../utils/storage';
import { base64ToObject } from '../../utils/converters';
import { PdfDocumentI } from '../../utils/types';
import PdfOccurrence from '../PdfOccurrence';

const PdfDocument: React.FC<PdfDocumentI> = ({ isInstitution }) => {
  const dataPDF = base64ToObject(
    getStorage(`${process.env.REACT_APP_PDF_FILE}`),
  );

  return (
    <PDFViewer style={Styles.screen}>
      <Document>
        <Page size="A4">
          {isInstitution ? (
            <View style={Styles.container}>
              <Image style={Styles.logo} src={Logo} />
              <View style={Styles.ocurrenceTitle}>
                <Image
                  src={dataPDF.type === 'PANIC' ? PanicSVG : EmergencySVG}
                  style={Styles.ocurrenceImage}
                />
                <Text>|</Text>
                <Text style={Styles.titleFont}>
                  {dataPDF.type === 'PANIC'
                    ? 'PÂNICO'
                    : `EMERGÊNCIA ${
                        dataPDF.level === 'LOW'
                          ? 'BAIXA'
                          : dataPDF.level === 'HIGH'
                            ? 'ALTA'
                            : 'MÉDIA'
                      }`}
                </Text>
              </View>
              <PdfOccurrence
                type={dataPDF.type}
                title="DESCRIÇÃO"
                institution={dataPDF.institution.profile?.name}
                address={`${dataPDF.institution.address?.street}, n°${dataPDF.institution.address?.numbering} | ${dataPDF.institution.address?.neighborhood}, ${dataPDF.institution.address?.city} - ${dataPDF.institution.address?.uf}`}
                triggerBy={dataPDF.triggers[0].user.name}
                telephone={dataPDF.institution.telephone}
                date={new Date(dataPDF.date).toLocaleDateString('pt-BR')}
                hour={new Date(dataPDF.date).toLocaleTimeString('pt-BR')}
                description={dataPDF.description}
                triggers={dataPDF.triggers}
              />
            </View>
          ) : (
            <View style={Styles.container}>
              <Image style={Styles.logo} src={Logo} />
              <View style={Styles.ocurrenceTitle}>
                <Text style={Styles.titleFont}>
                  RELATÓRIO DE OCORRÊNCIAS |{' '}
                  {`${new Date(dataPDF.dateStart).toLocaleDateString(
                    'pt-BR',
                  )} - ${new Date(dataPDF.dateEnd).toLocaleDateString(
                    'pt-BR',
                  )}`}
                </Text>
              </View>
              {dataPDF.occurrencies.map(
                (occurrence: OccurrenceE, index: number) => {
                  return (
                    <PdfOccurrence
                      key={index}
                      type={occurrence.type}
                      title={`${new Date(occurrence.date).toLocaleDateString(
                        'pt-BR',
                      )} | ${
                        occurrence.type === 'PANIC' ? 'PÂNICO' : 'EMERGÊNCIA'
                      } ${
                        occurrence.level === null
                          ? ''
                          : `- ${
                              occurrence.level === 'LOW'
                                ? 'BAIXA'
                                : occurrence.level === 'HIGH'
                                  ? 'ALTA'
                                  : 'MÉDIA'
                            }`
                      }`}
                      institution={occurrence.institution.profile?.name}
                      address={`${occurrence.institution.address?.street}, n°${occurrence.institution.address?.numbering} | ${occurrence.institution.address?.neighborhood}, ${occurrence.institution.address?.city} - ${occurrence.institution.address?.uf}`}
                      triggerBy={occurrence.triggers[0].user.name}
                      telephone={occurrence.institution.telephone}
                      date={new Date(occurrence.date).toLocaleDateString(
                        'pt-BR',
                      )}
                      hour={new Date(occurrence.date).toLocaleTimeString(
                        'pt-BR',
                      )}
                      description={occurrence.description}
                      triggers={occurrence.triggers}
                    />
                  );
                },
              )}
            </View>
          )}
        </Page>
      </Document>
    </PDFViewer>
  );
};

export default PdfDocument;
