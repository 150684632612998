import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Login from '../pages/public/login';
import ForgotPassword from '../pages/public/forgot-password';
import EmailVerification from '../pages/public/email-verification';
import Register from '../pages/public/register';
import RegisterVerification from '../pages/public/register-verification';
import RegisterConfirm from '../pages/public/register-confirm';
import SecurityBodyLayout from '../pages/private/security-body/layout';
import SecurityBodyHome from '../pages/private/security-body/home';
import InstitutionLayout from '../pages/private/institution/layout';
import InstitutionDashboard from '../pages/private/institution/dashboard';
import Ocurrences from '../pages/private/institution/occurrence';
import Assistance from '../pages/private/institution/assistance';
import Validations from '../pages/private/institution/validation';
import Rules from '../pages/private/institution/rules';
import Map from '../pages/private/institution/map';
import InstitutionRegisterData from '../pages/private/institution/register-data';
import AdminLayout from '../pages/private/admin/layout';
import AdminDashboard from '../pages/private/admin/dashboard';
import AdminRegisterData from '../pages/private/admin/register-data';
import RegisterClient from '../pages/private/admin/register-client';
import SecurityServices from '../pages/private/admin/security-service';
import Reports from '../pages/private/admin/report';
import PrivateRoute from './PrivateRoute';
import PdfDocument from '../components/PdfDocument';

const Router: React.FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <PrivateRoute isPrivate={false}>
              <Login />
            </PrivateRoute>
          }
        />
        <Route
          path="/email-verification"
          element={
            <PrivateRoute isPrivate={false}>
              <EmailVerification />
            </PrivateRoute>
          }
        />
        <Route
          path="/forgot-password"
          element={
            <PrivateRoute isPrivate={false}>
              <ForgotPassword />
            </PrivateRoute>
          }
        />
        <Route
          path="/register"
          element={
            <PrivateRoute isPrivate={false}>
              <Register />
            </PrivateRoute>
          }
        />
        <Route
          path="/register-verification"
          element={
            <PrivateRoute isPrivate={false}>
              <RegisterVerification />
            </PrivateRoute>
          }
        />
        <Route
          path="/register-confirm"
          element={
            <PrivateRoute isPrivate={false}>
              <RegisterConfirm />
            </PrivateRoute>
          }
        />

        <Route
          path="/security-bodies"
          element={
            <PrivateRoute isPrivate>
              <SecurityBodyLayout />
            </PrivateRoute>
          }
        >
          <Route index element={<SecurityBodyHome />} />
        </Route>

        <Route
          path="/security-bodies/pdf"
          element={
            <PrivateRoute isPrivate>
              <PdfDocument isInstitution={false} />
            </PrivateRoute>
          }
        />

        <Route
          path="/security-bodies/single-pdf"
          element={
            <PrivateRoute isPrivate>
              <PdfDocument isInstitution={true} />
            </PrivateRoute>
          }
        />

        <Route
          path="/institution"
          element={
            <PrivateRoute isPrivate>
              <InstitutionLayout />
            </PrivateRoute>
          }
        >
          <Route index path="dashboard" element={<InstitutionDashboard />} />
          <Route path="occurrence" element={<Ocurrences />} />
          <Route path="assistance" element={<Assistance />} />
          <Route path="validation" element={<Validations />} />
          <Route path="rules" element={<Rules />} />
          <Route path="map" element={<Map />} />
          <Route path="register-data" element={<InstitutionRegisterData />} />
        </Route>

        <Route
          path="/institucion/pdf"
          element={
            <PrivateRoute isPrivate>
              <PdfDocument isInstitution />
            </PrivateRoute>
          }
        />

        <Route
          path="/admin"
          element={
            <PrivateRoute isPrivate>
              <AdminLayout />
            </PrivateRoute>
          }
        >
          <Route index path="dashboard" element={<AdminDashboard />} />
          <Route path="register-client" element={<RegisterClient />} />
          <Route path="security-service" element={<SecurityServices />} />
          <Route path="report" element={<Reports />} />
          <Route path="register-data" element={<AdminRegisterData />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
