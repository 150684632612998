import styled from 'styled-components';
import { Avatar, Button } from 'antd';

const ProfileContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const EditButton = styled(Button)`
  position: relative;
  top: 80px;
  right: 30px;
  z-index: 1;
`;

const ProfileImage = styled(Avatar)`
  width: 30%;
  height: 30%;
  margin-bottom: 1%;
  z-index: 0;
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 4%;
`;

const ModalConfirm = styled(Button)`
  margin-top: 4%;
  background-color: #33c455 !important;
`;

const AvatarEditorArea = {
  avatarStyle: {
    display: 'flex',
    justifyContent: 'center',
    border: 'solid',
    borderWidth: 0.5,
    borderStyle: 'dashed',
  },
};

export {
  ProfileContainer,
  EditButton,
  ProfileImage,
  ModalContent,
  ModalConfirm,
  AvatarEditorArea,
};
