import { StyleSheet } from '@react-pdf/renderer';

const Styles = StyleSheet.create({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingVertical: 3,
    paddingHorizontal: 7,
    fontSize: 10,
    borderBottom: 1,
    borderBottomColor: '#e8e8e8',
  },
  infoCol: {
    display: 'flex',
    flexDirection: 'row',
  },
});

export { Styles };
