import styled from 'styled-components';
import { WarningsI } from '../../utils/types';

const Container = styled.div<WarningsI>`
  position: relative;
  width: 22%;
  height: 70%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding-top: 3%;
  padding-right: 3%;
  padding-left: 3%;
  padding-bottom: 2%;
  margin-left: 2%;
  margin-right: 2%;
  scale: 1;
  transition: 0.1s;
  background-color: ${props =>
    (props.value === 'informativo' && '#18B10A') ||
    (props.value === 'aviso' && '#FFD706') ||
    (props.value === 'urgente' && '#FF6B6A')};
  &:hover {
    scale: 1.1;
  }
  @media (max-width: 1500px) {
    width: 25%;
    height: 90%;
  }
`;

const Pin = styled.img<WarningsI>`
  position: absolute;
  top: -5px;
  display: ${props => props.type === 'admin' && 'none'};
`;

const Close = styled.img<WarningsI>`
  cursor: pointer;
  position: absolute;
  top: -4px;
  right: -3px;
  scale: 1;
  transition: 0.1s;
  display: ${props => props.type === 'instituicao' && 'none'};
  svg {
    height: 70px;
  }
  &:hover {
    scale: 1.5;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  min-height: 120px;
  height: auto;
`;

const Title = styled.p<WarningsI>`
  height: 30%;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  font-size: 13px;
  font-weight: 600;
  word-break: break-all;
  color: ${props =>
    props.value === 'informativo' || props.value === 'urgente'
      ? '#FFFF'
      : '#3D3D3D'};
`;

const Text = styled.p<WarningsI>`
  height: 70%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  font-size: 11px;
  text-align: justify;
  word-break: break-all;
  color: ${props =>
    props.value === 'informativo' || props.value === 'urgente'
      ? '#FFFF'
      : '#3D3D3D'};
`;

const PublicationDate = styled.p<WarningsI>`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  font-size: 8px;
  text-align: justify;
  margin-top: 8%;
  color: ${props =>
    props.value === 'informativo' || props.value === 'urgente'
      ? '#FFFF'
      : '#3D3D3D'};
`;

const IconColor = {
  cancel: {
    color: '#c53f3f',
  },
};

const ButtonColor = {
  cancel: {
    background: '#c53f3f',
  },
};

export {
  Container,
  Pin,
  Close,
  Content,
  Title,
  Text,
  PublicationDate,
  IconColor,
  ButtonColor,
};
