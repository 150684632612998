import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
import Router from './router/router';
import { ConfigProvider } from 'antd';
import ptBR from 'antd/lib/locale/pt_BR';
import './global.css';
import { base64ToObject } from './utils/converters';
import { getStorage } from './utils/storage';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

const client = new ApolloClient({
  uri: process.env.REACT_APP_URI,
  cache: new InMemoryCache({
    addTypename: false,
  }),
  headers: {
    Authorization:
      getStorage(`${process.env.REACT_APP_AUTH}`) !== undefined
        ? base64ToObject(getStorage(`${process.env.REACT_APP_AUTH}`)).token
        : undefined,
  },
});

root.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <ConfigProvider locale={ptBR}>
        <Router />
      </ConfigProvider>
    </ApolloProvider>
  </React.StrictMode>,
);

reportWebVitals();
