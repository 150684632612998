import styled from 'styled-components';
import { Input, Button, Form, Alert } from 'antd';
import img from '../../../assets/img/login-background.png';

const LoginPage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background-image: url(${img});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
`;

const Container = styled.div`
  width: 410px;
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: rgba(255, 255, 255, 0.048);
  backdrop-filter: blur(15.5px);
  box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.18);
  border-radius: 10px;
  border: 1px solid #b0b0b0;
  padding: 30px 13px;
`;

const LoginLogo = styled.div`
  display: flex;
  align-items: end;
  justify-content: center;
  margin-top: 16px;
  margin-bottom: 45px;
`;

const LoginForm = styled.div`
  height: 65%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const FormItem = styled(Form.Item)`
  .ant-form-item-label {
    padding: 0;
  }
  .ant-form-item-label * {
    color: #ffff !important;
    font-weight: 600;
  }
`;

const ErrorAlert = styled(Alert)`
  margin-bottom: 5%;
  background-color: #fac3c3;
`;

const LoginButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000 !important;
  width: 350px !important;
  height: 45px !important;
`;

const Options = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
`;

const Register = styled.div`
  justify-content: center;
  display: flex;
  flex-direction: row;
  margin-bottom: 8%;
`;

const QuestionAccount = styled.p`
  font-size: 14px;
  color: #ffff;
`;

const Footer = styled.div`
  height: 15%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 48px;
`;

const InfoContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 15px;
  flex-direction: row;
`;

const SubContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 1px;
  margin-top: 1%;
  flex-direction: column;
`;

const Copyright = styled.p`
  font-size: 10px;
  color: #ffff;
  margin-top: 3%;
  margin-bottom: 1%;
`;

const CompanyName = styled.h3`
  font-size: 21px;
  color: #fff;
  font-family: 'AddCityboy';
`;

const TitleSupport = styled.h3`
  font-size: 16px;
  color: #fff;
`;

const PhoneTitle = styled.h5`
  font-size: 14px;
  color: #fff;
`;

const Pipe = styled.p`
  color: #ffff;
  font-size: 23px;
`;

const Version = styled.p`
  color: #ffff;
  font-size: 11px;
`;

export {
  LoginPage,
  Container,
  LoginLogo,
  LoginForm,
  FormItem,
  ErrorAlert,
  LoginButton,
  Options,
  Register,
  QuestionAccount,
  Footer,
  InfoContainer,
  SubContainer,
  Copyright,
  CompanyName,
  TitleSupport,
  PhoneTitle,
  Pipe,
  Version,
};
