import { Buffer } from 'buffer';

function base64ToObject(value: string) {
  if (value !== undefined)
    return JSON.parse(Buffer.from(value, 'base64').toString('utf-8'));
  return {};
}

function objectToBase64(value: any) {
  return Buffer.from(JSON.stringify(value)).toString('base64');
}

function captalizeString(value: string) {
  const fragmentString = value.toLocaleLowerCase().split(' ');
  for (let k = 0; k < fragmentString.length; k++) {
    fragmentString[k] =
      fragmentString[k].charAt(0).toUpperCase() + fragmentString[k].slice(1);
  }

  return fragmentString.join(' ');
}

function base64ToBlob(base64: string) {
  return fetch(base64)
    .then(resp => {
      return resp.blob();
    })
    .catch(() => {
      return new Blob();
    });
}

export { base64ToObject, objectToBase64, captalizeString, base64ToBlob };
