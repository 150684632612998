import React from 'react';
import { CardI } from '../../utils/types';
import { Container, Title, Content, Cont, Icon } from './styles';

const Card: React.FC<CardI> = ({ title, cont, icon }) => {
  return (
    <Container>
      <Title>{title}</Title>
      <Content>
        <Cont>{cont}</Cont>
        <Icon src={icon} alt="" />
      </Content>
    </Container>
  );
};

export default Card;
