import React from 'react';
import { Container, Content, Title, ReportButton } from './styles';
import { ReportCardI } from '../../utils/types';

const ReportCard: React.FC<ReportCardI> = ({ title, icon }) => {
  return (
    <Container>
      <Content>
        <Title>{title}</Title>
        <img src={icon} alt="" />
        <ReportButton type="primary" size="small">
          Emitir relatório
        </ReportButton>
      </Content>
    </Container>
  );
};

export default ReportCard;
