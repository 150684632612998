import styled from 'styled-components';
import PathSVG from '../../assets/img/inform-path.svg';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1%;
  border-radius: 15px;
  position: absolute;
  top: 64px;
  right: 0;
  z-index: 10;
  background-color: #ffffff;
  height: calc(100% - 64px);
  overflow-y: scroll;
  width: 40%;
`;

const InstructionsContainer = styled.ul`
  display: flex;
  flex-direction: column;
  list-style: none;
`;

const Instruction = styled.li`
  position: relative;
  width: 100%;
  padding-left: 5%;
  margin-bottom: 1%;

  &::before {
    position: absolute;
    content: '';
    display: inline-block;
    background-image: url(${PathSVG});
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    width: 18px;
    height: 18px;
    left: 0;
  }
`;

const InstructionText = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 5%;

  span,
  p:last-child {
    white-space: nowrap;
  }
`;

const CloseButton = styled.button`
  cursor: pointer;
  border: 1px solid #000000;
  border-radius: 6px;
  background-color: #ffffff;
  font-size: 15px;
  width: fit-content;
  margin: 0 auto 3%;
  padding: 1% 2%;
  /* justify-self: flex-end; */

  &:hover {
    text-decoration: underline;
  }
`;

export {
  Container,
  InstructionsContainer,
  Instruction,
  CloseButton,
  InstructionText,
};
