import React, { useEffect, useState } from 'react';
import Fuse from 'fuse.js';
import {
  Container,
  AddButton,
  ViewModal,
  View,
  ModalForm,
  InfosContainer,
  Infos1,
  Infos2,
  FormItem,
  ContainerButton,
  SaveButton,
  SearchContainer,
  SearchInput,
  Cards,
  Loading,
} from './styles';
import AssistanceService from '../../../../components/AssistanceService';
import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { RiCustomerService2Fill, RiServiceLine } from 'react-icons/ri';
import { Input, Select, Spin, Divider } from 'antd';
import { BiShieldAlt } from 'react-icons/bi';
import { useMutation, useQuery } from '@apollo/client';
import {
  ADD_ASSISTANCE_SERVICE,
  ASSISTANCE_SERVICES,
  DELETE_ASSISTANCE_SERVICE,
  UPDATE_ASSISTANCE_SERVICE,
} from '../../../../utils/queries/institutionQueries';
import { AssistanceServiceE } from '../../../../utils/entities';
import { maskCellPhone } from '../../../../utils/masks';
import { base64ToObject } from '../../../../utils/converters';
import { getStorage } from '../../../../utils/storage';

const Assistance: React.FC = () => {
  const auth = base64ToObject(getStorage(`${process.env.REACT_APP_AUTH}`));
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [assistanceService, setAssistanceService] = useState<
    AssistanceServiceE | undefined
  >(undefined);
  const [assistanceServices, setAssistanceServices] = useState<
    AssistanceServiceE[]
  >([]);
  const [assistancesAll, setAssistanceAll] = useState<AssistanceServiceE[]>([]);
  const [loading, setLoading] = useState(true);
  const queryAssistanceService = useQuery(ASSISTANCE_SERVICES);
  const [addAssistanceService] = useMutation(ADD_ASSISTANCE_SERVICE);
  const [deleteAssistanceService] = useMutation(DELETE_ASSISTANCE_SERVICE);
  const [updateAssistanceService] = useMutation(UPDATE_ASSISTANCE_SERVICE);
  const [form] = ModalForm.useForm();

  function showModal() {
    setIsModalOpen(true);
  }

  function addAssistance() {
    addAssistanceService({
      variables: {
        assistanceServiceInput: assistanceService,
        profile: auth.id,
      },
    }).then(() => {
      queryAssistanceService.refetch();
      setAssistanceService(undefined);
    });

    setIsModalOpen(false);
  }

  function updateAssistance(assistanceService: any) {
    updateAssistanceService({
      variables: {
        assistanceServiceInput: {
          _id: assistanceService._id,
          name: assistanceService?.name,
          email: assistanceService?.email,
          type: assistanceService?.type,
          telephone: assistanceService?.telephone,
          whatsapp: assistanceService?.whatsapp,
        },
      },
    }).then(() => {
      queryAssistanceService.refetch();
    });
  }

  function deleteAssistance(id: string) {
    deleteAssistanceService({
      variables: {
        idAssistanceService: id,
      },
    }).then(() => {
      queryAssistanceService.refetch();
    });
  }

  useEffect(() => {
    if (queryAssistanceService.loading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [queryAssistanceService.loading]);

  useEffect(() => {
    setAssistanceServices(
      queryAssistanceService.data?.getAssistanceServiceAll === undefined
        ? []
        : queryAssistanceService.data?.getAssistanceServiceAll,
    );
    setAssistanceAll(
      queryAssistanceService.data?.getAssistanceServiceAll === undefined
        ? []
        : queryAssistanceService.data?.getAssistanceServiceAll,
    );
  }, [queryAssistanceService.data?.getAssistanceServiceAll]);

  function search(value: string) {
    if (value.length > 0) {
      const searched = new Fuse(assistancesAll, {
        keys: ['name', 'telephone'],
        includeMatches: true,
        includeScore: true,
        useExtendedSearch: true,
      });
      setAssistanceServices(
        searched.search(value).map(items => {
          return items.item;
        }),
      );
    } else {
      setAssistanceServices(assistancesAll);
    }
  }

  return (
    <Container>
      <Spin spinning={loading} fullscreen indicator={<Loading />} />
      <AddButton
        icon={<PlusOutlined />}
        shape="square"
        type="primary"
        onClick={showModal}
      />
      <SearchContainer>
        <SearchInput
          placeholder=" Pesquise..."
          size="large"
          prefix={<SearchOutlined />}
          onChange={e => {
            search(e.target.value);
          }}
        />
      </SearchContainer>
      <Divider orientation="left">
        <b>| GERENCIAMENTO DOS SERVIÇOS DE ASSISTÊNCIA</b>
      </Divider>
      <View>
        <Cards>
          {assistanceServices.map((assistanceService: AssistanceServiceE) => (
            <AssistanceService
              key={assistanceService._id}
              id={assistanceService._id}
              name={assistanceService.name}
              type={
                assistanceService.type === 'SECURITY'
                  ? 'Segurança'
                  : assistanceService.type === 'HEALTH'
                    ? 'Saúde'
                    : 'Assistência'
              }
              number={assistanceService.telephone}
              objectUpdate={assistanceService}
              onUpdate={updateAssistance}
              onDelete={deleteAssistance}
              icon={
                assistanceService.type === 'SECURITY' ? (
                  <BiShieldAlt />
                ) : assistanceService.type === 'HEALTH' ? (
                  <RiServiceLine />
                ) : (
                  <RiCustomerService2Fill />
                )
              }
            />
          ))}
        </Cards>
      </View>

      <ViewModal
        centered
        open={isModalOpen}
        onCancel={() => {
          setIsModalOpen(false);
        }}
        footer={null}
        afterClose={() => form.resetFields()}
      >
        <ModalForm
          form={form}
          layout="vertical"
          onFinish={() => {
            addAssistance();
          }}
          autoComplete="off"
        >
          <InfosContainer>
            <Infos1>
              <FormItem
                label="Nome:"
                name="nome"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input
                  placeholder="Digite o Nome"
                  value={assistanceService?.name}
                  onChange={(e: any) => {
                    setAssistanceService({
                      ...assistanceService,
                      name: e.target.value,
                    });
                  }}
                />
              </FormItem>
              <FormItem
                label="Tipo:"
                name="tipo"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select
                  placeholder="Selecione o tipo de serviço"
                  value={assistanceService?.type}
                  options={[
                    { value: 'SECURITY', label: 'Segurança' },
                    { value: 'HEALTH', label: 'Saúde' },
                    { value: 'ASSISTANCE', label: 'Assistência' },
                  ]}
                  onChange={(value: string) => {
                    setAssistanceService({ ...assistanceService, type: value });
                  }}
                />
              </FormItem>
              <FormItem
                label="Email:"
                name="email"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input
                  type="email"
                  placeholder="Digite o Email"
                  value={assistanceService?.email}
                  onChange={(e: any) => {
                    setAssistanceService({
                      ...assistanceService,
                      email: e.target.value,
                    });
                  }}
                />
              </FormItem>
            </Infos1>
            <Infos2>
              <FormItem
                label="Telefone:"
                name="telefone"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input
                  placeholder="Digite o Telefone"
                  value={assistanceService?.telephone}
                  onChange={(e: any) => {
                    form.setFieldValue(
                      'telefone',
                      maskCellPhone(e.target.value),
                    );
                    setAssistanceService({
                      ...assistanceService,
                      telephone: maskCellPhone(e.target.value),
                    });
                  }}
                />
              </FormItem>
              <FormItem
                label="Whatsapp:"
                name="whatsapp"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input
                  placeholder="Digite o Whatsapp:"
                  value={assistanceService?.whatsapp}
                  onChange={(e: any) => {
                    form.setFieldValue(
                      'whatsapp',
                      maskCellPhone(e.target.value),
                    );
                    setAssistanceService({
                      ...assistanceService,
                      whatsapp: maskCellPhone(e.target.value),
                    });
                  }}
                />
              </FormItem>
            </Infos2>
          </InfosContainer>
          <ContainerButton>
            <SaveButton type="primary" htmlType="submit">
              Adicionar
            </SaveButton>
          </ContainerButton>
        </ModalForm>
      </ViewModal>
    </Container>
  );
};

export default Assistance;
