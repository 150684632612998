import React, { useEffect, useState } from 'react';
import {
  AvatarEditorArea,
  EditButton,
  ModalConfirm,
  ModalContent,
  ProfileContainer,
  ProfileImage,
} from './styles';
import { EditOutlined, UserOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import { AvatarEditorI } from '../../utils/types';
import AvatarEdit from 'react-avatar-edit';
import { base64ToBlob } from '../../utils/converters';
import { Api } from '../../services/api';

const AvatarEditor: React.FC<AvatarEditorI> = ({
  source = null,
  onUpload = undefined,
  profile,
  folder,
  token,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [imgCrop, setImgCrop] = useState(false || null);
  const [img, setImg] = useState<any[]>([]);

  useEffect(() => {
    async function converterToBlob() {
      const multipartForm = new FormData();
      multipartForm.append('image', await base64ToBlob(img.at(-1).imgCrop));
      multipartForm.append('id', `${profile}`);
      multipartForm.append('profile', `${folder}`);
      Api.post('/upload/profile/', multipartForm, {
        headers: {
          Authorization: token,
        },
      })
        .then(() => {
          location.reload();
        })
        .catch(() => {});
    }

    if (img.length > 0 && onUpload === undefined) {
      converterToBlob();
    } else {
      if (img.length > 0 && onUpload !== undefined)
        onUpload?.(img.at(-1).imgCrop);
    }
  }, [img]);

  function showModal() {
    setIsModalOpen(true);
  }

  function handleCancel() {
    setIsModalOpen(false);
  }

  function onCrop(view: any) {
    setImgCrop(view);
  }

  function onClose() {
    setImgCrop(null);
  }

  function saveImg() {
    setImg([...img, { imgCrop }]);
    setIsModalOpen(false);
  }

  const showImg = img.map(item => item.imgCrop);

  return (
    <>
      <ProfileContainer>
        {!showImg.length || showImg.at(-1) == null ? (
          source !== null && showImg.at(-1) == null ? (
            <ProfileImage size={100} icon={<img src={source} />} />
          ) : (
            <ProfileImage size={100} icon={<UserOutlined />} />
          )
        ) : (
          <ProfileImage size={100} draggable={false} src={showImg.at(-1)} />
        )}

        <EditButton
          shape="circle"
          icon={<EditOutlined />}
          onClick={showModal}
        />
      </ProfileContainer>
      <Modal
        title="Imagem de Perfil"
        open={isModalOpen}
        onCancel={handleCancel}
        footer={null}
        width={650}
      >
        <ModalContent>
          <AvatarEdit
            width={500}
            height={250}
            onClose={onClose}
            onCrop={onCrop}
            borderStyle={AvatarEditorArea.avatarStyle}
            exportAsSquare={true}
          />
          <ModalConfirm size="large" type="primary" onClick={saveImg}>
            Alterar
          </ModalConfirm>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AvatarEditor;
