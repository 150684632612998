import React, { useState } from 'react';
import { Switch } from 'antd';
import { ConfigSwitchI } from '../../utils/types';
import { Container, Description, SwitchContainer, Title } from './styles';

const ConfigSwitch: React.FC<ConfigSwitchI> = ({
  title,
  value,
  description,
  onSwitch,
}) => {
  const [select, setSelected] = useState<any>();

  return (
    <Container>
      <Title>{title}</Title>
      <SwitchContainer>
        <Switch
          value={value}
          onChange={value => {
            onSwitch(value);
          }}
        />
      </SwitchContainer>
      <Description>
        <p>{description}</p>
      </Description>
    </Container>
  );
};

export default ConfigSwitch;
