import React, { useEffect, useState } from 'react';
import {
  CheckButton,
  Container,
  DefinitionsContent,
  InputLocation,
  InputsContainer,
  Loading,
  TriggerLocationContent,
} from './styles';
import ConfigSwitch from '../../../../components/ConfigSwitch';
import { CheckOutlined } from '@ant-design/icons';
import { useQuery, useMutation } from '@apollo/client';
import {
  RULES_INSTITUTION_BY_ID,
  UPDATE_INSTITUTION,
} from '../../../../utils/queries/institutionQueries';
import { base64ToObject } from '../../../../utils/converters';
import { getStorage } from '../../../../utils/storage';
import { InstitutionE } from '../../../../utils/entities';
import { Form, Slider, Spin, notification, Divider, Tooltip } from 'antd';

const Rules: React.FC = () => {
  const auth = base64ToObject(getStorage(`${process.env.REACT_APP_AUTH}`));
  const [points, setPoints] = useState(new Array<number>(2));
  const [institution, setInstitution] = useState<InstitutionE>();
  const [loading, setLoading] = useState(true);
  const [updateInstitution, updateInstitutionReturn] =
    useMutation(UPDATE_INSTITUTION);
  const queryRulesInstitution = useQuery(RULES_INSTITUTION_BY_ID, {
    variables: {
      id: auth.id,
    },
  });

  useEffect(() => {
    setInstitution(queryRulesInstitution.data?.getInstitutionByProfileId);
    setPoints(
      queryRulesInstitution.data?.getInstitutionByProfileId?.address
        .localization.coordinates === undefined
        ? new Array<number>(2)
        : queryRulesInstitution.data?.getInstitutionByProfileId?.address
            .localization.coordinates,
    );
  }, [queryRulesInstitution.data?.getInstitutionByProfileId]);

  useEffect(() => {
    queryRulesInstitution.refetch();
    if (queryRulesInstitution.loading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [queryRulesInstitution.loading]);

  // useEffect(() => {
  //   setInstitution({
  //     ...institution,
  //     address: {
  //       ...institution?.address,
  //       localization: {
  //         ...institution?.address?.localization,
  //         coordinates: points,
  //       },
  //     },
  //   });
  // }, [points]);

  function update() {
    updateInstitution({
      variables: {
        institutionInput: institution,
      },
      refetchQueries: [
        { query: RULES_INSTITUTION_BY_ID, variables: { id: auth.id } },
      ],
    });
    notification.success({
      message: 'Regras salvas',
    });
  }

  return (
    <Container>
      <Spin spinning={loading} fullscreen indicator={<Loading />} />
      <Divider orientation="left">
        <b>| GERENCIAMENTO DE REGRAS - APP</b>
      </Divider>
      <DefinitionsContent>
        <ConfigSwitch
          title="Acionar Pânico"
          description="Permitir que alunos acionem botão de PÂNICO no App"
          value={institution?.rules?.activePanic}
          onSwitch={value => {
            setInstitution({
              ...institution,
              rules: {
                ...institution?.rules,
                activePanic: value,
              },
            });
          }}
        />
        <ConfigSwitch
          title="Acionar Emergência"
          description="Permitir que alunos acionem o botão de EMERGÊNCIA no App"
          value={institution?.rules?.activeEmergency}
          onSwitch={value => {
            setInstitution({
              ...institution,
              rules: {
                ...institution?.rules,
                activeEmergency: value,
              },
            });
          }}
        />
        <ConfigSwitch
          title="Restringir acesso ao Chat"
          description="Restringir o acesso dos professores ao CHAT no App"
          value={institution?.rules?.restrictChat}
          onSwitch={value => {
            setInstitution({
              ...institution,
              rules: {
                ...institution?.rules,
                restrictChat: value,
              },
            });
          }}
        />
        <ConfigSwitch
          title="Edição de dados"
          description="Permitir que usuários possam EDITAR dados pessoais via App"
          value={institution?.rules?.activeDataEdition}
          onSwitch={value => {
            setInstitution({
              ...institution,
              rules: {
                ...institution?.rules,
                activeDataEdition: value,
              },
            });
          }}
        />
        <TriggerLocationContent>
          <Form layout="vertical">
            <InputsContainer>
              <Form.Item label="Latitude:">
                <InputLocation
                  value={points[1]}
                  placeholder="Digite a Latitude"
                  onChange={value => {
                    Object.freeze(points);
                    const updatePoints = [...points];
                    updatePoints[1] = Number(value);
                    setPoints(updatePoints);
                    setInstitution({
                      ...institution,
                      address: {
                        ...institution?.address,
                        localization: {
                          ...institution?.address?.localization,
                          coordinates: updatePoints,
                        },
                      },
                    });
                  }}
                />
              </Form.Item>
              <Form.Item label="Longitude:">
                <InputLocation
                  value={points[0]}
                  placeholder="Digite a Longitude"
                  onChange={value => {
                    Object.freeze(points);
                    const updatePoints = [...points];
                    updatePoints[0] = Number(value);
                    setPoints(updatePoints);
                    setInstitution({
                      ...institution,
                      address: {
                        ...institution?.address,
                        localization: {
                          ...institution?.address?.localization,
                          coordinates: updatePoints,
                        },
                      },
                    });
                  }}
                />
              </Form.Item>
            </InputsContainer>
            <Form.Item label="Perímetro de Acionamento (100 à 1000 m²):">
              <Slider
                value={institution?.rules?.limitActuation}
                min={100}
                max={1000}
                onChange={value => {
                  setInstitution({
                    ...institution,
                    rules: {
                      ...institution?.rules,
                      limitActuation: value,
                    },
                  });
                }}
              />
            </Form.Item>
          </Form>
        </TriggerLocationContent>
        {/* <ConfigSwitch
          title="Restringir acionamentos"
          description="Restringir acionamento do Pânico/Emergência ao ambiente escolar"
          value={institution?.rules?.restrictTriggerEnvSchooling}
          onSwitch={value => {
            setInstitution({
              ...institution,
              rules: {
                ...institution?.rules,
                restrictTriggerEnvSchooling: value,
              },
            });
          }}
        /> */}
      </DefinitionsContent>
      {/* <Divider orientation="left">
        <b>Local de Acionamento</b>
      </Divider> */}

      <Tooltip title="Salvar Alterações">
        <CheckButton
          onClick={update}
          shape="square"
          type="primary"
          icon={<CheckOutlined />}
        />
      </Tooltip>
    </Container>
  );
};

export default Rules;
