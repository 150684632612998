import styled from 'styled-components';
import { Button, TreeSelect } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { FaArrowLeftLong } from 'react-icons/fa6';

const Container = styled.div`
  width: 80vw;
  display: flex;
  flex-direction: row;
`;

const BackButton = styled(FaArrowLeftLong)`
  font-size: 24px;
  position: fixed;
  left: 2%;
  top: 12%;
  cursor: pointer;
  &:hover {
    transition: 0.15s;
    color: #808080;
  }
`;

const Loading = styled(LoadingOutlined)`
  font-size: 64px !important;
  color: #000;
`;

const Col1 = styled.div`
  width: 45%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const Col2 = styled.div`
  width: 55%;
  display: flex;
  justify-content: center;
`;

const SaveButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #33c455 !important;
  margin-top: 4%;
  width: 60%;
`;

const StateHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 7%;
`;

const Flag = styled.img`
  margin-right: 3%;
  width: 15%;
`;

const ListFlag = styled.img`
  margin-right: 3%;
  width: 5%;
`;

const Title = styled.h1`
  font-size: 26px;
  @media (max-width: 1500px) {
    font-size: 20px;
  }
`;

const StateInfos = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const Item = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 90%;
`;

const Regiao = styled.div`
  display: flex;
  flex-direction: column;
`;

const Municipios = styled.div`
  display: flex;
  flex-direction: column;
`;

const SelectMunicipiosContainer = styled.div`
  width: 37%;
`;

const SelectMunicipios = styled(TreeSelect)`
  width: 100%;
  max-height: 13vh;
  overflow-y: auto;
  .ant-select-selector {
    max-height: 13vh;
    overflow-y: auto;
  }
`;

const RegisteredStates = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10%;
`;

const ListItem = styled.div`
  display: flex;
  align-items: center;
  margin-top: 5%;
`;

const Area = styled.p`
  font-size: 20px;
  font-weight: 600;
  margin-top: 7%;
  margin-bottom: 2%;
  @media (max-width: 1500px) {
    font-size: 16px;
  }
`;

const StateArea = styled.img`
  width: 20%;
`;

const Label = styled.p`
  font-size: 20px;
  font-weight: 600;
  @media (max-width: 1500px) {
    font-size: 14px;
  }
`;

const Info = styled.p`
  color: #707070;
  font-size: 16px;
  @media (max-width: 1500px) {
    font-size: 12px;
  }
`;

export {
  Container,
  BackButton,
  Loading,
  Col1,
  Col2,
  SaveButton,
  StateHeader,
  Flag,
  ListFlag,
  Title,
  StateInfos,
  Item,
  Regiao,
  Municipios,
  SelectMunicipiosContainer,
  SelectMunicipios,
  RegisteredStates,
  ListItem,
  Area,
  StateArea,
  Label,
  Info,
};
