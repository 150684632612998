import React, { useEffect, useState } from 'react';
import { AssistanceServiceI } from '../../utils/types';
import { Input, message, Popconfirm, Select } from 'antd';
import {
  Container,
  UpdateButton,
  DeleteButton,
  ViewModal,
  ModalForm,
  InfosContainer,
  Infos1,
  Infos2,
  FormItem,
  ContainerButton,
  SaveButton,
  IconColor,
  ButtonColor,
  AvatarContainer,
  TypeServiceAvatar,
  Infos,
  Text,
  Options,
  Card,
} from './styles';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { AssistanceServiceE } from '../../utils/entities';
import { maskCellPhone } from '../../utils/masks';

const AssistanceService: React.FC<AssistanceServiceI> = ({
  name,
  icon,
  type,
  number,
  objectUpdate,
  id,
  onDelete,
  onUpdate,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [assistanceService, setAssistanceService] = useState<
    AssistanceServiceE | undefined
  >(objectUpdate);
  const [editForm] = ModalForm.useForm();

  useEffect(() => {
    editForm.setFieldsValue({
      nome: assistanceService?.name,
      tipo: assistanceService?.type,
      email: assistanceService?.email,
      telefone: assistanceService?.telephone,
      whatsapp: assistanceService?.whatsapp,
    });
  }, [0]);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
    onUpdate?.(assistanceService!);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const confirm = () => {
    message.success('Removido com sucesso');
  };

  const cancel = () => {
    message.error('Remoção cancelada');
  };

  return (
    <Container>
      <Card>
        <AvatarContainer>
          <TypeServiceAvatar shape="square" size={76} icon={icon} />
        </AvatarContainer>
        <Infos>
          <Text>{name}</Text>
          <Text>{type}</Text>
          <Text>{number}</Text>
        </Infos>
      </Card>
      <Options>
        <UpdateButton
          type="primary"
          onClick={() => {
            showModal();
          }}
        >
          Atualizar
        </UpdateButton>
        <ViewModal
          centered
          open={isModalOpen}
          onCancel={handleCancel}
          footer={null}
        >
          <ModalForm onFinish={handleOk} layout="vertical" form={editForm}>
            <InfosContainer>
              <Infos1>
                <FormItem
                  label="Nome:"
                  name="nome"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input
                    value={assistanceService?.name}
                    onChange={(e: any) => {
                      setAssistanceService({
                        ...assistanceService,
                        name: e.target.value,
                      });
                    }}
                  />
                </FormItem>
                <FormItem
                  label="Tipo:"
                  name="tipo"
                  rules={[
                    {
                      required: true,
                      message: 'Selecione o tipo',
                    },
                  ]}
                >
                  <Select
                    placeholder="Selecione o tipo de serviço"
                    value={assistanceService?.type}
                    options={[
                      { value: 'SECURITY', label: 'Segurança' },
                      { value: 'HEALTH', label: 'Saúde' },
                      { value: 'ASSISTANCE', label: 'Assistência' },
                    ]}
                    onChange={(value: string) => {
                      setAssistanceService({
                        ...assistanceService,
                        type: value,
                      });
                    }}
                  />
                </FormItem>
                <FormItem
                  label="Email:"
                  name="email"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input
                    type="email"
                    placeholder="Digite o Email"
                    value={assistanceService?.email}
                    onChange={(e: any) => {
                      setAssistanceService({
                        ...assistanceService,
                        email: e.target.value,
                      });
                    }}
                  />
                </FormItem>
              </Infos1>
              <Infos2>
                <FormItem
                  label="Telefone:"
                  name="telefone"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input
                    placeholder="Digite o Telefone"
                    value={assistanceService?.telephone}
                    onChange={(e: any) => {
                      editForm.setFieldValue(
                        'telefone',
                        maskCellPhone(e.target.value),
                      );
                      setAssistanceService({
                        ...assistanceService,
                        telephone: maskCellPhone(e.target.value),
                      });
                    }}
                  />
                </FormItem>
                <FormItem
                  label="Whatsapp:"
                  name="whatsapp"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input
                    placeholder="Digite o Whatsapp:"
                    value={assistanceService?.whatsapp}
                    onChange={(e: any) => {
                      editForm.setFieldValue(
                        'whatsapp',
                        maskCellPhone(e.target.value),
                      );
                      setAssistanceService({
                        ...assistanceService,
                        whatsapp: maskCellPhone(e.target.value),
                      });
                    }}
                  />
                </FormItem>
              </Infos2>
            </InfosContainer>
            <ContainerButton>
              <SaveButton htmlType="submit" type="primary">
                Salvar
              </SaveButton>
            </ContainerButton>
          </ModalForm>
        </ViewModal>
        <Popconfirm
          title={`${'Remover'} ${name}`}
          description="Tem certeza que deseja remover este serviço?"
          onConfirm={() => {
            confirm();
            onDelete?.(id);
          }}
          onCancel={cancel}
          okText="Sim"
          cancelText="Não"
          icon={<ExclamationCircleOutlined style={IconColor.cancel} />}
          okButtonProps={{ style: ButtonColor.cancel }}
        >
          <DeleteButton type="primary">Remover</DeleteButton>
        </Popconfirm>
      </Options>
    </Container>
  );
};

export default AssistanceService;
