import React, { useEffect, useState } from 'react';
import {
  EditForm,
  AvatarContainer,
  Inputs,
  FormItem,
  List,
  HiddenItem,
  Container,
  RegisterService,
  Loading,
  InputNumbers,
  CancelEditButton,
  ConfirmButton,
} from './styles';
import { CollapseProps, Input, InputNumber, Select, Spin } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import UF from '../../../../utils/ufs';
import AvatarEditor from '../../../../components/AvatarEditor';
import SecurityService from '../../../../components/SecurityService';
import PM from '../../../../assets/img/PMPB.png';
import { SecurityServicesE } from '../../../../utils/entities';
import {
  maskCep,
  maskCellPhone,
  maskLandline,
  maskWhatsApp,
  maskNumber,
} from '../../../../utils/masks';
import { useQuery, useMutation } from '@apollo/client';
import {
  ADD_SECURITY_SERVICE,
  DELETE_SECURITY_SERVICE,
  SECURITIES_SERVICES,
  SECURITY_SERVICE_BY_ID,
  UPDATE_SECURITY_SERVICE,
  CITIES_ENABLES,
} from '../../../../utils/queries/adminQueries';
import { Api, ViaCep } from '../../../../services/api';
import { getStorage } from '../../../../utils/storage';
import { base64ToBlob, base64ToObject } from '../../../../utils/converters';

const SecurityServices: React.FC = () => {
  const [isUpdate, setIsUpdate] = useState(false);
  const [typePhone, setTypePhone] = useState('1');
  const auth = base64ToObject(getStorage(`${process.env.REACT_APP_AUTH}`));
  const [imgUpload, setImgUpload] = useState<Blob>();
  const [openCollapse, setOpenCollapse] = useState<string | string[]>([]);
  const [clients, setClients] = useState<any[]>([]);
  const [points, setPoints] = useState(new Array<number>(2));
  const [securityService, setSecurityService] = useState<SecurityServicesE>();
  const [form] = EditForm.useForm();
  const querySecurityServices = useQuery(SECURITIES_SERVICES);
  const querySecurityService = useQuery(SECURITY_SERVICE_BY_ID);
  const queryCities = useQuery(CITIES_ENABLES);
  const [deleteSecurityService, deleteSecurityServiceReturn] = useMutation(
    DELETE_SECURITY_SERVICE,
  );
  const [addSecurityService, addSecurityServiceReturn] =
    useMutation(ADD_SECURITY_SERVICE);
  const [updateSecurityService, updateSecurityServiceReturn] = useMutation(
    UPDATE_SECURITY_SERVICE,
  );
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (
      querySecurityServices.loading ||
      querySecurityService.loading ||
      addSecurityServiceReturn.loading ||
      deleteSecurityServiceReturn.loading ||
      updateSecurityServiceReturn.loading
    ) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [
    querySecurityServices.loading,
    querySecurityService.loading,
    addSecurityServiceReturn.loading,
    deleteSecurityServiceReturn.loading,
    updateSecurityServiceReturn.loading,
  ]);

  useEffect(() => {
    if (securityService?.sphere === 'STATE') {
      setClients(
        UF.ufs.map(state => {
          return { label: state.nome, value: state.sigla };
        }),
      );
    } else {
      setClients(
        queryCities.data?.getCityEnables.map((city: any) => {
          return { label: `${city.name} - ${city.uf}`, value: city._id };
        }),
      );
    }
  }, [securityService?.sphere, queryCities.data]);

  useEffect(() => {
    if (querySecurityService.data?.getSecurityBodyById !== undefined) {
      setSecurityService(querySecurityService.data?.getSecurityBodyById);

      form.setFieldsValue({
        nome: querySecurityService.data?.getSecurityBodyById.profile.name,
        email: querySecurityService.data?.getSecurityBodyById.profile.email,
        telefone: querySecurityService.data?.getSecurityBodyById.telephone,
        whatsapp: querySecurityService.data?.getSecurityBodyById.whatsapp,
        sigla: querySecurityService.data?.getSecurityBodyById.acronym,
        cliente: querySecurityService.data?.getSecurityBodyById.client,
        esferaadministrativa:
          querySecurityService.data?.getSecurityBodyById.sphere,
        cep: querySecurityService.data?.getSecurityBodyById.address.cep,
        uf: querySecurityService.data?.getSecurityBodyById.address.uf,
        cidade: querySecurityService.data?.getSecurityBodyById.address.city,
        zona: querySecurityService.data?.getSecurityBodyById.address.zone,
        bairro:
          querySecurityService.data?.getSecurityBodyById.address.neighborhood,
        rua: querySecurityService.data?.getSecurityBodyById.address.street,
        numero:
          querySecurityService.data?.getSecurityBodyById.address.numbering,
        latitude:
          querySecurityService.data?.getSecurityBodyById.address.localization
            .coordinates[1],
        longitude:
          querySecurityService.data?.getSecurityBodyById.address.localization
            .coordinates[0],
      });
      if (
        querySecurityService.data?.getSecurityBodyById.address.localization
          .coordinates !== undefined
      )
        setPoints(
          querySecurityService.data?.getSecurityBodyById.address.localization
            .coordinates,
        );

      let valuePhone = '1';

      if (
        !querySecurityService.data?.getSecurityBodyById?.telephone.includes('(')
      )
        valuePhone = '2';

      setTypePhone(valuePhone);
    }
  }, [querySecurityService.data]);

  useEffect(() => {
    if (
      addSecurityServiceReturn.data?.addSecurityBody !== undefined &&
      imgUpload !== undefined
    ) {
      const multipartForm = new FormData();
      multipartForm.append('image', imgUpload);
      multipartForm.append(
        'id',
        `${addSecurityServiceReturn.data?.addSecurityBody.profile._id}`,
      );
      multipartForm.append('profile', 'SECURITY_BODY');
      Api.post('/upload/profile/', multipartForm, {
        headers: {
          Authorization: auth.token,
        },
      })
        .then(() => {
          querySecurityServices.refetch();
          querySecurityService.refetch({ id: securityService?._id });
          setLoading(false);
        })
        .catch(() => {});
    }
  }, [addSecurityServiceReturn.data?.addSecurityBody]);

  async function setImage(img: any) {
    setImgUpload(await base64ToBlob(img));
  }

  function complementAddressByCep(cep: string) {
    ViaCep.get(`/${cep.replace('-', '')}/json`)
      .then(resp => {
        setSecurityService({
          ...securityService,
          address: {
            ...securityService?.address,
            cep: resp.data.cep,
            uf: resp.data.uf,
            street: resp.data.logradouro,
            city: resp.data.localidade,
            neighborhood: resp.data.bairro,
          },
        });

        form.setFieldsValue({
          uf: resp.data.uf,
          rua: resp.data.logradouro,
          cidade: resp.data.localidade,
          bairro: resp.data.bairro,
        });
      })
      .catch(() => {});
  }

  function enableEdit(id: string) {
    querySecurityService.refetch({ id });
    setOpenCollapse(['1']);
    setIsUpdate(true);
  }

  function deleteSecurity(id: string) {
    deleteSecurityService({
      variables: {
        idSecurity: id,
      },
      refetchQueries: [{ query: SECURITIES_SERVICES }],
    });
  }

  function add() {
    addSecurityService({
      variables: {
        securityInput: {
          ...securityService,
          address: {
            ...securityService?.address,
            localization: {
              ...securityService?.address?.localization,
              coordinates: points,
            },
          },
          profile: {
            ...securityService?.profile,
            type: 'SECURITY_BODY',
          },
        },
      },
      refetchQueries: [{ query: SECURITIES_SERVICES }],
    });
  }

  function update() {
    updateSecurityService({
      variables: {
        securityInput: {
          ...securityService,
          address: {
            ...securityService?.address,
            localization: {
              ...securityService?.address?.localization,
              coordinates: points,
            },
          },
          profile: {
            ...securityService?.profile,
            image: undefined,
          },
        },
      },
      refetchQueries: [{ query: SECURITIES_SERVICES }],
    });
  }

  const items: CollapseProps['items'] = [
    {
      key: '1',
      label: !isUpdate
        ? 'CADASTRAR SERVIÇO DE SEGURANÇA'
        : 'ATUALIZAR SERVIÇO DE SEGURANÇA',
      children: (
        <EditForm
          onFinish={() => {
            if (isUpdate) {
              update();
              setIsUpdate(!isUpdate);
            } else {
              add();
            }
            form.resetFields();
          }}
          form={form}
          layout="vertical"
        >
          <AvatarContainer>
            <AvatarEditor
              source={securityService?.profile?.image}
              profile={securityService?.profile?._id}
              folder="SECURITY_BODY"
              token={auth.token}
              onUpload={isUpdate ? undefined : setImage}
            />
          </AvatarContainer>
          <Inputs>
            <FormItem
              label="Nome:"
              name="nome"
              rules={[
                {
                  required: true,
                  message: 'Por favor, informe o NOME',
                },
              ]}
            >
              <Input
                placeholder="Digite o Nome do Serviço"
                value={securityService?.profile?.name}
                onChange={e => {
                  form.setFieldValue('nome', e.target.value);
                  setSecurityService({
                    ...securityService,
                    profile: {
                      ...securityService?.profile,
                      name: e.target.value,
                    },
                  });
                }}
              />
            </FormItem>
            <FormItem
              label="E-mail:"
              name="email"
              rules={[
                {
                  required: true,
                  message: 'Por favor, informe o E-MAIL',
                },
              ]}
            >
              <Input
                type="email"
                placeholder="Digite o e-mail"
                value={securityService?.profile?.email}
                onChange={e => {
                  form.setFieldValue('email', e.target.value);
                  setSecurityService({
                    ...securityService,
                    profile: {
                      ...securityService?.profile,
                      email: e.target.value,
                    },
                  });
                }}
              />
            </FormItem>
            <FormItem
              label="Telefone:"
              name="telefone"
              rules={[
                {
                  required: true,
                  message: 'Por favor, informe o TELEFONE',
                },
              ]}
            >
              <Input
                placeholder="Digite o Telefone"
                value={securityService?.telephone}
                addonBefore={
                  <Select
                    value={typePhone}
                    onChange={(value: string) => {
                      setTypePhone(value);
                    }}
                  >
                    <Select.Option value="1">Celular</Select.Option>
                    <Select.Option value="2">Fixo</Select.Option>
                  </Select>
                }
                onChange={e => {
                  form.setFieldValue(
                    'telefone',
                    typePhone === '1'
                      ? maskCellPhone(e.target.value)
                      : maskLandline(e.target.value),
                  );
                  setSecurityService({
                    ...securityService,
                    telephone:
                      typePhone === '1'
                        ? maskCellPhone(e.target.value)
                        : maskLandline(e.target.value),
                  });
                }}
              />
            </FormItem>
          </Inputs>
          <Inputs>
            <FormItem label="Whatsapp:" name="whatsapp">
              <Input
                placeholder="Digite o Whatsapp"
                value={securityService?.whatsapp}
                onChange={e => {
                  form.setFieldValue('whatsapp', maskWhatsApp(e.target.value));
                  setSecurityService({
                    ...securityService,
                    whatsapp: maskWhatsApp(e.target.value),
                  });
                }}
              />
            </FormItem>
            <FormItem
              label="Sigla:"
              name="sigla"
              rules={[
                {
                  required: true,
                  message: 'Por favor, informe a SIGLA',
                },
              ]}
            >
              <Input
                placeholder="Digite a Sigla do Órgão"
                value={securityService?.acronym}
                onChange={e => {
                  form.setFieldValue('sigla', e.target.value);
                  setSecurityService({
                    ...securityService,
                    acronym: e.target.value,
                  });
                }}
              />
            </FormItem>

            <FormItem
              label="Esfera Administrativa:"
              name="esferaadministrativa"
              rules={[
                {
                  required: true,
                  message: 'Por favor, selecione a ESFERA ADMINSITRATIVA',
                },
              ]}
            >
              <Select
                placeholder="Selecione a Esfera Administrativa"
                value={securityService?.sphere}
                options={[
                  {
                    label: 'Estadual',
                    value: 'STATE',
                  },
                  {
                    label: 'Municipal',
                    value: 'MUNICIPAL',
                  },
                ]}
                onChange={value => {
                  form.setFieldValue('esferaadministrativa', value);
                  setSecurityService({
                    ...securityService,
                    sphere: value,
                  });
                }}
              />
            </FormItem>
          </Inputs>
          <Inputs>
            <FormItem
              label="Cliente:"
              name="cliente"
              rules={[
                {
                  required: true,
                  message: 'Por favor, Selecione o CLIENTE',
                },
              ]}
            >
              <Select
                placeholder="Selecione o Cliente"
                options={clients}
                disabled={securityService?.sphere === undefined}
                onChange={(value: string) => {
                  setSecurityService({ ...securityService, client: value });
                }}
              />
            </FormItem>
            <FormItem
              label="Chave de Acesso:"
              name="chaveacesso"
              rules={[
                {
                  required: !isUpdate,
                  message: 'Por favor, Informe a CHAVE DE ACESSO',
                },
              ]}
            >
              <Input.Password
                placeholder="Digite sua Chave de acesso"
                value={securityService?.profile?.password}
                onChange={e => {
                  form.setFieldValue('chaveacesso', e.target.value);
                  setSecurityService({
                    ...securityService,
                    profile: {
                      ...securityService?.profile,
                      password: e.target.value,
                    },
                  });
                }}
              />
            </FormItem>
            <FormItem
              label="CEP:"
              name="cep"
              rules={[
                {
                  required: true,
                  message: 'Por favor, Informe o CEP',
                },
              ]}
            >
              <Input
                placeholder="Digite o seu CEP"
                value={securityService?.address?.cep}
                onChange={e => {
                  form.setFieldValue('cep', maskCep(e.target.value));
                  setSecurityService({
                    ...securityService,
                    address: {
                      ...securityService?.address,
                      cep: maskCep(e.target.value),
                    },
                  });
                  complementAddressByCep(maskCep(e.target.value));
                }}
              />
            </FormItem>
          </Inputs>
          <Inputs>
            <FormItem
              label="UF:"
              name="uf"
              rules={[
                {
                  required: true,
                  message: 'Por favor, Selecione a UF',
                },
              ]}
            >
              <Select
                placeholder="Selecione a UF"
                value={securityService?.address?.uf}
                options={UF.ufs.map(state => {
                  return { label: state.sigla, value: state.sigla };
                })}
                onChange={value => {
                  form.setFieldValue('uf', value);
                  setSecurityService({
                    ...securityService,
                    address: {
                      ...securityService?.address,
                      uf: value,
                    },
                  });
                }}
              />
            </FormItem>
            <FormItem
              label="Cidade:"
              name="cidade"
              rules={[
                {
                  required: true,
                  message: 'Por favor, Informe a CIDADE',
                },
              ]}
            >
              <Input
                placeholder="Digite a sua Cidade"
                value={securityService?.address?.city}
                onChange={e => {
                  form.setFieldValue('cidade', e.target.value);
                  setSecurityService({
                    ...securityService,
                    address: {
                      ...securityService?.address,
                      city: e.target.value,
                    },
                  });
                }}
              />
            </FormItem>
            <FormItem
              label="Zona:"
              name="zona"
              rules={[
                {
                  required: true,
                  message: 'Por favor, Selecione a ZONA',
                },
              ]}
            >
              <Select
                placeholder="Selecione a Zona"
                value={securityService?.address?.zone}
                options={[
                  {
                    label: 'Urbana',
                    value: 'URBAN',
                  },
                  {
                    label: 'Rural',
                    value: 'RURAL',
                  },
                ]}
                onChange={value => {
                  form.setFieldValue('zona', value);
                  setSecurityService({
                    ...securityService,
                    address: {
                      ...securityService?.address,
                      zone: value,
                    },
                  });
                }}
              />
            </FormItem>
          </Inputs>
          <Inputs>
            <FormItem
              label="Bairro:"
              name="bairro"
              rules={[
                {
                  required: true,
                  message: 'Por favor, Informe o BAIRRO',
                },
              ]}
            >
              <Input
                placeholder="Digite o seu Bairro"
                value={securityService?.address?.neighborhood}
                onChange={e => {
                  form.setFieldValue('bairro', e.target.value);
                  setSecurityService({
                    ...securityService,
                    address: {
                      ...securityService?.address,
                      neighborhood: e.target.value,
                    },
                  });
                }}
              />
            </FormItem>
            <FormItem
              label="Rua:"
              name="rua"
              rules={[
                {
                  required: true,
                  message: 'Por favor, Informe a RUA',
                },
              ]}
            >
              <Input
                placeholder="Digite a sua Rua"
                value={securityService?.address?.street}
                onChange={e => {
                  form.setFieldValue('rua', e.target.value);
                  setSecurityService({
                    ...securityService,
                    address: {
                      ...securityService?.address,
                      street: e.target.value,
                    },
                  });
                }}
              />
            </FormItem>
            <FormItem
              label="Número:"
              name="numero"
              rules={[
                {
                  required: true,
                  message: 'Por favor, Informe o NÚMERO',
                },
              ]}
            >
              <InputNumbers
                min={0}
                placeholder="Digite o Número"
                value={securityService?.address?.numbering}
                onChange={value => {
                  form.setFieldValue('numero', value);
                  setSecurityService({
                    ...securityService,
                    address: {
                      ...securityService?.address,
                      numbering: Number(value),
                    },
                  });
                }}
              />
            </FormItem>
          </Inputs>
          <Inputs>
            <FormItem
              label="Latitude:"
              name="latitude"
              rules={[
                {
                  required: true,
                  message: 'Por favor, Informe a LATITUDE',
                },
              ]}
            >
              <InputNumbers
                placeholder="Digite a Latitude"
                value={points[1]}
                onChange={value => {
                  if (isUpdate) {
                    Object.freeze(points);
                    const updatePoints = [...points];
                    updatePoints[1] = Number(value);
                    setPoints(updatePoints);
                  } else {
                    points[1] = Number(value);
                    setPoints(points);
                  }
                }}
              />
            </FormItem>
            <FormItem
              label="Longitude:"
              name="longitude"
              rules={[
                {
                  required: true,
                  message: 'Por favor, Informe a LONGITUDE',
                },
              ]}
            >
              <InputNumbers
                placeholder="Digite a Longitude"
                value={points[0]}
                onChange={value => {
                  if (isUpdate) {
                    Object.freeze(points);
                    const updatePoints = [...points];
                    updatePoints[0] = Number(value);
                    setPoints(updatePoints);
                  } else {
                    points[0] = Number(value);
                    setPoints(points);
                  }
                  form.setFieldValue('longitude', points[0]);
                }}
              />
            </FormItem>
            <HiddenItem />
          </Inputs>
          <FormItem>
            <ConfirmButton
              type="primary"
              htmlType="submit"
              onClick={() => {
                setOpenCollapse([]);
              }}
            >
              {!isUpdate ? 'Cadastrar' : 'Salvar alterações'}
            </ConfirmButton>
            {isUpdate && (
              <CancelEditButton
                type="primary"
                onClick={() => {
                  setIsUpdate(false);
                  setSecurityService(undefined);
                  setOpenCollapse([]);
                  form.resetFields(undefined);
                }}
              >
                Cancelar
              </CancelEditButton>
            )}
          </FormItem>
        </EditForm>
      ),
    },
  ];

  return (
    <Container>
      <Spin spinning={loading} fullscreen indicator={<Loading />} />
      <RegisterService
        activeKey={openCollapse}
        accordion
        items={items}
        size="large"
        onChange={key => {
          setOpenCollapse(key);
        }}
      />

      <List>
        {querySecurityServices.data?.getSecurityBodyAll.map(
          (service: SecurityServicesE) => {
            return (
              <SecurityService
                id={`${service._id}`}
                key={`${service._id}`}
                title={`${service.profile?.name}`}
                state={`${service.address?.uf}`}
                number={`${service.telephone}`}
                onEdit={enableEdit}
                onDelete={deleteSecurity}
                image={service.profile?.image}
              />
            );
          },
        )}
      </List>
    </Container>
  );
};

export default SecurityServices;
