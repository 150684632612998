import { StyleSheet } from '@react-pdf/renderer';

const Styles = StyleSheet.create({
  titleOccurrenceList: {
    display: 'flex',
    backgroundColor: '#e8e8e8',
    fontSize: 10,
    width: '100%',
    padding: 5,
  },
  titleFont: {
    fontFamily: 'Helvetica-Bold',
  },
  list: {
    width: '100%',
    fontSize: 10,
  },
  listHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    width: '100%',
    fontFamily: 'Helvetica-Bold',
    border: 2,
    borderColor: '#e8e8e8',
    padding: 5,
    marginTop: 2,
  },
  headerInfo: {
    display: 'flex',
    flexDirection: 'row',
  },
});

export { Styles };
