import { gql } from '@apollo/client';

export const DASHINSTITUTION = gql`
  query getDataInstitution($institution: MongoID!) {
    getDataInstitution(institution: $institution) {
      validations
      teachers
      employees
      students
    }
  }
`;

export const ASSISTANCE_SERVICES = gql`
  query {
    getAssistanceServiceAll {
      _id
      name
      type
      telephone
      email
      whatsapp
    }
  }
`;

export const ASSISTANCE_SERVICES_BY_CITY = gql`
  query GetAssistanceServiceByCity($profile: MongoID!) {
    getAssistanceServiceByCity(profile: $profile) {
      _id
      name
      type
      telephone
      email
      whatsapp
    }
  }
`;

export const ADD_ASSISTANCE_SERVICE = gql`
  mutation AddAssistanceService(
    $assistanceServiceInput: AssistanceServiceInput!
    $profile: MongoID!
  ) {
    addAssistanceService(
      assistanceService: $assistanceServiceInput
      profile: $profile
    ) {
      _id
    }
  }
`;

export const DELETE_ASSISTANCE_SERVICE = gql`
  mutation DeleteAssistanceService($idAssistanceService: MongoID!) {
    deleteAssistanceService(id: $idAssistanceService) {
      _id
    }
  }
`;

export const UPDATE_ASSISTANCE_SERVICE = gql`
  mutation UpdateAssistanceService(
    $assistanceServiceInput: AssistanceServiceInput!
  ) {
    updateAssistanceService(assistanceService: $assistanceServiceInput) {
      _id
    }
  }
`;

export const INSTITUTION_BY_ID = gql`
  query GetInstitutionByProfileId($id: MongoID!) {
    getInstitutionByProfileId(id: $id) {
      _id
      inep
      telephone
      category
      address {
        cep
        uf
        city
        zone
        neighborhood
        street
        numbering
        localization {
          coordinates
        }
      }
      profile {
        _id
        image
        email
        name
      }
    }
  }
`;

export const MAP_INSTITUTION_BY_ID = gql`
  query GetInstitutionByProfileId($id: MongoID!) {
    getInstitutionByProfileId(id: $id) {
      map
    }
  }
`;

export const ADD_INSTITUTION = gql`
  mutation AddInstitution($institutionInput: InstitutionInput!) {
    addInstitution(institution: $institutionInput) {
      _id
    }
  }
`;

export const UPDATE_INSTITUTION = gql`
  mutation UpdateInstitution($institutionInput: InstitutionInput!) {
    updateInstitution(institution: $institutionInput) {
      _id
    }
  }
`;

export const IMAGE_INSTITUTION_BY_ID = gql`
  query GetInstitutionByProfileId($id: MongoID!) {
    getInstitutionByProfileId(id: $id) {
      profile {
        image
      }
    }
  }
`;

export const RULES_INSTITUTION_BY_ID = gql`
  query GetInstitutionByProfileId($id: MongoID!) {
    getInstitutionByProfileId(id: $id) {
      _id
      rules {
        activePanic
        activeEmergency
        activeDataEdition
        restrictChat
        limitActuation
      }
      address {
        cep
        uf
        city
        neighborhood
        zone
        street
        numbering
        localization {
          coordinates
        }
      }
    }
  }
`;

export const VALIDATION_USER = gql`
  query GetUserByInstitution($institution: MongoID!) {
    getUserByInstitution(institution: $institution) {
      _id
      cpf
      name
      type
      active
      status
      image
      isImported
      email
      responsible {
        email
        telephone
        name
      }
    }
  }
`;

export const UPDATE_USER_BY_TYPE = gql`
  mutation UpdateUserType($id: MongoID!, $type: String!) {
    updateUserType(id: $id, type: $type) {
      _id
    }
  }
`;

export const UPDATE_USER_BY_STATUS = gql`
  mutation updateUserValidate($id: MongoID!) {
    updateUserValidate(id: $id) {
      _id
    }
  }
`;

export const UPDATE_USER_BY_ACTIVE = gql`
  mutation UpdateUserActive($id: MongoID!, $active: Boolean!) {
    updateUserActive(id: $id, active: $active) {
      _id
    }
  }
`;

export const OCCURRENCES_BY_INSTITUTION = gql`
  query GetOccurrenceByInstitutionAndDate($institution: MongoID!, $year: Int!) {
    getOccurrenceByInstitutionAndDate(institution: $institution, year: $year) {
      _id
      type
      level
      description
      date
      triggers {
        actuation
        user {
          name
          image
          responsible {
            name
            telephone
          }
        }
      }
    }
  }
`;

export const UPDATE_OCCURRENCE = gql`
  mutation UpdateDescriptionOccurrence($id: MongoID!, $description: String!) {
    updateDescriptionOccurrence(id: $id, description: $description) {
      _id
    }
  }
`;

export const YEARS_OCCURRENCE = gql`
  query GetYearsOccurrences($institution: MongoID!) {
    getYearsOccurrences(institution: $institution) {
      value
    }
  }
`;

export const OCCURRENCE_BY_ID = gql`
  query GetOccurrenceById($id: MongoID!) {
    getOccurrenceById(id: $id) {
      _id
      date
      type
      level
      description
      institution {
        profile {
          name
        }
        address {
          cep
          uf
          city
          neighborhood
          street
          numbering
        }
      }
      triggers {
        actuation
        user {
          image
          name
          type
          responsible {
            name
            telephone
          }
          cpf
          responsible {
            name
            telephone
          }
        }
      }
    }
  }
`;

export const OCCURRENCE_BY_INSTITUTION_ACTIVES = gql`
  query GetOccurrenceInstitutionActive($insID: MongoID, $occuID: MongoID) {
    getOccurrenceInstitutionActive(institution: $insID, occurrence: $occuID) {
      _id
      date
      type
      level
      institution {
        profile {
          name
        }
        address {
          cep
          uf
          city
          neighborhood
          street
          numbering
        }
      }
      triggers {
        actuation
        user {
          name
          cpf
          image
        }
      }
    }
  }
`;
