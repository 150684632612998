import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import Routes from '../../../../assets/file/routes.json';
import { Button, Dropdown, Menu, MenuProps } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import {
  Container,
  LayoutHeader,
  LayoutContent,
  RoutesHeader,
  UserAvatar,
  ViewModal,
  ContainerButton,
  Label,
  DashboardIcon,
  LabelLink,
  ReisterUFIcon,
  SecurityIcon,
  EditIcon,
  ReportsIcon,
  ExitIcon,
  ProfileOptions,
  LogoContainer,
} from './styles';
import { getStorage, setStorage } from '../../../../utils/storage';
import { useQuery } from '@apollo/client';
import { PROFILE_BY_ID } from '../../../../utils/queries/adminQueries';
import { base64ToObject } from '../../../../utils/converters';
import Logo from '../../../../assets/img/Logo.svg';

const AdminLayout: React.FC = () => {
  const page = getStorage(`${process.env.REACT_APP_SELECTED_PAGE}`, {
    value: '1',
  });

  const queryProfile = useQuery(PROFILE_BY_ID, {
    variables: {
      id: base64ToObject(getStorage(`${process.env.REACT_APP_AUTH}`)).id,
    },
  });

  function clearLocalStorage() {
    localStorage.clear();
  }

  const [isModalOpen, setIsModalOpen] = useState(false);

  function showModal() {
    setIsModalOpen(true);
  }

  function handleOk() {
    setIsModalOpen(false);
    clearLocalStorage();
  }

  function handleCancel() {
    setIsModalOpen(false);
  }

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <ProfileOptions
          onClick={() => {
            setStorage(
              `${process.env.REACT_APP_SELECTED_PAGE}`,
              Routes.private.admin.registerData,
            );
          }}
          href="register-data"
        >
          <EditIcon /> Editar Perfil
        </ProfileOptions>
      ),
    },
    {
      key: '2',
      danger: true,
      label: (
        <ProfileOptions onClick={showModal}>
          <ExitIcon /> Sair
        </ProfileOptions>
      ),
    },
  ];

  return (
    <Container>
      <LayoutHeader>
        <LogoContainer>
          <img src={Logo} alt="" width={'30%'} />
        </LogoContainer>
        <RoutesHeader>
          <Menu
            id="menu"
            theme="dark"
            mode="horizontal"
            disabledOverflow={true}
            defaultSelectedKeys={[page.value]}
            items={[
              {
                key: '1',
                label: (
                  <Label>
                    <DashboardIcon />
                    <LabelLink to={'dashboard'}>Dashboard</LabelLink>
                  </Label>
                ),
                onClick: () => {
                  setStorage(
                    `${process.env.REACT_APP_SELECTED_PAGE}`,
                    Routes.private.admin.dashboard,
                  );
                },
              },
              {
                key: '2',
                label: (
                  <Label>
                    <ReisterUFIcon />
                    <LabelLink to={'register-client'}>
                      Cadastrar Cliente
                    </LabelLink>
                  </Label>
                ),
                onClick: () => {
                  setStorage(
                    `${process.env.REACT_APP_SELECTED_PAGE}`,
                    Routes.private.admin.registerUF,
                  );
                },
              },
              {
                key: '3',
                label: (
                  <Label>
                    <SecurityIcon />
                    <LabelLink to={'security-service'}>
                      Serviços de Segurança
                    </LabelLink>
                  </Label>
                ),
                onClick: () => {
                  setStorage(
                    `${process.env.REACT_APP_SELECTED_PAGE}`,
                    Routes.private.admin.securityServices,
                  );
                },
              },
              /* {
                key: '4',
                label: (
                  <Label>
                    <ReportsIcon />
                    <LabelLink to={'report'}>Emissão de relatórios</LabelLink>
                  </Label>
                ),
                onClick: () => {
                  setStorage(
                    `${process.env.REACT_APP_SELECTED_PAGE}`,
                    Routes.private.admin.reports,
                  );
                },
              }, */
            ]}
          />
          <Dropdown
            menu={{ items }}
            placement="bottomRight"
            trigger={['click']}
          >
            <UserAvatar
              shape="circle"
              icon={
                queryProfile.data?.getProfileById.image === undefined ||
                queryProfile.data?.getProfileById.image === null ? (
                  <UserOutlined />
                ) : (
                  <img src={queryProfile.data?.getProfileById.image} />
                )
              }
              draggable={false}
              size="large"
            ></UserAvatar>
          </Dropdown>
        </RoutesHeader>
        <ViewModal
          open={isModalOpen}
          onCancel={handleCancel}
          footer={null}
          title="Sair da conta"
        >
          <p>Tem certeza que deseja sair da conta?</p>
          <ContainerButton>
            <Button type="primary" danger onClick={handleOk} href="/">
              Sim
            </Button>
          </ContainerButton>
        </ViewModal>
      </LayoutHeader>

      <LayoutContent>
        <Outlet />
      </LayoutContent>
    </Container>
  );
};

export default AdminLayout;
