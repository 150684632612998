import React, { useEffect, useState } from 'react';
import { Avatar, Popover, Space, Tooltip } from 'antd';
import {
  Container,
  ContentName,
  ViewModal,
  ModalForm,
  FormItem,
  TextArea,
  ContainerButton,
  SaveButton,
  Description,
  ContentDescription,
  ContentDate,
  Title,
  PrintIcon,
  EditModal,
  GroupAvatarColor,
  TypeCard,
  Line,
  DateLabel,
  AvatarContent,
} from './styles';
import { OcurrenceI } from '../../utils/types';
import EmergencySVG from '../../assets/img/emergency-image.png';
import PanicSVG from '../../assets/img/panic-image.png';
import { UserOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/client';
import {
  OCCURRENCE_BY_ID,
  UPDATE_OCCURRENCE,
} from '../../utils/queries/institutionQueries';
import { setStorage } from '../../utils/storage';
import { objectToBase64 } from '../../utils/converters';

const Ocurrence: React.FC<OcurrenceI> = ({
  id,
  type,
  level,
  date,
  hour,
  description,
  reporter,
  onUpdate,
  onLoading,
}) => {
  const [theDescription, setTheDescription] = useState(description);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [form] = ModalForm.useForm();

  const [updateOccurrence, updateOcurrenceReturned] =
    useMutation(UPDATE_OCCURRENCE);
  const queryOccurrence = useQuery(OCCURRENCE_BY_ID, {
    variables: {
      id,
    },
  });

  function showEditModal() {
    setIsModalOpen(true);
  }

  function handleCancel() {
    setIsModalOpen(false);
  }

  useEffect(() => {
    queryOccurrence.refetch();
    setTheDescription(queryOccurrence.data?.getOccurrenceById.description);
    form.setFieldsValue({
      descricao: queryOccurrence.data?.getOccurrenceById.description,
    });
  }, [queryOccurrence.data]);

  function update() {
    updateOccurrence({
      variables: {
        id,
        description: theDescription,
      },
    }).then(() => {
      setIsModalOpen(false);
      onUpdate(true);
    });
  }

  function generatePDf() {
    onLoading(true);
    queryOccurrence.refetch({ id }).then(resp => {
      onLoading(false);
      setStorage(
        `${process.env.REACT_APP_PDF_FILE}`,
        objectToBase64(resp.data.getOccurrenceById),
      );
      window.open('/institucion/pdf', '_blank');
    });
  }

  return (
    <Container>
      <Title>
        <TypeCard>
          <img
            src={type === 'EMERGENCY' ? EmergencySVG : PanicSVG}
            alt=""
            width={20}
          />
          <Line type="vertical" />
          <div>
            {type === 'EMERGENCY' ? 'EMERGÊNCIA' : 'PÂNICO'}
            {type === 'EMERGENCY'
              ? level === 'MEDIUM'
                ? ' - MÉDIA'
                : level === 'HIGH'
                  ? ' - ALTA'
                  : ' - BAIXA'
              : null}
          </div>
        </TypeCard>
        <div>
          <Tooltip title="Adicionar descrição">
            <EditModal onClick={showEditModal} />
          </Tooltip>
          <Tooltip title="Imprimir relatório">
            <PrintIcon onClick={generatePDf} />
          </Tooltip>
        </div>
      </Title>
      <ContentDate>
        <DateLabel>Data & Hora:</DateLabel>
        {date.toLocaleDateString('pt-br')} - {date.toLocaleTimeString('pt-BR')}
        {/* {hour.getUTCHours() < 10
          ? '0' + hour.getUTCHours()
          : hour.getUTCHours()}
        :
        {hour.getUTCMinutes() < 10
          ? '0' + hour.getUTCMinutes()
          : hour.getUTCMinutes()} */}
      </ContentDate>
      <ContentDescription>
        <Description>
          {description === null || description === undefined
            ? 'Adicione uma descrição...'
            : description}
        </Description>
      </ContentDescription>
      <ContentName>
        <div>
          {type === 'EMERGENCY' ? (
            <AvatarContent>
              <Space size="small">
                {reporter.map(trigger => {
                  return (
                    <>
                      <Avatar
                        icon={
                          trigger.user.image === null ||
                          trigger.user.image === undefined ? (
                            <UserOutlined />
                          ) : (
                            <img src={trigger.user.image} />
                          )
                        }
                        shape="circle"
                        size="small"
                      />
                      <p>{trigger.user.name}</p>
                    </>
                  );
                })}
              </Space>
            </AvatarContent>
          ) : (
            <AvatarContent>
              <Space size="small">
                <p>Acionado por:</p>
                <Avatar.Group
                  maxCount={3}
                  size="small"
                  maxStyle={{
                    cursor: 'pointer',
                    backgroundColor: GroupAvatarColor.backgroundColor,
                  }}
                  maxPopoverTrigger="focus"
                >
                  {reporter.map(occurrence => {
                    return (
                      <Popover
                        key={Date.now()}
                        content={
                          <div>
                            Nome: {occurrence.user.name}
                            <br />
                            {occurrence.user.responsible !== null &&
                            occurrence.user.responsible !== undefined
                              ? `Responsável: ${occurrence.user.responsible.name} - ${occurrence.user.responsible.telephone}`
                              : `Responsável não informado`}
                          </div>
                        }
                        placement="top"
                      >
                        <Avatar
                          icon={
                            occurrence.user.image === null ? (
                              <UserOutlined />
                            ) : (
                              <img src={occurrence.user.image} />
                            )
                          }
                        />
                      </Popover>
                    );
                  })}
                </Avatar.Group>
              </Space>
            </AvatarContent>
          )}
        </div>
      </ContentName>

      <ViewModal
        centered
        title="Adicionar descrição"
        open={isModalOpen}
        onCancel={handleCancel}
        footer={null}
      >
        <ModalForm
          layout="vertical"
          form={form}
          onFinish={update}
          autoComplete="off"
        >
          <FormItem
            label="Descrição:"
            name="descricao"
            rules={[{ required: true, message: 'Insira uma descrição' }]}
          >
            <TextArea
              rows={6}
              defaultValue={theDescription}
              onChange={e => {
                setTheDescription(e.target.value);
              }}
            />
          </FormItem>
          <ContainerButton>
            <SaveButton type="primary" htmlType="submit">
              Salvar
            </SaveButton>
          </ContainerButton>
        </ModalForm>
      </ViewModal>
    </Container>
  );
};

export default Ocurrence;
