import { gql } from '@apollo/client';

export const DASHADMIN = gql`
  query GetDataAdmin {
    getDataAdmin {
      clients
      institutions
      emergencies
      panics
    }
  }
`;

export const NOTES = gql`
  query GetNoteAll {
    getNoteAll {
      _id
      title
      level
      content
    }
  }
`;

export const ADD_NOTES = gql`
  mutation AddNote($noteInput: NoteInput!) {
    addNote(note: $noteInput) {
      _id
    }
  }
`;

export const DELETE_NOTE = gql`
  mutation DeleteNote($idNote: MongoID!) {
    deleteNote(id: $idNote) {
      _id
    }
  }
`;

export const LAST_CLIENTS = gql`
  query GetLastFiveClients {
    getLastFiveClients {
      _id
      name
      uf
    }
  }
`;

export const CITIES_ENABLES = gql`
  query GetCityEnables {
    getCityEnables {
      _id
      name
      uf
    }
  }
`;

export const CITIES_BY_UF = gql`
  query GetCityByUF($uf: String!) {
    getCityByUF(uf: $uf) {
      _id
      name
      enable
    }
  }
`;

export const ADD_CITY_BY_UF = gql`
  mutation AddCitiesByUf($uf: String!) {
    addCitiesByUf(uf: $uf) {
      _id
    }
  }
`;

export const ADD_CITY_BY_IDS = gql`
  mutation AddCitiesByIds($ids: [MongoID!]!) {
    addCitiesByIds(ids: $ids) {
      _id
    }
  }
`;

export const DELETE_CITY_BY_IDS = gql`
  mutation DeleteCityByIds($ids: [MongoID!]!) {
    deleteCityByIds(ids: $ids) {
      _id
    }
  }
`;

export const PROFILE_BY_ID = gql`
  query GetProfileById($id: MongoID!) {
    getProfileById(id: $id) {
      _id
      image
      email
      name
    }
  }
`;

export const UPDATE_PROFILE = gql`
  mutation UpdateProfile($profileInput: ProfileInput!) {
    updateProfile(profile: $profileInput) {
      _id
    }
  }
`;

export const ADD_SECURITY_SERVICE = gql`
  mutation AddSecurityBody($securityInput: SecurityBodyInput!) {
    addSecurityBody(securityBody: $securityInput) {
      _id
      profile {
        _id
      }
    }
  }
`;

export const SECURITIES_SERVICES = gql`
  query GetSecurityBodyAll {
    getSecurityBodyAll {
      _id
      acronym
      telephone
      sphere
      profile {
        _id
        image
        email
        name
      }
      address {
        uf
      }
    }
  }
`;

export const SECURITY_SERVICE_BY_ID = gql`
  query GetSecurityBodyById($id: MongoID!) {
    getSecurityBodyById(id: $id) {
      _id
      profile {
        _id
        image
        email
        name
      }
      client
      telephone
      whatsapp
      acronym
      sphere
      address {
        cep
        uf
        city
        zone
        neighborhood
        street
        numbering
        localization {
          coordinates
        }
      }
    }
  }
`;

export const UPDATE_SECURITY_SERVICE = gql`
  mutation UpdateSecurityBody($securityInput: SecurityBodyInput!) {
    updateSecurityBody(securityBody: $securityInput) {
      _id
    }
  }
`;

export const DELETE_SECURITY_SERVICE = gql`
  mutation DeleteSecurityBody($idSecurity: MongoID!) {
    deleteSecurityBody(id: $idSecurity) {
      _id
    }
  }
`;
