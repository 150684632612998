import styled from 'styled-components';
import { Button, Layout } from 'antd';

const Container = styled(Layout)`
  height: 100vh;
`;

const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  gap: 10px;
`;

const LayoutHeader = styled(Layout.Header)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
  padding-inline: 90px !important;
`;

const LayoutContent = styled(Layout.Content)`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffff;
  height: 100%;
`;

const ExitButton = styled(Button)`
  color: #ffff !important;
  display: flex;
  align-items: stretch;
  height: fit-content;
  font-size: 17px;
  gap: 10%;

  span {
    padding-top: 9%;
  }

  .svg-icons {
    font-size: 25px;
  }
`;

const ButtonColor = {
  ok: {
    background: '#33c455',
  },
  cancel: {
    background: '#c53f3f',
  },
};

export {
  Container,
  LayoutHeader,
  LayoutContent,
  LogoContainer,
  ExitButton,
  ButtonColor,
};
