import styled from 'styled-components';
import { Input, Button, Form, Alert } from 'antd';

const Container = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Content = styled.div`
  width: 35%;
  height: 65%;
  border-radius: 12px;
  border: 1px solid #b0b0b0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Title = styled.p`
  margin-bottom: 1.5em;
  font-size: 24px;
  font-weight: 600;
`;

const PasswordForm = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
`;

const Fields = styled(Form)`
  width: 50%;
`;

const FormItem = styled(Form.Item)`
  .ant-form-item-label {
    padding: 0%;
  }
  .ant-form-item-label * {
    font-weight: 600;
  }
`;

const ErrorAlert = styled(Alert)`
  margin-bottom: 5%;
  background-color: #fac3c3;
`;

const PasswordInput = styled(Input)``;

const SaveButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #33c455 !important;
  width: 100%;
`;

export {
  Container,
  Content,
  Title,
  PasswordForm,
  Fields,
  FormItem,
  PasswordInput,
  SaveButton,
  ErrorAlert,
};
