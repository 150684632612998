import styled from 'styled-components';
import { Button, FloatButton, Form, Input, Modal } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const Container = styled.div`
  width: 100%;
`;

const Loading = styled(LoadingOutlined)`
  font-size: 64px !important;
  color: #000;
`;

const SearchContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-right: 4%;
`;

const SearchInput = styled(Input)`
  width: 30%;
`;

const View = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const Cards = styled.div`
  width: 90%;
  display: grid;
  grid-template-columns: 25% 25% 25%;
  justify-content: space-around;
  @media (max-width: 1500px) {
    grid-template-columns: 30% 30% 30%;
  }
`;

const AddButton = styled(FloatButton)`
  right: 5%;
  .ant-float-btn-body {
    background-color: #33c455 !important;
  }
`;

const ViewModal = styled(Modal)`
  .ant-modal-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const ModalForm = styled(Form)`
  display: flex;
  flex-direction: column;
  margin-top: 5%;
`;

const InfosContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const Infos1 = styled.div`
  margin-right: 2%;
`;

const Infos2 = styled.div`
  margin-left: 2%;
`;

const FormItem = styled(Form.Item)`
  .ant-form-item-label {
    font-weight: 600;
  }
`;

const ContainerButton = styled(Form.Item)`
  display: flex;
  justify-content: center;
  margin: 0;
`;

const SaveButton = styled(Button)`
  background-color: #33c455 !important;
  width: 200px;
`;

export {
  Container,
  Loading,
  SearchContainer,
  SearchInput,
  View,
  Cards,
  AddButton,
  ViewModal,
  ModalForm,
  InfosContainer,
  Infos1,
  Infos2,
  FormItem,
  ContainerButton,
  SaveButton,
};
