import React from 'react';
import { TypeSelect } from './styles';
import { TypeFilterI } from '../../utils/types';

const TypeFilter: React.FC<TypeFilterI> = ({ onChoice }) => {
  return (
    <TypeSelect
      defaultValue="Todos"
      options={[
        {
          value: 'ALL',
          label: 'Todos',
        },
        {
          value: 'PANIC',
          label: 'Pânicos',
        },
        {
          value: 'EMERGENCY',
          label: 'Emergências',
        },
      ]}
      onSelect={(value: any) => {
        onChoice(value);
      }}
    />
  );
};

export default TypeFilter;
