import React from 'react';
import { TileLayer, Marker } from 'react-leaflet';
import L from 'leaflet';
import { CloseOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { ButtonText, Map, MapButton, MapButtonsContainer } from './styles';
import { MapI } from '../../utils/types';
import 'leaflet/dist/leaflet.css';
import MapController from '../MapController';
import Icon from 'leaflet/dist/images/marker-icon.png';

const OpenStreetMaps: React.FC<MapI> = ({
  coordinates,
  isRoute,
  isRouteModalOpen,
  onOpenRouteModal,
  onHideRoute,
  position = false,
}) => {
  function openRoutesModal() {
    onOpenRouteModal?.();
  }

  function hideRoute() {
    onHideRoute?.();
  }

  return (
    <Map center={coordinates} zoom={15} scrollWheelZoom={false}>
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {isRoute && (
        <MapButtonsContainer>
          <MapButton type="button" onClick={hideRoute}>
            <CloseOutlined />
            <ButtonText>Fechar rota</ButtonText>
          </MapButton>
          {!isRouteModalOpen && (
            <MapButton type="button" onClick={openRoutesModal}>
              <InfoCircleOutlined />
              <ButtonText>Ver instruções</ButtonText>
            </MapButton>
          )}
        </MapButtonsContainer>
      )}

      <MapController coordinates={coordinates} />
      {position && (
        <Marker
          icon={
            new L.Icon({
              iconUrl: Icon,
              iconSize: new L.Point(20, 30),
              className: 'leaflet-div-icon',
            })
          }
          position={coordinates}
        />
      )}
    </Map>
  );
};

export default OpenStreetMaps;
