import styled from 'styled-components';
import { InformI } from '../../utils/types';
import { Badge, Collapse, Modal } from 'antd';

const Container = styled.div<InformI>`
  width: 100%;
  display: flex;
  border: solid;
  border-width: 0.5px;
  border-radius: 6px;
  border-color: ${props =>
    props.type === 'EMERGENCY' ? '#f7ea8b' : '#f7918b'};
  padding: 8px;
  margin-bottom: 5%;
`;

const Text = styled.p`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 70%;
  font-size: 15px;
`;

const Count = styled(Badge)`
  display: unset;
`;

const ViewModal = styled(Modal)`
  .ant-modal-header {
    display: flex;
    justify-content: center;
    margin-bottom: 5%;
  }
  .ant-modal-body {
    display: flex;
    flex-direction: column;
  }
`;

const ModalInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1%;
`;

const Flag = styled.img`
  width: 10%;
  height: 10%;
  position: absolute;
  top: 0px;
  left: 10px;
`;

const ModalLabel = styled.p`
  font-weight: 600;
  font-size: 13px;
`;

const Info = styled.p`
  color: #666666;
  font-size: 15px;
`;

const Card = styled(Collapse)`
  display: flex;
  width: 100%;

  .ant-collapse-item,
  .ant-collapse-header,
  .ant-collapse-header-text {
    width: 100%;
  }
`;

const CardLabel = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const LabelText = styled.p`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 70%;
  font-size: 15px;
  text-align: center;
`;

const AvatarContent = styled.div`
  display: flex;
  align-items: center;
`;

const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 5%;
  position: relative;
  width: 100%;
`;

const ItemData = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 5%;
  align-items: center;
  margin-bottom: 1.5%;
`;

const ItemText = styled.p`
  width: 90%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 15px;
  flex-grow: 2;
`;

const InformButton = styled.button`
  border-width: 0.5px;
  border-radius: 6px;
  background: none;
  cursor: pointer;
  display: flex;
  align-items: stretch;
  width: 40%;

  .anticon {
    padding: 3% 3% 3% 6%;
  }

  &:hover {
    background-color: #dedede;
  }
`;

const ButtonText = styled.p`
  width: 100%;
  text-align: center;
  font-size: 15px;
  padding: 3%;
`;

const ButtonContainer = styled.div`
  margin-top: 3%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
`;

const ShowRoute = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 5%;
  align-items: center;
  margin-bottom: 1.5%;

  .anticon {
    padding: 0 0.7%;
  }
`;

const InfoButtonContainer = styled.div`
  width: 90%;
  flex-grow: 2;
`;

const InfoButton = styled.button`
  font-size: 15px;
  border: none;
  background-color: #ffffff;
  cursor: pointer;
  text-align: left;
  text-overflow: ellipsis;
  text-decoration: underline;
  white-space: nowrap;
  overflow: hidden;
  width: fit-content;

  &:hover {
    color: #5a5a5a;
  }
`;

const InfoUserList = styled.div`
  display: flex;
  justify-content: space-between;
`;

const InfoInstitutionUser = styled.div`
  display: flex;
  color: #666666;
  font-size: 15px;
  /* gap: 5px; */
  flex-direction: column;
  align-items: center;
`;

const InfoUserIcon = styled.img`
  width: 30px;
`;

export {
  Container,
  Text,
  Count,
  ViewModal,
  ModalInfo,
  Flag,
  ModalLabel,
  Info,
  Card,
  CardLabel,
  LabelText,
  AvatarContent,
  ItemContainer,
  ItemData,
  ItemText,
  InformButton,
  ButtonText,
  ButtonContainer,
  ShowRoute,
  InfoButtonContainer,
  InfoButton,
  InfoUserList,
  InfoInstitutionUser,
  InfoUserIcon,
};
