import React, { useEffect, useState } from 'react';
import Card from '../../../../components/Card';
import Warning from '../../../../components/Warning';
import {
  Container,
  Cards,
  Frame,
  FrameImage,
  Warnings,
  Div1,
  Div2,
  Div3,
  Loading,
} from './styles';
import Legend from '../../../../assets/img/legend-frame.svg';
import WarningImage from '../../../../assets/img/warnings.svg';
import { useQuery } from '@apollo/client';
import { NOTES } from '../../../../utils/queries/adminQueries';
import { DashInstitutionE, NoteE } from '../../../../utils/entities';
import { Spin } from 'antd';
import { DASHINSTITUTION } from '../../../../utils/queries/institutionQueries';
import { base64ToObject } from '../../../../utils/converters';
import { getStorage } from '../../../../utils/storage';

const InstitutionDashboard: React.FC = () => {
  const auth = base64ToObject(getStorage(`${process.env.REACT_APP_AUTH}`));
  const [dashInstitution, setDashInstitution] = useState<DashInstitutionE>();
  const queryNote = useQuery(NOTES);
  const queryDashData = useQuery(DASHINSTITUTION, {
    variables: {
      institution: auth.id,
    },
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setDashInstitution(queryDashData.data?.getDataInstitution);
  }, [queryDashData.data?.getDataInstitution]);

  useEffect(() => {
    queryDashData.refetch();
    queryNote.refetch();
    if (queryNote.loading) {
      setLoading(!loading);
    } else {
      setLoading(false);
    }
  }, [queryNote.loading]);

  return (
    <Container>
      <Spin spinning={loading} fullscreen indicator={<Loading />} />
      <Cards>
        <Card
          title={'VALIDAÇÕES PENDENTES'}
          cont={
            dashInstitution?.validations === undefined
              ? 0
              : dashInstitution?.validations
          }
          icon={require('../../../../assets/img/validations-image.png')}
        />
        <Card
          title={'PROESSORES'}
          cont={
            dashInstitution?.teachers === undefined
              ? 0
              : dashInstitution?.teachers
          }
          icon={require('../../../../assets/img/teachers-image.png')}
        />
        <Card
          title={'ALUNOS'}
          cont={
            dashInstitution?.students === undefined
              ? 0
              : dashInstitution?.students
          }
          icon={require('../../../../assets/img/students-image.png')}
        />
        <Card
          title={'FUNCIONÁRIOS'}
          cont={
            dashInstitution?.employees === undefined
              ? 0
              : dashInstitution?.employees
          }
          icon={require('../../../../assets/img/employees-image.png')}
        />
      </Cards>
      <Frame>
        <FrameImage src={require('../../../../assets/img/frame.png')} alt="" />
        <Warnings>
          <Div1>
            <img src={WarningImage} width={'20%'} alt="" />
          </Div1>
          <Div2>
            {queryNote.loading
              ? null
              : queryNote.data?.getNoteAll.map((note: NoteE) => (
                  <Warning
                    key={note._id}
                    id={note._id}
                    value={note.level?.toLowerCase()}
                    title={note.title}
                    text={note.content}
                    type="instituicao"
                  />
                ))}
          </Div2>
          <Div3>
            <img src={Legend} width={'9%'} alt="" />
          </Div3>
        </Warnings>
      </Frame>
    </Container>
  );
};

export default InstitutionDashboard;
