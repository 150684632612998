import React, { useEffect, useState } from 'react';
import { Input, Spin, notification } from 'antd';
import {
  Column,
  Container,
  EditForm,
  FormItem,
  ContainerButton,
  SaveButton,
  Loading,
} from './styles';
import AvatarEditor from '../../../../components/AvatarEditor';
import { ProfileE } from '../../../../utils/entities';
import { useQuery, useMutation } from '@apollo/client';
import {
  PROFILE_BY_ID,
  UPDATE_PROFILE,
} from '../../../../utils/queries/adminQueries';
import { getStorage } from '../../../../utils/storage';
import { base64ToObject } from '../../../../utils/converters';

const AdminRegisterData: React.FC = () => {
  const [editForm] = EditForm.useForm();
  const [profile, setProfile] = useState<ProfileE>();
  const [enableInput, setEnableInput] = useState(true);
  const [enableButton, setEnableButton] = useState(false);
  const auth = base64ToObject(getStorage(`${process.env.REACT_APP_AUTH}`));
  const [loading, setLoading] = useState(true);
  const queryProfile = useQuery(PROFILE_BY_ID, {
    variables: {
      id: auth.id,
    },
  });
  const [updateProfile, updateProfileReturn] = useMutation(UPDATE_PROFILE);

  useEffect(() => {
    setProfile({
      _id: queryProfile.data?.getProfileById._id,
      email: queryProfile.data?.getProfileById.email,
      name: queryProfile.data?.getProfileById.name,
      image: queryProfile.data?.getProfileById.image,
    });

    editForm.setFieldsValue({
      email: queryProfile.data?.getProfileById.email,
      nome: queryProfile.data?.getProfileById.name,
    });

    if (queryProfile.loading || updateProfileReturn.loading) {
      setLoading(!loading);
    } else {
      setLoading(false);
    }
  }, [queryProfile.loading, updateProfileReturn.loading]);

  function update() {
    updateProfile({
      variables: {
        profileInput: {
          _id: profile?._id,
          name: profile?.name,
          email: profile?.email,
          password:
            profile?.password === undefined
              ? undefined
              : profile.password.trim().length <= 0
                ? undefined
                : profile.password,
        },
      },
      refetchQueries: [{ query: PROFILE_BY_ID }],
    });
    setProfile({ ...profile, password: undefined });
    setLoading(true);
  }

  return (
    <Container>
      <Spin spinning={loading} fullscreen indicator={<Loading />} />

      <AvatarEditor
        source={profile?.image}
        profile={auth.id}
        folder={auth.profile}
        token={auth.token}
      />

      <EditForm form={editForm} layout="vertical">
        <Column>
          <FormItem
            label="Nome:"
            name="nome"
            rules={[{ required: true, message: 'Por favor, informe o NOME' }]}
          >
            <Input
              value={profile?.name}
              placeholder="Digite o seu Nome"
              size="large"
              onChange={e => {
                setProfile({ ...profile, name: e.target.value });
                editForm.setFieldValue('nome', e.target.value);
              }}
            />
          </FormItem>
          <FormItem label="Chave de acesso:" name="senha">
            <Input.Password
              placeholder="Digite sua Chave de acesso"
              size="large"
              onChange={e => {
                setProfile({ ...profile, password: e.target.value });
                editForm.setFieldValue('senha', e.target.value);

                if (
                  profile?.password !== undefined &&
                  profile?.password?.length >= 7
                ) {
                  setEnableInput(false);
                  setEnableButton(true);
                } else {
                  if (
                    profile?.password !== undefined &&
                    profile?.password?.length >= 0
                  ) {
                    setEnableInput(true);
                    setEnableButton(false);
                  }
                }
              }}
            />
          </FormItem>
        </Column>
        <Column>
          <FormItem
            label="Email:"
            name="email"
            rules={[{ required: true, message: 'Por favor, informe o E-MAIL' }]}
          >
            <Input
              value={profile?.email}
              type="email"
              placeholder="Digite seu Email"
              size="large"
              onChange={e => {
                editForm.setFieldValue('email', e.target.value);
                setProfile({ ...profile, email: e.target.value });
              }}
            />
          </FormItem>

          <FormItem label="Confirme a Chave:" name="confirmasenha">
            <Input.Password
              placeholder="Digite sua chave de acesso novamente"
              size="large"
              disabled={enableInput}
              onChange={e => {
                editForm.setFieldValue('confirmasenha', e.target.value);
                if (
                  profile?.password !== undefined &&
                  profile?.password.length === e.target.value.length &&
                  profile?.password === e.target.value
                ) {
                  setEnableButton(false);
                } else {
                  if (
                    profile?.password !== undefined &&
                    e.target.value.length < profile?.password?.length
                  ) {
                    setEnableButton(true);
                  }
                }
              }}
            />
          </FormItem>
        </Column>
        <FormItem>
          <ContainerButton>
            <SaveButton
              disabled={enableButton}
              size="large"
              type="primary"
              htmlType="submit"
              onClick={() => {
                update();
                location.reload();
              }}
            >
              Salvar
            </SaveButton>
          </ContainerButton>
        </FormItem>
      </EditForm>
    </Container>
  );
};

export default AdminRegisterData;
