import React from 'react';
import { PrivateRouteI } from '../../utils/types';
import { Navigate, useLocation } from 'react-router-dom';
import { getStorage, setStorage } from '../../utils/storage';
import Routes from '../../assets/file/routes.json';
import { base64ToObject } from '../../utils/converters';

const PrivateRoute: React.FC<PrivateRouteI> = ({ children, isPrivate }) => {
  const storage = getStorage(`${process.env.REACT_APP_AUTH}`);
  const isAuthenticated = storage !== undefined;
  const location = useLocation();

  if (isAuthenticated) {
    const type = base64ToObject(storage);
    const selectedPage = getStorage(`${process.env.REACT_APP_SELECTED_PAGE}`);

    switch (type.profile) {
      case 'ADMIN': {
        if (selectedPage === undefined) {
          setStorage(
            `${process.env.REACT_APP_SELECTED_PAGE}`,
            Routes.private.admin.dashboard,
          );
        } else {
          setStorage(`${process.env.REACT_APP_SELECTED_PAGE}`, selectedPage);
          if (selectedPage.path !== location.pathname) {
            return <Navigate to={selectedPage.path} />;
          }
        }
        break;
      }
      case 'INSTITUTION': {
        if (selectedPage === undefined) {
          setStorage(
            `${process.env.REACT_APP_SELECTED_PAGE}`,
            Routes.private.instituicao.dashboard,
          );
        } else {
          setStorage(`${process.env.REACT_APP_SELECTED_PAGE}`, selectedPage);
          if (location.pathname === '/institucion/pdf') {
            break;
          } else if (selectedPage.path !== location.pathname) {
            return <Navigate to={selectedPage.path} />;
          }
        }
        break;
      }
      default: {
        if (selectedPage === undefined) {
          setStorage(
            `${process.env.REACT_APP_SELECTED_PAGE}`,
            Routes.private.securityBodies.home,
          );
        } else {
          setStorage(`${process.env.REACT_APP_SELECTED_PAGE}`, selectedPage);
          if (location.pathname === '/security-bodies/pdf') {
            break;
          } else if (location.pathname === '/security-bodies/single-pdf') {
            break;
          } else if (selectedPage.path !== location.pathname) {
            return <Navigate to={selectedPage.path} />;
          }
        }
      }
    }
  }

  if (isPrivate && !isAuthenticated) {
    return <Navigate to="/" />;
  }

  if (!isPrivate && isAuthenticated) {
    return (
      <Navigate
        to={getStorage(`${process.env.REACT_APP_SELECTED_PAGE}`).path}
      />
    );
  }

  return children;
};

export default PrivateRoute;
