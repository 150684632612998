import styled from 'styled-components';
import { Form, Divider, Collapse, InputNumber, Button } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  height: 90%;
`;

const Loading = styled(LoadingOutlined)`
  font-size: 64px !important;
  color: #000;
`;

const EditForm = styled(Form)`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const AvatarContainer = styled.div`
  margin-bottom: 3%;
`;

const Inputs = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 80%;
`;

const FormItem = styled(Form.Item)`
  width: 25% !important;
  .ant-form-item-control-input-content {
    display: flex;
    justify-content: center;
  }
  .ant-input {
    width: 100%;
  }
  .ant-select-selector {
    width: 100%;
  }
  .ant-form-item-label {
    padding: 0;
  }
`;

const HiddenItem = styled(Form.Item)`
  width: 25% !important;
  visibility: hidden;
  .ant-form-item-control-input-content {
    display: flex;
    justify-content: center;
  }
  .ant-input {
    width: 100%;
  }
  .ant-select-selector {
    width: 100%;
  }
  .ant-form-item-label {
    padding: 0;
  }
`;

const Line = styled(Divider)`
  margin-top: 0 !important;
  .ant-divider-inner-text {
    font-weight: 600;
  }
`;

const List = styled.div`
  display: grid;
  grid-template-columns: 20% 20% 20% 20% 20%;
  @media (max-width: 1500px) {
    grid-template-columns: 25% 25% 25% 25%;
  }
`;

const RegisterService = styled(Collapse)`
  margin-bottom: 2%;
`;

const InputNumbers = styled(InputNumber)`
  width: 100%;
`;

const ConfirmButton = styled(Button)`
  margin-top: 7%;
  background-color: #33c455 !important;
  width: 70%;
`;

const CancelEditButton = styled(Button)`
  margin-top: 7%;
  margin-left: 8%;
  background-color: #c53f3f !important;
  width: 70%;
`;

export {
  Container,
  Loading,
  EditForm,
  AvatarContainer,
  Inputs,
  FormItem,
  HiddenItem,
  Line,
  List,
  RegisterService,
  InputNumbers,
  ConfirmButton,
  CancelEditButton,
};
