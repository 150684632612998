import React, { useEffect, useState } from 'react';
import { Container, Cards, Filters, Filter1, Filter2, Loading } from './styles';
import Ocurrence from '../../../../components/Occurrence';
import YearFilter from '../../../../components/YearFilter';
import TypeFilter from '../../../../components/TypeFilter';
import { useQuery } from '@apollo/client';
import {
  OCCURRENCES_BY_INSTITUTION,
  YEARS_OCCURRENCE,
} from '../../../../utils/queries/institutionQueries';
import { base64ToObject } from '../../../../utils/converters';
import { getStorage } from '../../../../utils/storage';
import { OccurrenceE } from '../../../../utils/entities';
import { Spin, Divider } from 'antd';

const Ocurrences: React.FC = () => {
  const auth = base64ToObject(getStorage(`${process.env.REACT_APP_AUTH}`));
  const [occurrencies, setOcurrencies] = useState<OccurrenceE[]>([]);
  const [occurrenceAll, setOcurrenceAll] = useState<OccurrenceE[]>([]);
  const [loading, setLoading] = useState(true);
  const yearFind = new Date().getFullYear();
  const [years, setYears] = useState<number[]>([]);
  const queryYears = useQuery(YEARS_OCCURRENCE, {
    variables: {
      institution: auth.id,
    },
  });
  const queryOccurrencies = useQuery(OCCURRENCES_BY_INSTITUTION, {
    variables: {
      institution: auth.id,
      year: yearFind,
    },
  });

  useEffect(() => {
    queryOccurrencies.refetch();
    if (queryOccurrencies.loading || queryYears.loading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [queryOccurrencies.loading, queryYears.loading]);

  useEffect(() => {
    setOcurrencies(
      queryOccurrencies.data?.getOccurrenceByInstitutionAndDate === undefined
        ? []
        : queryOccurrencies.data?.getOccurrenceByInstitutionAndDate,
    );

    setOcurrenceAll(
      queryOccurrencies.data?.getOccurrenceByInstitutionAndDate === undefined
        ? []
        : queryOccurrencies.data?.getOccurrenceByInstitutionAndDate,
    );
  }, [queryOccurrencies.data]);

  useEffect(() => {
    if (!queryYears.loading) {
      setYears(
        queryYears.data?.getYearsOccurrences === undefined
          ? [2023]
          : queryYears.data?.getYearsOccurrences.map((year: any) => {
              return year.value;
            }),
      );
    }
  }, [queryYears.data?.getYearsOccurrences]);

  function filterType(value: string) {
    if (value !== 'ALL') {
      setOcurrencies(
        occurrenceAll.filter(occurrence => {
          return occurrence.type === value;
        }),
      );
    } else {
      setOcurrencies(occurrenceAll);
    }
  }

  function filterYear(value: string) {
    setLoading(true);
    queryOccurrencies
      .refetch({ institution: auth.id, year: Number(value) })
      .then(() => {
        setLoading(false);
      });
  }

  function updateOccurrence(isUpdate: boolean) {
    if (isUpdate) queryOccurrencies.refetch();
  }

  function startLoading(value: boolean) {
    setLoading(value);
  }

  return (
    <Container>
      <Filters>
        <Filter1>
          <TypeFilter onChoice={filterType} />
        </Filter1>
        <Filter2>
          <YearFilter options={years} onChoice={filterYear} />
        </Filter2>
      </Filters>
      <Divider orientation="left">
        <b>| GERENCIAMENTO DAS OCORRÊNCIAS</b>
      </Divider>
      <Cards>
        <Spin spinning={loading} fullscreen indicator={<Loading />} />
        {occurrencies.map((occurrence, index) => {
          return (
            <Ocurrence
              key={index}
              id={occurrence._id}
              type={occurrence.type}
              level={occurrence.level}
              date={new Date(occurrence.date)}
              hour={new Date(occurrence.date)}
              reporter={occurrence.triggers}
              description={occurrence.description}
              onUpdate={updateOccurrence}
              onLoading={startLoading}
            />
          );
        })}
      </Cards>
    </Container>
  );
};

export default Ocurrences;
