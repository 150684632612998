import React, { useEffect, useState } from 'react';
import Fuse from 'fuse.js';
import {
  Container,
  Cards,
  SearchContainer,
  SearchInput,
  DisableTreeSelect,
  Filter,
  Loading,
  View,
} from './styles';
import Validation from '../../../../components/Validation';
import { PiStudentBold } from 'react-icons/pi';
import { GrUserWorker } from 'react-icons/gr';
import { FaChalkboardTeacher } from 'react-icons/fa';
import { SearchOutlined, SyncOutlined } from '@ant-design/icons';
import { Button, Spin, Upload, message, Divider } from 'antd';
import { useMutation, useQuery } from '@apollo/client';
import {
  UPDATE_USER_BY_ACTIVE,
  UPDATE_USER_BY_STATUS,
  UPDATE_USER_BY_TYPE,
  VALIDATION_USER,
} from '../../../../utils/queries/institutionQueries';
import { base64ToObject } from '../../../../utils/converters';
import { getStorage } from '../../../../utils/storage';
import { UserE } from '../../../../utils/entities';
import { Api } from '../../../../services/api';

const Validations: React.FC = () => {
  const auth = base64ToObject(getStorage(`${process.env.REACT_APP_AUTH}`));
  const [users, setUsers] = useState<UserE[]>([]);
  const [usersAll, setUserAll] = useState<UserE[]>([]);
  const [loading, setLoading] = useState(true);
  const queryValitations = useQuery(VALIDATION_USER, {
    variables: {
      institution: auth.id,
    },
  });
  const [updateType, updateTypeReturned] = useMutation(UPDATE_USER_BY_TYPE);
  const [updateStatus, updateStatusReturned] = useMutation(
    UPDATE_USER_BY_STATUS,
  );
  const [updateActive, updateActiveReturned] = useMutation(
    UPDATE_USER_BY_ACTIVE,
  );

  useEffect(() => {
    if (
      queryValitations.loading ||
      updateTypeReturned.loading ||
      updateStatusReturned.loading ||
      updateActiveReturned.loading
    ) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [
    queryValitations.loading ||
      updateTypeReturned.loading ||
      updateStatusReturned.loading ||
      updateActiveReturned.loading,
  ]);

  useEffect(() => {
    setUsers(
      queryValitations.data?.getUserByInstitution !== undefined
        ? queryValitations.data?.getUserByInstitution
        : [],
    );

    setUserAll(
      queryValitations.data?.getUserByInstitution !== undefined
        ? queryValitations.data?.getUserByInstitution
        : [],
    );
  }, [queryValitations.data?.getUserByInstitution]);

  const filters = [
    {
      title: 'Todos',
      value: '8',
    },
    {
      disabled: true,
      title: <DisableTreeSelect>Tipo de pessoa</DisableTreeSelect>,
      value: '0',
      children: [
        {
          title: 'Aluno',
          value: '1',
        },
        {
          title: 'Professor',
          value: '2',
        },
        {
          title: 'Funcionário',
          value: '3',
        },
      ],
    },
    {
      title: 'Validados',
      value: '4',
    },
    {
      title: 'Não validados',
      value: '5',
    },
    {
      title: 'Ativos',
      value: '6',
    },
    {
      title: 'Inativos',
      value: '7',
    },
  ];

  function alterType(id: string, type: string) {
    updateType({
      variables: {
        id,
        type,
      },
    }).then(() => {
      queryValitations.refetch();
    });
  }

  function alterStatus(id: string) {
    updateStatus({
      variables: {
        id,
      },
    }).then(() => {
      message.success('Validação confirmada');
      queryValitations.refetch();
    });
  }

  function alterActive(id: string, value: boolean) {
    updateActive({
      variables: {
        id,
        active: value,
      },
    }).then(() => {
      queryValitations.refetch();
    });
  }

  function search(value: string) {
    if (value.length > 0) {
      const searched = new Fuse(usersAll, {
        keys: ['name', 'responsible.name'],
        includeMatches: true,
        includeScore: true,
        useExtendedSearch: true,
      });
      setUsers(
        searched.search(value).map(items => {
          return items.item;
        }),
      );
    } else {
      setUsers(usersAll);
    }
  }

  function filter(option: string) {
    switch (option) {
      case '1':
        setUsers(
          usersAll.filter(user => {
            return user.type === 'STUDENT';
          }),
        );
        break;
      case '2':
        setUsers(
          usersAll.filter(user => {
            return user.type === 'TEACHER';
          }),
        );
        break;
      case '3':
        setUsers(
          usersAll.filter(user => {
            return user.type === 'EMPLOYEE';
          }),
        );
        break;
      case '4':
        setUsers(
          usersAll.filter(user => {
            return user.status === 'VALIDATED';
          }),
        );
        break;
      case '5':
        setUsers(
          usersAll.filter(user => {
            return user.status === 'NO_VALIDATED';
          }),
        );
        break;
      case '6':
        setUsers(
          usersAll.filter(user => {
            return user.active;
          }),
        );
        break;
      case '7':
        setUsers(
          usersAll.filter(user => {
            return !user.active;
          }),
        );
        break;
      default:
        setUsers(usersAll);
    }
  }

  function uploadFile(file: any) {
    if (file !== undefined) {
      setLoading(true);
      const form = new FormData();
      form.append('institution', auth.id);
      form.append('file', file);

      Api.post('/upload/import/', form, {
        headers: {
          Authorization: auth.token,
        },
      })
        .then(() => {
          setLoading(false);
          const list = document.querySelector(
            '.ant-upload-list-item-container',
          ) as any;
          list.innerHTML = null;
          queryValitations.refetch({ institution: auth.id });
        })
        .catch(() => {
          setLoading(false);
        });
    }
  }

  return (
    <Container>
      <Spin spinning={loading} fullscreen indicator={<Loading />} />

      <SearchContainer>
        <SearchInput
          placeholder=" Pesquise..."
          size="large"
          prefix={<SearchOutlined />}
          onChange={e => {
            search(e.target.value);
          }}
        />
        <Filter
          treeData={filters}
          placeholder="Filtros"
          onChange={(value: any) => {
            filter(value);
          }}
          size="large"
        />
        <Upload
          accept=".csv"
          maxCount={1}
          multiple={false}
          beforeUpload={() => {
            return false;
          }}
          onChange={file => {
            uploadFile(file.fileList[0]?.originFileObj);
          }}
        >
          <Button type="primary" size="large">
            Importar dados
          </Button>
        </Upload>
        <Button size="large">
          <SyncOutlined
            onClick={() => {
              queryValitations.refetch();
            }}
          />
        </Button>
      </SearchContainer>
      <View>
        <Divider orientation="left">
          <b>| GERENCIAMENTO DE VALIDAÇÕES DOS USUÁRIOS</b>
        </Divider>
      </View>
      <Cards>
        {users.map(user => {
          return (
            <Validation
              id={`${user._id}`}
              key={user._id}
              name={user.name}
              active={user.active}
              type={
                user.type === 'EMPLOYEE'
                  ? 'Funcionário'
                  : user.type === 'STUDENT'
                    ? 'Aluno'
                    : 'Professor'
              }
              cpf={user.cpf}
              reponsible={
                user.responsible !== null && user.type === 'STUDENT'
                  ? user.responsible?.name
                  : undefined
              }
              responsibleNumber={
                user.responsible !== null
                  ? user.responsible?.telephone
                  : undefined
              }
              validate={user.status !== 'NO_VALIDATED'}
              image={user.image}
              imported={user.isImported}
              icon={
                user.type === 'EMPLOYEE' ? (
                  <GrUserWorker />
                ) : user.type === 'STUDENT' ? (
                  <PiStudentBold />
                ) : (
                  <FaChalkboardTeacher />
                )
              }
              email={user.email}
              isStudent={user.type === 'STUDENT'}
              onAlterType={alterType}
              onActive={alterActive}
              onStatus={alterStatus}
            />
          );
        })}
      </Cards>
    </Container>
  );
};

export default Validations;
