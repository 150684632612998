import { StyleSheet } from '@react-pdf/renderer';

const Styles = StyleSheet.create({
  screen: {
    height: '100vh',
    width: '100%',
  },
  logo: {
    margin: '2%',
    width: '20%',
  },
  titleFont: {
    fontFamily: 'Helvetica-Bold',
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    padding: '1%',
    rowGap: 2,
  },
  containerOcurrence: {
    width: '100%',
  },
  ocurrenceTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    backgroundColor: '#003e77',
    color: '#ffff',
    fontSize: 14,
    width: '100%',
    padding: '1%',
    gap: 10,
  },
  ocurrenceImage: {
    width: '3%',
  },
  subtitle: {
    display: 'flex',
    backgroundColor: '#9c9c9c',
    fontSize: 10,
    width: '100%',
    padding: '1%',
  },
  infosContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 5,
    fontSize: 10,
  },
  infosCol: {
    rowGap: 5,
  },
  infosRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  description: {
    width: '100%',
    border: 2,
    borderColor: '#9c9c9c',
    padding: 5,
    fontSize: 10,
  },
  list: {
    width: '100%',
    fontSize: 10,
  },
});

export { Styles };
