import styled from 'styled-components';

const Container = styled.div`
  background-color: #f4f4f4;
  width: 14rem;
  height: 7.1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: solid;
  border-width: 1px;
  border-radius: 8px;
  border-color: #c2c2c2;
  margin-left: 1%;
  margin-right: 1%;
  @media (max-width: 1500px) {
    width: 20%;
    height: 75%;
  }
`;

const Title = styled.p`
  font-size: 0.9rem;
  color: #818181;
  font-weight: 600;
  @media (max-width: 1500px) {
    font-size: 0.7rem;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Cont = styled.p`
  font-size: 3rem;
  color: #090909;
  font-weight: 600;
  margin-right: 5px;
  @media (max-width: 1400px) {
    font-size: 2rem;
  }
`;

const Icon = styled.img`
  height: 70%;
  width: 70%;
  margin-left: 5px;
`;

export { Container, Title, Content, Cont, Icon };
