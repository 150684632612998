import { MapContainer } from 'react-leaflet';
import styled from 'styled-components';

const Map = styled(MapContainer)`
  height: 100%;
  width: 100%;

  .leaflet-routing-container {
    display: none !important;
  }
`;

const MapButtonsContainer = styled.div`
  position: absolute;
  top: 1.5%;
  right: 1.5%;
  display: flex;
  flex-direction: column;
  border: 2.5px solid #00000036;
  border-radius: 3px;
  width: 14%;
  z-index: 10000;
`;

const MapButton = styled.button`
  position: relative;
  cursor: pointer;
  border: none;
  display: flex;
  align-items: stretch;
  background-color: #ffffff;
  font-size: 13px;
  height: 1%;

  .anticon {
    padding: 5%;
    border-right: 1px solid #00000036;
  }

  &:last-child {
    border-top: 1px solid #bcbcbc;
  }
`;

const ButtonText = styled.p`
  width: 100%;
  text-align: center;
  padding: 5%;
`;

export { Map, MapButtonsContainer, MapButton, ButtonText };
