import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Form, Input } from 'antd';
import iEscolarBRAND from '../../../assets/img/logo-iescolar.svg';
import {
  LoginPage,
  Container,
  LoginLogo,
  LoginForm,
  FormItem,
  LoginButton,
  Options,
  Register,
  QuestionAccount,
  Footer,
  Copyright,
  InfoContainer,
  SubContainer,
  ErrorAlert,
  CompanyName,
  TitleSupport,
  PhoneTitle,
  Pipe,
  Version,
} from './styles';
import { setStorage } from '../../../utils/storage';
import { Api } from '../../../services/api';
import Logo from '../../../assets/img/Logo.svg';

const Login: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loginError, setLoginError] = useState(false);

  const handleSubmit = async () => {
    await Api.post('/login/dashboard', {
      email,
      password,
    })
      .then(resp => {
        setStorage(`${process.env.REACT_APP_AUTH}`, resp.data);
        setStorage(`${process.env.REACT_APP_PANICS}`, []);
        window.location.reload();
      })
      .catch(() => {
        setLoginError(true);
      });
  };

  return (
    <LoginPage>
      <Container>
        <LoginLogo>
          <img src={Logo} alt="" width={'60%'} draggable={false} />
        </LoginLogo>
        <LoginForm>
          <Form layout="vertical">
            {loginError ? (
              <ErrorAlert
                message="Verifique seus dados"
                type="error"
                showIcon
                closable
                onClose={() => {
                  setLoginError(false);
                }}
              />
            ) : null}

            <FormItem label="CREDENCIAL:">
              <Input
                placeholder="Digite o suas credenciais"
                value={email}
                size="large"
                onChange={e => {
                  setEmail(e.target.value);
                }}
              />
            </FormItem>

            <FormItem label="CHAVE:">
              <Input.Password
                placeholder="Digite sua chave de acesso"
                value={password}
                size="large"
                onChange={e => {
                  setPassword(e.target.value);
                }}
              />
            </FormItem>

            <Form.Item>
              <LoginButton
                size="large"
                type="primary"
                onClick={async () => {
                  await handleSubmit();
                }}
              >
                Entrar
              </LoginButton>
            </Form.Item>
          </Form>

          <Options>
            <Register>
              <QuestionAccount>Não tem conta? </QuestionAccount>
              <Link className="link" to={'/register'}>
                Criar conta
              </Link>
            </Register>
            <Link className="link" to={'/email-verification'}>
              Esqueceu sua chave?
            </Link>
          </Options>
        </LoginForm>

        <Footer>
          <InfoContainer>
            <img src={iEscolarBRAND} width={77} />
            <Pipe>|</Pipe>
            <SubContainer>
              <TitleSupport>Suporte</TitleSupport>
              <PhoneTitle>(66) 98101-1961</PhoneTitle>
            </SubContainer>
          </InfoContainer>
          <Copyright>
            © 2014 - {new Date().getFullYear()} RedeNet. Todos os direitos
            reservados.
          </Copyright>
          <Version> 1.0 - [versão beta]</Version>
        </Footer>
      </Container>
    </LoginPage>
  );
};

export default Login;
