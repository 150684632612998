function distanceKmBetweenPoints(from: number[], to: number[]) {
  const radLat1 = (Math.PI * from[1]) / 180;
  const radLat2 = (Math.PI * to[1]) / 180;
  const radLon1 = (Math.PI * from[0]) / 180;
  const radLon2 = (Math.PI * to[0]) / 180;
  const theta = from[0] - to[0];
  const radTheta = (Math.PI * theta) / 180;
  let distance =
    Math.sin(radLat1) * Math.sin(radLat2) +
    Math.cos(radLat1) * Math.cos(radLat2) * Math.cos(radTheta);
  distance = Math.acos(distance);
  distance = (distance * 180) / Math.PI;
  distance = distance * 60 * 1.1515;
  return Number((distance * 1.609344).toFixed(3));
}

export { distanceKmBetweenPoints };
