import styled from 'styled-components';
import { Modal } from 'antd';
import { FaMapMarkerAlt } from 'react-icons/fa';

const EmergencyModal = styled(Modal)`
  .ant-modal-header {
    display: flex;
    justify-content: center;
  }
  .ant-modal-body {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  .ant-modal-title {
    font-size: 36px;
  }
  .ant-modal-content p {
    font-size: 18px;
    text-align: justify;
  }
`;

const ActuationEmergencyInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
`;

const Address = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const Marker = styled(FaMapMarkerAlt)`
  font-size: 14px;
`;

export { EmergencyModal, ActuationEmergencyInfo, Address, Marker };
