import styled from 'styled-components';
import { Avatar } from 'antd';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  padding-left: 2em;
  padding-right: 3em;
  width: 16em;
  height: 5em;
  background-color: #0d0d0d;
  border-radius: 10px;
  border-width: 1px;
  border-style: solid;
  border-color: #d3d3d3;
`;

const ImageSchool = styled(Avatar)`
  border-radius: 10px;
  border-style: solid;
  border-width: 2px;
  border-color: #d9d9d9;
`;

const Hello = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.p`
  color: #ffff;
  font-size: 19px;
  font-weight: 600;
`;

const Text = styled.p`
  color: #ffff;
  font-size: 14px;
  margin-bottom: 0.3em;
`;

export { Container, ImageSchool, Hello, Title, Text };
