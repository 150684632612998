import React, { useEffect, useState } from 'react';
import { RouteInstructionsI } from '../../utils/types';
import {
  CloseButton,
  Container,
  Instruction,
  InstructionText,
  InstructionsContainer,
} from './styles';
import translate from 'translate';
import { Skeleton } from 'antd';

const RouteInstructions: React.FC<RouteInstructionsI> = ({
  route,
  onClose,
  isOpen,
}) => {
  const [displayRoute, setDisplayRoute] = useState<any>(undefined);
  const [instructions, setInstructions] = useState<any[]>([]);
  const [texts, setTexts] = useState<string[]>([]);
  const [open, setOpen] = useState(isOpen);
  const [isLoading, setIsLoading] = useState(true);

  function handleClose() {
    setDisplayRoute(undefined);
    onClose();
  }

  async function getInstructions() {
    const translatedArr: string[] = [];
    for (const instruction of route.instructions) {
      await translate(instruction.text, 'pt')
        .then(translated => {
          translatedArr.push(translated);
        })
        .catch(error => console.log(error));
    }
    setTexts(translatedArr);
  }

  useEffect(() => {
    setDisplayRoute(route);
    setOpen(isOpen);
  }, [isOpen, route]);

  useEffect(() => {
    if (!displayRoute || !open) return;
    setIsLoading(true);
    setInstructions(displayRoute.instructions);
    getInstructions().then(() => setIsLoading(false));
  }, [displayRoute, open]);

  return route && isOpen ? (
    <Container>
      <CloseButton onClick={handleClose}>Fechar instruções</CloseButton>
      <InstructionsContainer>
        <Skeleton active loading={isLoading}>
          {instructions.map((instruction, index) => (
            <Instruction key={index}>
              <InstructionText>
                {texts[index] && (
                  <p>
                    {texts[index].includes('(') ? (
                      <>
                        {texts[index].slice(0, -8)}
                        <span>
                          {texts[index].slice(-8, texts[index].length)}
                        </span>
                      </>
                    ) : (
                      texts[index]
                    )}
                  </p>
                )}
                <p>{Math.round(instruction.distance)} m</p>
              </InstructionText>
            </Instruction>
          ))}
        </Skeleton>
      </InstructionsContainer>
    </Container>
  ) : null;
};

export default RouteInstructions;
