import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import {
  Container,
  LayoutHeader,
  LayoutContent,
  ExitButton,
  ButtonColor,
  LogoContainer,
} from './styles';
import { Popconfirm } from 'antd';
import Logo from '../../../../assets/img/Logo.svg';
import PanicAlert from '../../../../components/PanicAlert';
import EmergencyAlert from '../../../../components/EmergencyAlert';
import { useQuery } from '@apollo/client';
import {
  SECURITY_SERVICE_BY_PROFILE,
  OCCURRENCE_BY_SECURITY_BODY,
} from '../../../../utils/queries/securityBodyQueries';
import { getStorage, setStorage } from '../../../../utils/storage';
import { base64ToObject } from '../../../../utils/converters';
import { closeAlert, wasOpenAlert } from '../../../../utils/manageralerts';
import {
  connection,
  query,
  ref,
  onValue,
  limitToLast,
} from '../../../../services/firebase';
import { CollectionRealTimeDatabase } from '../../../../utils/enums';
import { HiMiniArrowLeftOnRectangle } from 'react-icons/hi2';
import { LogoutOutlined } from '@ant-design/icons';

const SecurityBodyLayout: React.FC = () => {
  const auth = base64ToObject(getStorage(`${process.env.REACT_APP_AUTH}`));
  const navigate = useNavigate();
  const [isPanicModalOpen, setIsPanicModalOpen] = useState(false);
  const [isEmergencyModalOpen, setIsEmergencyModalOpen] = useState(false);
  const [occurrence, setOccurrence] = useState();
  const queryProfile = useQuery(SECURITY_SERVICE_BY_PROFILE, {
    variables: {
      id: auth.id,
    },
  });
  const queryOccurrence = useQuery(OCCURRENCE_BY_SECURITY_BODY, {
    variables: {
      body: auth.id,
      occuID: null,
    },
  });

  function clearLocalStorage() {
    localStorage.clear();
  }

  function showPanicModal() {
    setIsPanicModalOpen(true);
  }

  function handlePanicCancel(id: string) {
    setIsPanicModalOpen(false);
    closeAlert(id);
  }

  function showEmergencyModal() {
    setIsEmergencyModalOpen(true);
  }

  function handleEmergencyCancel(id: string) {
    setIsEmergencyModalOpen(false);
    closeAlert(id);
  }

  function refetch(occurrence: any) {
    queryOccurrence
      .refetch({ body: auth.id, occuID: occurrence.mongoId })
      .then(resp => {
        setOccurrence(resp.data.getOccurrenceBySecurityBody);

        if (resp.data.getOccurrenceBySecurityBody !== null) {
          const time = Math.abs(
            Math.round(
              (new Date(resp.data.getOccurrenceBySecurityBody.date).getTime() -
                new Date().getTime()) /
                1000 /
                60,
            ),
          );

          if (time <= 7 && !wasOpenAlert(occurrence.mongoId, occurrence.type)) {
            if (occurrence.type === 'PANIC') {
              showPanicModal();
            } else {
              if (
                occurrence.type === 'EMERGENCY' &&
                !wasOpenAlert(occurrence.mongoId)
              ) {
                showEmergencyModal();
              }
            }
          }
        }
      })
      .catch();
  }

  useEffect(() => {
    onValue(
      query(
        ref(connection, `${CollectionRealTimeDatabase.OCCURRENCE}`),
        limitToLast(1),
      ),
      snapshot => {
        snapshot.forEach(child => {
          refetch(child.val());
        });
      },
    );
  }, []);

  return (
    <Container>
      <EmergencyAlert
        occurrence={occurrence}
        activate={isEmergencyModalOpen}
        disable={handleEmergencyCancel}
      />
      <PanicAlert
        occurrence={occurrence}
        activate={isPanicModalOpen}
        disable={handlePanicCancel}
      />
      <LayoutHeader>
        <LogoContainer>
          <img src={Logo} alt="" width={'45%'} draggable={false} />
          {queryProfile.data?.getSecurityBodyByProfileId?.profile ===
            undefined &&
          queryProfile.data?.getSecurityBodyByProfileId?.profile ===
            null ? null : (
            <>
              <p style={{ color: '#ffff', fontSize: 30 }}>|</p>
              <img
                src={
                  queryProfile.data?.getSecurityBodyByProfileId?.profile?.image
                }
                alt=""
                width={35}
                height={35}
                draggable={false}
              />
            </>
          )}
        </LogoContainer>
        <Popconfirm
          title={''}
          description="Tem certeza que deseja sair da sua conta?"
          placement="bottomRight"
          onConfirm={() => {
            clearLocalStorage();
            navigate('/');
          }}
          okText="Sim"
          cancelText="Não"
          icon={null}
          okButtonProps={{ style: ButtonColor.cancel }}
        >
          <div>
            <ExitButton type="text">
              <HiMiniArrowLeftOnRectangle className="svg-icons" />
              Sair
            </ExitButton>
          </div>
        </Popconfirm>
      </LayoutHeader>

      <LayoutContent>
        <Outlet />
      </LayoutContent>
    </Container>
  );
};

export default SecurityBodyLayout;
