import Brazil_Flag from '../assets/uf/flags/square-rounded/01-brasil-square-rounded.svg';
import Brazil_Area from '../assets/uf/area/Brazil.svg';
import Acre_Flag from '../assets/uf/flags/square-rounded/02-acre-square-rounded.svg';
import Acre_Area from '../assets/uf/area/Acre.svg';
import Alagoas_Flag from '../assets/uf/flags/square-rounded/03-alagoas-square-rounded.svg';
import Alagoas_Area from '../assets/uf/area/Alagoas.svg';
import Amapa_Flag from '../assets/uf/flags/square-rounded/04-amapa-square-rounded.svg';
import Amapa_Area from '../assets/uf/area/Amapa.svg';
import Amazonas_Flag from '../assets/uf/flags/square-rounded/05-amazonas-square-rounded.svg';
import Amazonas_Area from '../assets/uf/area/Amazonas.svg';
import Bahia_Flag from '../assets/uf/flags/square-rounded/06-bahia-square-rounded.svg';
import Bahia_Area from '../assets/uf/area/Bahia.svg';
import Ceara_Flag from '../assets/uf/flags/square-rounded/07-ceara-square-rounded.svg';
import Ceara_Area from '../assets/uf/area/Ceara.svg';
import Distrito_Federal_Flag from '../assets/uf/flags/square-rounded/08-distrito-federal-square-rounded.svg';
import Distrito_Federal_Area from '../assets/uf/area/Distrito_Federal.svg';
import Espirito_Santo_Flag from '../assets/uf/flags/square-rounded/09-espirito-santo-square-rounded.svg';
import Espirito_Santo_Area from '../assets/uf/area/Espirito_Santo.svg';
import Goias_Flag from '../assets/uf/flags/square-rounded/10-goias-square-rounded.svg';
import Goias_Area from '../assets/uf/area/Goias.svg';
import Maranhao_Flag from '../assets/uf/flags/square-rounded/11-maranhao-square-rounded.svg';
import Maranhao_Area from '../assets/uf/area/Maranhao.svg';
import Mato_Grosso_do_Sul_Flag from '../assets/uf/flags/square-rounded/13-mato-grosso-do-sul-square-rounded.svg';
import Mato_Grosso_do_Sul_Area from '../assets/uf/area/Mato_Grosso_do_Sul.svg';
import Mato_Grosso_Flag from '../assets/uf/flags/square-rounded/12-mato-grosso-square-rounded.svg';
import Mato_Groso_Area from '../assets/uf/area/Mato_Grosso.svg';
import Minas_Gerais_Flag from '../assets/uf/flags/square-rounded/14-minas-gerais-square-rounded.svg';
import Minas_Gerais_Area from '../assets/uf/area/Minas_Gerais.svg';
import Para_Flag from '../assets/uf/flags/square-rounded/15-para-square-rounded.svg';
import Para_Area from '../assets/uf/area/Para.svg';
import Paraiba_Flag from '../assets/uf/flags/square-rounded/16-paraiba-square-rounded.svg';
import Paraiba_Area from '../assets/uf/area/Paraiba.svg';
import Parana_Flag from '../assets/uf/flags/square-rounded/17-parana-square-rounded.svg';
import Parana_Area from '../assets/uf/area/Parana.svg';
import Pernambuco_Flag from '../assets/uf/flags/square-rounded/18-pernambuco-square-rounded.svg';
import Pernambuco_Area from '../assets/uf/area/Pernambuco.svg';
import Piaui_Flag from '../assets/uf/flags/square-rounded/19-piaui-square-rounded.svg';
import Piaui_Area from '../assets/uf/area/Piaui.svg';
import Rio_de_Janeiro_Flag from '../assets/uf/flags/square-rounded/20-rio-de-janeiro-square-rounded.svg';
import Rio_de_Janeiro_Area from '../assets/uf/area/Rio_de_Janeiro.svg';
import Rio_Grande_do_Norte_Flag from '../assets/uf/flags/square-rounded/21-rio-grande-do-norte-square-rounded.svg';
import Rio_Grande_do_Norte_Area from '../assets/uf/area/Rio_Grande_do_Norte.svg';
import Rio_Grande_do_Sul_Flag from '../assets/uf/flags/square-rounded/22-rio-grande-do-sul-square-rounded.svg';
import Rio_Grande_do_Sul_Area from '../assets/uf/area/Rio_Grande_do_Sul.svg';
import Rondonia_Flag from '../assets/uf/flags/square-rounded/23-rondonia-square-rounded.svg';
import Rondonia_Area from '../assets/uf/area/Rondonia.svg';
import Roraima_Flag from '../assets/uf/flags/square-rounded/24-roraima-square-rounded.svg';
import Roraima_Area from '../assets/uf/area/Roraima.svg';
import Santa_Catarina_Flag from '../assets/uf/flags/square-rounded/25-santa-catarina-square-rounded.svg';
import Santa_Catarina_Area from '../assets/uf/area/Santa_Catarina.svg';
import Sao_Paulo_Flag from '../assets/uf/flags/square-rounded/26-sao-paulo-square-rounded.svg';
import Sao_Paulo_Area from '../assets/uf/area/Sao_Paulo.svg';
import Sergipe_Flag from '../assets/uf/flags/square-rounded/27-sergipe-square-rounded.svg';
import Sergipe_Area from '../assets/uf/area/Sergipe.svg';
import Tocantins_Flag from '../assets/uf/flags/square-rounded/28-tocantins-square-rounded.svg';
import Tocantins_Area from '../assets/uf/area/Tocantins.svg';

const brasil = {
  info: {
    bandeira: Brazil_Flag,
    area: Brazil_Area,
  },
  ufs: [
    {
      nome: 'Acre',
      sigla: 'AC',
      bandeira: Acre_Flag,
      area: Acre_Area,
      regiao: 'Norte',
      municipios: 22,
      artigo: 'o',
    },
    {
      nome: 'Alagoas',
      sigla: 'AL',
      bandeira: Alagoas_Flag,
      area: Alagoas_Area,
      regiao: 'Nordeste',
      municipios: 102,
      artigo: 'e',
    },
    {
      nome: 'Amapá',
      sigla: 'AP',
      bandeira: Amapa_Flag,
      area: Amapa_Area,
      regiao: 'Norte',
      municipios: 16,
      artigo: 'o',
    },
    {
      nome: 'Amazonas',
      sigla: 'AM',
      bandeira: Amazonas_Flag,
      area: Amazonas_Area,
      regiao: 'Norte',
      municipios: 62,
      artigo: 'o',
    },
    {
      nome: 'Bahia',
      sigla: 'BA',
      bandeira: Bahia_Flag,
      area: Bahia_Area,
      regiao: 'Nordeste',
      municipios: 417,
      artigo: 'a',
    },
    {
      nome: 'Ceará',
      sigla: 'CE',
      bandeira: Ceara_Flag,
      area: Ceara_Area,
      regiao: 'Nordeste',
      municipios: 184,
      artigo: 'o',
    },
    {
      nome: 'Distrito Federal',
      sigla: 'DF',
      bandeira: Distrito_Federal_Flag,
      area: Distrito_Federal_Area,
      regiao: 'Centro-Oeste',
      municipios: 1,
      artigo: 'o',
    },
    {
      nome: 'Espirito Santo',
      sigla: 'ES',
      bandeira: Espirito_Santo_Flag,
      area: Espirito_Santo_Area,
      regiao: 'Suldeste',
      municipios: 78,
      artigo: 'o',
    },
    {
      nome: 'Goiás',
      sigla: 'GO',
      bandeira: Goias_Flag,
      area: Goias_Area,
      regiao: 'Centro-Oeste',
      municipios: 246,
      artigo: 'e',
    },
    {
      nome: 'Maranhão',
      sigla: 'MA',
      bandeira: Maranhao_Flag,
      area: Maranhao_Area,
      regiao: 'Nordeste',
      municipios: 217,
      artigo: 'o',
    },
    {
      nome: 'Mato Grosso',
      sigla: 'MT',
      bandeira: Mato_Grosso_Flag,
      area: Mato_Groso_Area,
      regiao: 'Centro-Oeste',
      municipios: 141,
      artigo: 'o',
    },
    {
      nome: 'Mato Grosso do Sul',
      sigla: 'MS',
      bandeira: Mato_Grosso_do_Sul_Flag,
      area: Mato_Grosso_do_Sul_Area,
      regiao: 'Centro-Oeste',
      municipios: 79,
      artigo: 'o',
    },
    {
      nome: 'Minas Gerais',
      sigla: 'MG',
      bandeira: Minas_Gerais_Flag,
      area: Minas_Gerais_Area,
      regiao: 'Suldeste',
      municipios: 853,
      artigo: 'e',
    },
    {
      nome: 'Pará',
      sigla: 'PA',
      bandeira: Para_Flag,
      area: Para_Area,
      regiao: 'Norte',
      municipios: 144,
      artigo: 'o',
    },
    {
      nome: 'Paraíba',
      sigla: 'PB',
      bandeira: Paraiba_Flag,
      area: Paraiba_Area,
      regiao: 'Nordeste',
      municipios: 223,
      artigo: 'a',
    },
    {
      nome: 'Paraná',
      sigla: 'PR',
      bandeira: Parana_Flag,
      area: Parana_Area,
      regiao: 'Sul',
      municipios: 399,
      artigo: 'o',
    },
    {
      nome: 'Pernambuco',
      sigla: 'PE',
      bandeira: Pernambuco_Flag,
      area: Pernambuco_Area,
      regiao: 'Nordeste',
      municipios: 185,
      artigo: 'o',
    },
    {
      nome: 'Piauí',
      sigla: 'PI',
      bandeira: Piaui_Flag,
      area: Piaui_Area,
      regiao: 'Nordeste',
      municipios: 224,
      artigo: 'o',
    },
    {
      nome: 'Rio de Janeiro',
      sigla: 'RJ',
      bandeira: Rio_de_Janeiro_Flag,
      area: Rio_de_Janeiro_Area,
      regiao: 'Suldeste',
      municipios: 92,
      artigo: 'o',
    },
    {
      nome: 'Rio Grande do Norte',
      sigla: 'RN',
      bandeira: Rio_Grande_do_Norte_Flag,
      area: Rio_Grande_do_Norte_Area,
      regiao: 'Nordeste',
      municipios: 167,
      artigo: 'o',
    },
    {
      nome: 'Rio Grande do Sul',
      sigla: 'RS',
      bandeira: Rio_Grande_do_Sul_Flag,
      area: Rio_Grande_do_Sul_Area,
      regiao: 'Sul',
      municipios: 497,
      artigo: 'o',
    },
    {
      nome: 'Rondônia',
      sigla: 'RO',
      bandeira: Rondonia_Flag,
      area: Rondonia_Area,
      regiao: 'Norte',
      municipios: 52,
      artigo: 'e',
    },
    {
      nome: 'Roraima',
      sigla: 'RR',
      bandeira: Roraima_Flag,
      area: Roraima_Area,
      regiao: 'Norte',
      municipios: 15,
      artigo: 'e',
    },
    {
      nome: 'Santa Catarina',
      sigla: 'SC',
      bandeira: Santa_Catarina_Flag,
      area: Santa_Catarina_Area,
      regiao: 'Sul',
      municipios: 295,
      artigo: 'e',
    },
    {
      nome: 'São Paulo',
      sigla: 'SP',
      bandeira: Sao_Paulo_Flag,
      area: Sao_Paulo_Area,
      regiao: 'Suldeste',
      municipios: 645,
      artigo: 'e',
    },
    {
      nome: 'Sergipe',
      sigla: 'SE',
      bandeira: Sergipe_Flag,
      area: Sergipe_Area,
      regiao: 'Nordeste',
      municipios: 75,
      artigo: 'e',
    },
    {
      nome: 'Tocantins',
      sigla: 'TO',
      bandeira: Tocantins_Flag,
      area: Tocantins_Area,
      regiao: 'Norte',
      municipios: 139,
      artigo: 'e',
    },
  ],
};

export default brasil;
