import React, { useEffect, useState } from 'react';
import {
  Container,
  Col1,
  Col2,
  ListContainer,
  Filter,
  List,
  PrintModal,
  PrintButtonContainer,
  PrintButton,
  FloatEmergency,
  FloatPanic,
  RangePicker,
  GoBackButton,
  ShowAllButton,
  ListHeader,
} from './styles';
import OpenStreetMaps from '../../../../components/OpenStreetMap';
import YearFilter from '../../../../components/YearFilter';
import Inform from '../../../../components/Inform';
import { FloatButton, Form, TreeSelect } from 'antd';
import {
  PlusOutlined,
  PrinterFilled,
  ReloadOutlined,
  ArrowLeftOutlined,
} from '@ant-design/icons';
import dayjs from 'dayjs';
import { useQuery } from '@apollo/client';
import {
  ALL_OCCURRENCES,
  ALL_OCCURRENCES_BY_DATE_AND_TYPE,
  OCCURRENCE_BY_SECURITY_BODY,
  SECURITY_SERVICE_BY_PROFILE,
} from '../../../../utils/queries/securityBodyQueries';
import { InformE } from '../../../../utils/entities';
import { base64ToObject, objectToBase64 } from '../../../../utils/converters';
import { getStorage, setStorage } from '../../../../utils/storage';
import {
  connection,
  query,
  ref,
  onValue,
  limitToLast,
} from '../../../../services/firebase';
import { CollectionRealTimeDatabase } from '../../../../utils/enums';
import { wasOpenAlert } from '../../../../utils/manageralerts';
import * as L from 'leaflet';
import 'leaflet-routing-machine';
import { MapView } from '../../../../components/MapController';
import Icon from 'leaflet/dist/images/marker-icon.png';
import RouteInstructions from '../../../../components/RouteInstructions';
const Leaflet = L as any;

const SecurityBodyHome: React.FC = () => {
  const auth = base64ToObject(getStorage(`${process.env.REACT_APP_AUTH}`));
  const [type, setType] = useState<string>();
  const [level, setLevel] = useState<string>();
  const [dateStart, setDateStart] = useState<Date | undefined>(undefined);
  const [dateEnd, setDateEnd] = useState<Date | undefined>(undefined);
  const [isPrintModalOpen, setIsPrintModalOpen] = useState(false);
  const [coordinates, setCoordinates] = useState<any>(undefined);
  const [isShowAll, setIsShowAll] = useState(false);
  const [informs, setInforms] = useState<InformE[]>([]);
  const [informsAll, setInformsAll] = useState<InformE[]>([]);
  const [year, setYear] = useState(new Date().getFullYear());
  const [route, setRoute] = useState<any>(undefined);
  const [routeControl, setRouteControl] = useState<any>(undefined);
  const [position, setPosition] = useState(true);
  const [isRouteModalOpen, setIsRouteModalOpen] = useState(false);

  const queryOccurrence = useQuery(OCCURRENCE_BY_SECURITY_BODY, {
    variables: {
      body: auth.id,
      occuID: null,
    },
  });

  const queryOccurrencies = useQuery(ALL_OCCURRENCES, {
    variables: { id: auth.id, year },
  });

  const queryDataProfile = useQuery(SECURITY_SERVICE_BY_PROFILE, {
    variables: {
      id: auth.id,
    },
  });

  const queryOccurrenceDataPdf = useQuery(ALL_OCCURRENCES_BY_DATE_AND_TYPE, {
    variables: {
      id: auth.id,
    },
  });

  function genaretePdf() {
    queryOccurrenceDataPdf
      .refetch({
        body: auth.id,
        type,
        level,
        dateStart,
        dateEnd,
      })
      .then(resp => {
        setStorage(
          `${process.env.REACT_APP_PDF_FILE}`,
          objectToBase64({
            dateStart,
            dateEnd,
            occurrencies: resp.data.getOccurrenceAllBySecurityBodyTypeDate,
          }),
        );
        window.open('/security-bodies/pdf', '_blank');
      })
      .catch(() => {});
  }

  useEffect(() => {
    const ocurrences: InformE[] =
      queryOccurrencies.data?.getOccurrenceAllBySecurityBodyYear !== undefined
        ? queryOccurrencies.data?.getOccurrenceAllBySecurityBodyYear
        : [];

    setInformsAll(ocurrences);

    setInforms(
      isShowAll
        ? ocurrences
        : ocurrences.filter((ocurrence, index) => {
            return index < 3;
          }),
    );
  }, [queryOccurrencies.data?.getOccurrenceAllBySecurityBodyYear]);

  useEffect(() => {
    if (coordinates === undefined && queryDataProfile.data !== undefined) {
      setCoordinates(
        queryDataProfile.data?.getSecurityBodyByProfileId !== null
          ? [
              queryDataProfile.data?.getSecurityBodyByProfileId.address
                .localization.coordinates[1],
              queryDataProfile.data?.getSecurityBodyByProfileId.address
                .localization.coordinates[0],
            ]
          : undefined,
      );
    }
  }, [queryDataProfile.data?.getSecurityBodyByProfileId]);

  const handleShowAll = () => {
    setInforms(informsAll);
    setIsShowAll(true);
  };

  const handleShowLess = () => {
    setInforms(
      informsAll.filter((ocurrence, index) => {
        return index < 3;
      }),
    );
    setIsShowAll(false);
  };

  const showPrintModal = () => {
    setIsPrintModalOpen(true);
  };

  const handlePrintCancel = () => {
    setIsPrintModalOpen(false);
  };

  const handleOk = () => {
    genaretePdf();
    setIsPrintModalOpen(false);
  };

  const treeData = [
    {
      title: 'Todos',
      value: 'ALL',
    },
    {
      title: 'Emergência',
      value: 'EMERGENCY',
      children: [
        {
          title: 'Alta',
          value: 'HIGH',
        },
        {
          title: 'Média',
          value: 'MEDIUM',
        },
        {
          title: 'Baixa',
          value: 'LOW',
        },
      ],
    },
    {
      title: 'Pânico',
      value: 'PANIC',
    },
  ];

  function filterYear(value: string) {
    // console.log(value);
  }

  function filterType(option: string) {
    switch (option) {
      case '1':
        setIsShowAll(true);
        setInforms(
          informsAll.filter(inform => {
            return inform.type === 'EMERGENCY';
          }),
        );
        break;

      case '2':
        setIsShowAll(true);
        setInforms(
          informsAll.filter(inform => {
            return inform.type === 'PANIC';
          }),
        );
        break;

      default:
        setIsShowAll(true);
        setInforms(informsAll);
        break;
    }
  }

  function newCoordinates(occurrence: any) {
    queryOccurrence
      .refetch({ body: auth.id, occuID: occurrence.mongoId })
      .then(resp => {
        if (resp.data.getOccurrenceBySecurityBody !== null) {
          const time = Math.abs(
            Math.round(
              (new Date(resp.data.getOccurrenceBySecurityBody.date).getTime() -
                new Date().getTime()) /
                1000 /
                60,
            ),
          );

          if (time <= 7 && !wasOpenAlert(occurrence.mongoId, occurrence.type)) {
            setCoordinates([
              occurrence.localization[1],
              occurrence.localization[0],
            ]);
          } else {
            if (
              queryDataProfile.data?.getSecurityBodyByProfileId !== undefined
            ) {
              setCoordinates([
                queryDataProfile.data?.getSecurityBodyByProfileId.address
                  .localization.coordinates[1],
                queryDataProfile.data?.getSecurityBodyByProfileId.address
                  .localization.coordinates[0],
              ]);
            }
          }
        }
      })
      .catch();
  }

  useEffect(() => {
    onValue(
      query(
        ref(connection, `${CollectionRealTimeDatabase.OCCURRENCE}`),
        limitToLast(1),
      ),
      snapshot => {
        snapshot.forEach(child => {
          newCoordinates(child.val());
        });
      },
    );
  }, []);

  function showRoute(destination: number[]) {
    const map: L.Map = MapView.getMap();
    if (routeControl) map.removeControl(routeControl);
    const control = Leaflet.Routing.control({
      waypoints: [
        L.latLng(coordinates[0], coordinates[1]),
        L.latLng(destination[0], destination[1]),
      ],
      lineOptions: {
        styles: [{ color: '#3e8bcf', weight: 7 }],
      },
      createMarker(i: number, waypoint: any, n: number) {
        return L.marker(waypoint.latLng, {
          draggable: true,
          icon: new L.Icon({
            iconUrl: Icon,
            iconSize: new L.Point(20, 30),
            className: 'leaflet-div-icon',
          }),
        });
      },
    });
    setPosition(false);
    control.addTo(map);
    setRouteControl(control);

    control.on('routesfound', (e: any) => {
      setRoute(e.routes[0]);
    });
  }

  function hideRoute() {
    if (!routeControl) return;
    const map = MapView.getMap();
    map.removeControl(routeControl);
    setRouteControl(undefined);
    setRoute(undefined);
    setPosition(true);
  }

  function openRoutesModal() {
    setIsRouteModalOpen(!isRouteModalOpen);
  }

  return (
    <Container>
      <RouteInstructions
        isOpen={isRouteModalOpen}
        onClose={openRoutesModal}
        route={route}
      />
      <Col1>
        {coordinates !== undefined ? (
          <OpenStreetMaps
            coordinates={coordinates}
            position={position}
            isRoute={!!route}
            isRouteModalOpen={isRouteModalOpen}
            onOpenRouteModal={openRoutesModal}
            onHideRoute={hideRoute}
          />
        ) : null}
      </Col1>
      <Col2>
        <ListContainer>
          {isShowAll && (
            <ListHeader>
              <GoBackButton onClick={handleShowLess}>
                <ArrowLeftOutlined />
                <span>Voltar</span>
              </GoBackButton>
              <Filter>
                <YearFilter options={[2024]} onChoice={filterYear} />
              </Filter>
            </ListHeader>
          )}
          <List>
            {informs.map(occurrence => {
              return (
                <Inform
                  id={occurrence._id}
                  key={occurrence._id}
                  type={occurrence.type}
                  text={occurrence.description}
                  level={occurrence.level}
                  person={occurrence.triggers[0].user.name}
                  personFunction={
                    occurrence.triggers[0].user.type === 'STUDENT'
                      ? 'Aluno'
                      : occurrence.triggers[0].user.type === 'TEACHER'
                        ? 'Professor(a)'
                        : 'Funcionário(a)'
                  }
                  images={occurrence.triggers.map(
                    trigger => trigger.user.image,
                  )}
                  responsible={occurrence.triggers[0].user.responsible?.name}
                  responsibleNumber={
                    occurrence.triggers[0].user.responsible?.telephone
                  }
                  institutionId={occurrence.institution?.profile?._id}
                  address={
                    `${occurrence.institution?.address?.street}` +
                    ', ' +
                    `${occurrence.institution?.address?.neighborhood}` +
                    ', n° ' +
                    `${occurrence.institution?.address?.numbering}`
                  }
                  coordinates={
                    occurrence.institution?.address?.localization
                      ?.coordinates || []
                  }
                  onShowRoute={showRoute}
                  count={
                    occurrence.triggers.length > 1
                      ? occurrence.triggers.length
                      : undefined
                  }
                  date={new Date(
                    `${occurrence?.triggers[0]?.actuation}`,
                  ).toLocaleDateString('pt-BR')}
                  hour={new Date(
                    `${occurrence?.triggers[0]?.actuation}`,
                  ).toLocaleTimeString('pt-BR')}
                  origin={coordinates}
                />
              );
            })}
          </List>
          {isShowAll ? null : (
            <ShowAllButton onClick={handleShowAll}>
              <p>Mostrar todas as ocorrências</p>
            </ShowAllButton>
          )}
        </ListContainer>
        {isShowAll && (
          <FloatButton.Group
            trigger="click"
            type="primary"
            shape="square"
            style={{ right: 40 }}
            icon={<PlusOutlined />}
          >
            <FloatButton
              icon={<PrinterFilled />}
              tooltip={<div>Impressão</div>}
              onClick={showPrintModal}
            />
            <FloatButton
              icon={<FloatEmergency />}
              tooltip={<div>Exibir emergências</div>}
              onClick={() => {
                filterType('1');
              }}
            />
            <FloatButton
              icon={<FloatPanic />}
              tooltip={<div>Exibir pânicos</div>}
              onClick={() => {
                filterType('2');
              }}
            />
            <FloatButton
              icon={<ReloadOutlined />}
              tooltip={<div>Exibir todos</div>}
              onClick={() => {
                filterType('3');
              }}
            />
          </FloatButton.Group>
        )}
      </Col2>

      <PrintModal
        title="Imprimir casos"
        open={isPrintModalOpen}
        onCancel={handlePrintCancel}
        footer={null}
        centered
        width={400}
      >
        <Form layout="vertical">
          <Form.Item label="Tipo:">
            <TreeSelect
              value={level !== undefined ? level : type}
              treeData={treeData}
              placeholder="Selecione o tipo da ocorrência"
              treeDefaultExpandAll
              onSelect={value => {
                if (value === 'LOW' || value === 'MEDIUM' || value === 'HIGH') {
                  setType('EMERGENCY');
                  setLevel(value);
                } else {
                  setType(value);
                  setLevel(undefined);
                }
              }}
            />
          </Form.Item>
          <Form.Item label="Intervalo:">
            <RangePicker
              format={'DD/MM/YYYY'}
              placement="topLeft"
              placeholder={['Início', 'Fim']}
              defaultValue={
                dateStart === undefined && dateEnd === undefined
                  ? ([] as any)
                  : [dayjs(dateStart), dayjs(dateEnd)]
              }
              onCalendarChange={(value: any) => {
                setDateStart(value[0]?.$d);
                setDateEnd(value[1]?.$d);
              }}
            />
          </Form.Item>
          <PrintButtonContainer>
            <PrintButton type="primary" onClick={handleOk}>
              Imprimir
            </PrintButton>
          </PrintButtonContainer>
        </Form>
      </PrintModal>
    </Container>
  );
};

export default SecurityBodyHome;
