import styled from 'styled-components';
import { Modal, Form, Button, Avatar } from 'antd';

const Container = styled.div`
  display: flex;
  align-items: center;
  height: 120px;
  position: relative;
  margin-top: 20%;
  margin-bottom: 10%;
  scale: 1;
  transition: 0.1s;
  &:hover {
    scale: 1.1;
  }
`;

const Card = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  border: solid;
  border-width: 1px;
  border-color: #c5c5c5;
  border-radius: 10px;
  justify-content: space-between;
  padding-left: 3%;
`;

const AvatarContainer = styled.div`
  width: 25%;
  height: 100%;
  display: flex;
  align-items: center;
`;

const Complement = styled.div`
  position: absolute;
  top: -3%;
  right: -3%;
  display: flex;
  flex-direction: column;
`;

const TypePersonAvatar = styled(Avatar)`
  color: #000;
  background-color: #ffff;
`;

const UserInfo = styled.div`
  position: absolute;
  top: 3%;
  left: 1%;
  background-color: #00000000;
  color: #ffff;
  display: flex;
  align-items: center;
`;

const Infos = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 75%;
  height: 100%;
`;

const Text = styled.p`
  font-size: 14px;
  margin-bottom: 1.5%;
`;

const Options = styled.div`
  display: flex;
  justify-content: flex-end;
  position: absolute;
  top: 90%;
  right: 5%;
`;

const ValidateButton = styled(Button)`
  background-color: #33c455 !important;
  margin-right: 4%;
`;

const ChangeButton = styled(Button)`
  background-color: #ff9900 !important;
`;

const InactivateButton = styled(Button)`
  background-color: #ff0000 !important;
`;

const ViewModal = styled(Modal)`
  .ant-modal-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const ModalForm = styled(Form)`
  display: flex;
  flex-direction: column;
  margin-top: 5%;
`;

const FormItem = styled(Form.Item)`
  .ant-form-item-label {
    font-weight: 600;
  }
`;

const ContainerButton = styled(Form.Item)`
  display: flex;
  justify-content: center;
  margin: 0;
`;

const SaveButton = styled(Button)`
  background-color: #33c455 !important;
  width: 200px;
`;

const IconColor = {
  ok: {
    color: '#33c455',
  },
  cancel: {
    color: '#c53f3f',
  },
};

const ButtonColor = {
  ok: {
    background: '#33c455',
  },
  cancel: {
    background: '#c53f3f',
  },
};

export {
  Container,
  Card,
  AvatarContainer,
  TypePersonAvatar,
  Complement,
  UserInfo,
  Infos,
  Text,
  Options,
  ValidateButton,
  ChangeButton,
  ViewModal,
  ModalForm,
  FormItem,
  ContainerButton,
  SaveButton,
  InactivateButton,
  IconColor,
  ButtonColor,
};
