import React from 'react';
import {
  Container,
  Content,
  Title,
  Text,
  Pin,
  Close,
  PublicationDate,
  IconColor,
  ButtonColor,
} from './styles';
import { WarningsI } from '../../utils/types';
import PinSVG from '../../assets/img/pushpin.svg';
import CloseSVG from '../../assets/img/close-icon.svg';
import { Popconfirm } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

const Warning: React.FC<WarningsI> = ({
  value,
  type,
  title,
  text,
  id,
  onDelete,
}) => {
  return (
    <Container value={value}>
      <Popconfirm
        title="Remover aviso?"
        onConfirm={() => {
          onDelete?.(id);
        }}
        onCancel={undefined}
        okText="Sim"
        cancelText="Não"
        icon={<ExclamationCircleOutlined style={IconColor.cancel} />}
        okButtonProps={{ style: ButtonColor.cancel }}
      >
        <Close type={type} src={CloseSVG} alt="" />
      </Popconfirm>
      <Pin src={PinSVG} alt="" height={15} type={type} />
      <Content>
        <Title value={value}>{title}</Title>
        <Text value={value}>{text}</Text>
      </Content>
      <PublicationDate value={value}>
        {new Date().toLocaleDateString()}
      </PublicationDate>
    </Container>
  );
};

export default Warning;
