function getStorage(key: string, valueDefault?: any): any {
  const object = localStorage.getItem(key);
  return object != null ? (JSON.parse(object) as any) : valueDefault;
}

function setStorage(key: string, value: any): void {
  localStorage.setItem(key, JSON.stringify(value));
}

export { getStorage, setStorage };
