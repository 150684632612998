import React, { useEffect, useState } from 'react';
import {
  Container,
  EditForm,
  Inputs,
  FormItem,
  HiddenItem,
  Line,
  InputNumbers,
  Loading,
} from './styles';
import { Button, Input, Select, Spin } from 'antd';
import AvatarEditor from '../../../../components/AvatarEditor';
import { useMutation, useQuery } from '@apollo/client';
import {
  INSTITUTION_BY_ID,
  UPDATE_INSTITUTION,
} from '../../../../utils/queries/institutionQueries';
import { base64ToObject } from '../../../../utils/converters';
import { getStorage } from '../../../../utils/storage';
import { InstitutionE } from '../../../../utils/entities';
import { maskCellPhone, maskCep, maskLandline } from '../../../../utils/masks';
import { ViaCep } from '../../../../services/api';

const InstitutionRegisterData: React.FC = () => {
  const [editForm] = EditForm.useForm();
  const [typePhone, setTypePhone] = useState('1');
  const [enableInput, setEnableInput] = useState(true);
  const [enableButton, setEnableButton] = useState(false);
  const auth = base64ToObject(getStorage(`${process.env.REACT_APP_AUTH}`));
  const [institution, setInstitution] = useState<InstitutionE>();
  const [loading, setLoading] = useState(true);
  const [updateInstitution, updateInstitutionReturn] =
    useMutation(UPDATE_INSTITUTION);

  const queryInstitution = useQuery(INSTITUTION_BY_ID, {
    variables: { id: auth.id },
  });

  useEffect(() => {
    if (updateInstitutionReturn.loading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [updateInstitutionReturn.loading]);

  useEffect(() => {
    setInstitution(queryInstitution.data?.getInstitutionByProfileId);

    editForm.setFieldsValue({
      inep: queryInstitution.data?.getInstitutionByProfileId.inep,
      nome: queryInstitution.data?.getInstitutionByProfileId.profile.name,
      categoria: queryInstitution.data?.getInstitutionByProfileId.category,
      email: queryInstitution.data?.getInstitutionByProfileId.profile.email,
      telefone: queryInstitution.data?.getInstitutionByProfileId.telephone,
      cidade: queryInstitution.data?.getInstitutionByProfileId.address.city,
      cep: queryInstitution.data?.getInstitutionByProfileId.address.cep,
      zona: queryInstitution.data?.getInstitutionByProfileId.address.zone,
      bairro:
        queryInstitution.data?.getInstitutionByProfileId.address.neighborhood,
      rua: queryInstitution.data?.getInstitutionByProfileId.address.street,
      numero:
        queryInstitution.data?.getInstitutionByProfileId.address.numbering,
    });

    let valuePhone = '1';

    if (
      !queryInstitution.data?.getInstitutionByProfileId.telephone.includes('(')
    )
      valuePhone = '2';

    setTypePhone(valuePhone);

    if (queryInstitution.loading) {
      setLoading(!loading);
    } else {
      setLoading(false);
    }
  }, [queryInstitution.loading]);

  function complementAddressByCep(cep: string) {
    ViaCep.get(`/${cep.replace('-', '')}/json`)
      .then(resp => {
        setInstitution({
          ...institution,
          address: {
            ...institution?.address,
            cep: resp.data.cep,
            uf: resp.data.uf,
            street: resp.data.logradouro,
            city: resp.data.localidade,
            neighborhood: resp.data.bairro,
          },
        });

        editForm.setFieldsValue({
          uf: resp.data.uf,
          rua: resp.data.logradouro,
          cidade: resp.data.localidade,
          bairro: resp.data.bairro,
        });
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }

  function update() {
    updateInstitution({
      variables: {
        institutionInput: {
          ...institution,
          profile: {
            ...institution?.profile,
            image: undefined,
          },
        },
      },
      refetchQueries: [
        { query: INSTITUTION_BY_ID, variables: { id: auth.id } },
      ],
    });
  }

  return (
    <Container>
      <Spin spinning={loading} fullscreen indicator={<Loading />} />
      <EditForm layout="vertical" form={editForm} onFinish={update}>
        <AvatarEditor
          token={auth.token}
          profile={auth.id}
          folder={auth.profile}
          source={institution?.profile?.image}
        />
        <Line plain orientation="left">
          INFORMAÇÕES GERAIS
        </Line>
        <Inputs>
          <FormItem label="INEP:" name="inep">
            <Input placeholder="Digite o INEP da Instituição" disabled />
          </FormItem>
          <FormItem
            label="Nome:"
            name="nome"
            rules={[
              {
                required: true,
                message: 'Por favor, informe o NOME',
              },
            ]}
          >
            <Input
              placeholder="Digite o Nome da Instituição"
              value={institution?.profile?.name}
              onChange={e => {
                setInstitution({
                  ...institution,
                  profile: { ...institution?.profile, name: e.target.value },
                });
                editForm.setFieldValue('nome', e.target.value);
              }}
            />
          </FormItem>
          <FormItem
            label="Categoria:"
            name="categoria"
            rules={[
              {
                required: true,
                message: 'Por favor, selecione a CATEGORIA',
              },
            ]}
          >
            <Select
              placeholder="Selecione a sua Categoria"
              value={institution?.category}
              onChange={value => {
                setInstitution({ ...institution, category: value });
                editForm.setFieldValue('categoria', value);
              }}
              options={[
                {
                  label: 'Púbilica',
                  value: 'PUBLIC',
                },
                {
                  label: 'Privada',
                  value: 'PRIVATE',
                },
              ]}
            />
          </FormItem>
        </Inputs>
        <Inputs>
          <FormItem
            label="Chave:"
            name="chave"
            rules={[
              {
                message: 'Por favor, informe a CHAVE',
              },
            ]}
          >
            <Input.Password
              placeholder="Digite a sua Chave de acesso"
              onChange={e => {
                editForm.setFieldValue('chave', e.target.value);
                setInstitution({
                  ...institution,
                  profile: {
                    ...institution?.profile,
                    password: e.target.value,
                  },
                });

                if (e.target.value.length <= 0) {
                  setEnableInput(true);
                  setEnableButton(false);
                  setInstitution({
                    ...institution,
                    profile: {
                      ...institution?.profile,
                      password: undefined,
                    },
                  });
                } else if (
                  e.target.value.length >= 0 &&
                  e.target.value.length <= 7
                ) {
                  setEnableInput(true);
                  setEnableButton(true);
                } else if (e.target.value.length >= 8) {
                  setEnableInput(false);
                }
              }}
            />
          </FormItem>
          <FormItem
            label="Repita a Chave:"
            name="repitachave"
            rules={[
              {
                required: !enableInput,
                message: 'Por favor, repita a CHAVE',
              },
            ]}
          >
            <Input.Password
              disabled={enableInput}
              placeholder="Digite novamente sua Chave de acesso"
              onChange={e => {
                if (
                  institution?.profile?.password !== undefined &&
                  institution?.profile?.password?.length ===
                    e.target.value.length &&
                  institution?.profile?.password === e.target.value
                ) {
                  setEnableButton(false);
                } else {
                  if (
                    institution?.profile?.password !== undefined &&
                    e.target.value.length <
                      institution?.profile?.password?.length
                  ) {
                    setEnableButton(true);
                  }
                }
              }}
            />
          </FormItem>
          <HiddenItem label="hidden">
            <Input />
          </HiddenItem>
        </Inputs>
        <Line plain orientation="left">
          CONTATO
        </Line>
        <Inputs>
          <FormItem
            label="E-mail:"
            name="email"
            rules={[
              {
                required: true,
                message: 'Por favor, informe o EMAIL',
              },
            ]}
          >
            <Input
              placeholder="Digite o seu E-mail"
              value={institution?.profile?.email}
              onChange={e => {
                setInstitution({
                  ...institution,
                  profile: { ...institution?.profile, email: e.target.value },
                });
                editForm.setFieldValue('email', e.target.value);
              }}
            />
          </FormItem>
          <FormItem
            label="Telefone:"
            name="telefone"
            rules={[
              {
                required: true,
                message: 'Por favor, informe o TELEFONE',
              },
            ]}
          >
            <Input
              placeholder="Digite o seu Telefone"
              value={institution?.telephone}
              addonBefore={
                <Select
                  defaultValue={'Celular'}
                  value={typePhone}
                  onChange={value => {
                    setTypePhone(value);
                  }}
                >
                  <Select.Option value="1">Celular</Select.Option>
                  <Select.Option value="2">Fixo</Select.Option>
                </Select>
              }
              onChange={e => {
                editForm.setFieldValue(
                  'telefone',
                  typePhone === '1'
                    ? maskCellPhone(e.target.value)
                    : maskLandline(e.target.value),
                );
                setInstitution({
                  ...institution,
                  telephone:
                    typePhone === '1'
                      ? maskCellPhone(e.target.value)
                      : maskLandline(e.target.value),
                });
              }}
            />
          </FormItem>
          <HiddenItem label="hidden">
            <Input />
          </HiddenItem>
        </Inputs>
        <Line plain orientation="left">
          LOCALIZAÇÃO
        </Line>
        <Inputs>
          <FormItem
            label="CEP:"
            name="cep"
            rules={[
              {
                required: true,
                message: 'Por favor, informe o CEP',
              },
            ]}
          >
            <Input
              placeholder="Digite o seu CEP"
              value={institution?.address?.cep}
              onChange={e => {
                setInstitution({
                  ...institution,
                  address: {
                    ...institution?.address,
                    cep: maskCep(e.target.value),
                  },
                });
                editForm.setFieldValue('cep', maskCep(e.target.value));
                complementAddressByCep(maskCep(e.target.value));
              }}
            />
          </FormItem>
          <FormItem
            label="Cidade:"
            name="cidade"
            rules={[
              {
                required: true,
                message: 'Por favor, informe a CIDADE',
              },
            ]}
          >
            <Input
              placeholder="Digite a sua Cidade"
              value={institution?.address?.city}
              onChange={e => {
                setInstitution({
                  ...institution,
                  address: { ...institution?.address, city: e.target.value },
                });
                editForm.setFieldValue('cidade', e.target.value);
              }}
            />
          </FormItem>
          <FormItem
            label="Zona:"
            name="zona"
            rules={[
              {
                required: true,
                message: 'Por favor, selecione a ZONA',
              },
            ]}
          >
            <Select
              placeholder="Selecione a Zona"
              value={institution?.address?.zone}
              onChange={value => {
                setInstitution({
                  ...institution,
                  address: { ...institution?.address, zone: value },
                });
                editForm.setFieldValue('zona', value);
              }}
              options={[
                {
                  label: 'Urbana',
                  value: 'URBAN',
                },
                {
                  label: 'Rural',
                  value: 'RURAL',
                },
              ]}
            />
          </FormItem>
        </Inputs>
        <Inputs>
          <FormItem
            label="Bairro:"
            name="bairro"
            rules={[
              {
                required: true,
                message: 'Por favor, informe o BAIRRO',
              },
            ]}
          >
            <Input
              placeholder="Digite o seu Bairro"
              value={institution?.address?.neighborhood}
              onChange={e => {
                setInstitution({
                  ...institution,
                  address: {
                    ...institution?.address,
                    neighborhood: e.target.value,
                  },
                });
                editForm.setFieldValue('bairro', e.target.value);
              }}
            />
          </FormItem>
          <FormItem
            label="Rua:"
            name="rua"
            rules={[
              {
                required: true,
                message: 'Por favor, informe a RUA',
              },
            ]}
          >
            <Input
              placeholder="Digite a sua Rua"
              value={institution?.address?.street}
              onChange={e => {
                setInstitution({
                  ...institution,
                  address: {
                    ...institution?.address,
                    street: e.target.value,
                  },
                });
                editForm.setFieldValue('rua', e.target.value);
              }}
            />
          </FormItem>
          <FormItem
            label="Número:"
            name="numero"
            rules={[
              {
                required: true,
                message: 'Por favor, informe o NÚMERO',
              },
            ]}
          >
            <InputNumbers
              placeholder="Digite o Número da Instituição"
              value={institution?.address?.numbering}
              onChange={value => {
                setInstitution({
                  ...institution,
                  address: {
                    ...institution?.address,
                    numbering: Number(value),
                  },
                });
                editForm.setFieldValue('numero', value);
              }}
            />
          </FormItem>
        </Inputs>
        <FormItem>
          <Button
            disabled={enableButton}
            type="primary"
            htmlType="submit"
            onClick={() => {
              setLoading(true);
            }}
          >
            Salvar alterações
          </Button>
        </FormItem>
      </EditForm>
    </Container>
  );
};

export default InstitutionRegisterData;
