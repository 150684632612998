import styled from 'styled-components';
import { Form, Col, Button, InputNumber, Row } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const Container = styled(Row)`
  overflow-y: auto;
  height: 100vh;
`;

const Loading = styled(LoadingOutlined)`
  font-size: 64px !important;
  color: #000;
`;

const Div1 = styled(Col)`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const RegisterContent = styled.div`
  margin-left: 20%;
  margin-right: 20%;
  margin-top: 5%;
  margin-bottom: 5%;
`;

const RegisterImage = styled.img`
  width: 100%;
`;

const RegisterTitle = styled.p`
  font-size: 2em;
  font-weight: 600;
  text-align: center;
`;

const RegisterText = styled.p`
  color: #7c7c7c;
  font-size: 1.2em;
  text-align: center;
`;

const Div2 = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const RegisterForm = styled(Form)`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  .ant-form-item {
    margin-bottom: 2%;
    width: 45%;
    @media (max-width: 1500px) {
      margin-bottom: 1%;
    }
  }
`;

const Inputs = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 80%;
`;

const FormItem = styled(Form.Item)`
  .ant-form-item-control-input-content {
    display: flex;
    justify-content: center;
  }
  .ant-input {
    width: 100%;
    @media (max-width: 1500px) {
      height: 30px;
    }
  }
  .ant-select-selector {
    background-color: blue;
    width: 100%;
    @media (max-width: 1500px) {
      height: 30px !important;
    }
  }
  .ant-form-item-label {
    padding: 0;
    font-weight: 600;
  }
  .ant-btn {
    margin-top: 2%;
    background-color: #33c455 !important;
    width: 70%;
  }
`;

const InputNumbers = styled(InputNumber)`
  width: 100%;
`;

const BackToLogin = styled.div`
  display: flex;
  flex-direction: row;
`;

const RegisterQuestionAccount = styled.p`
  font-size: 14px;
`;

const LogoIescolar = styled.img`
  margin-bottom: 1%;
  margin-top: 1%;
`;

const ButtonGeolocation = styled(Button)`
  background-color: #faad14 !important;
`;

const ButtonSchoolNotFound = styled(Button)`
  background-color: #ff4d4f !important;
`;

export {
  Container,
  Loading,
  Div1,
  RegisterContent,
  RegisterImage,
  RegisterTitle,
  RegisterText,
  Div2,
  RegisterForm,
  Inputs,
  FormItem,
  InputNumbers,
  BackToLogin,
  RegisterQuestionAccount,
  LogoIescolar,
  ButtonGeolocation,
  ButtonSchoolNotFound,
};
