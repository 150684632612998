import React from 'react';
import { Select } from 'antd';
import { YearFilterI } from '../../utils/types';

const YearFilter: React.FC<YearFilterI> = ({ options, onChoice }) => {
  return (
    <Select
      defaultValue={`${
        options[0] !== undefined ? options[0] : new Date().getFullYear()
      }`}
      options={options.map(year => {
        return { label: year, value: year };
      })}
      onSelect={(value: string) => {
        onChoice(value);
      }}
    />
  );
};

export default YearFilter;
