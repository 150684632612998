import React from 'react';
import IconCONFIRM from '../../../assets/img/confirm.svg';
import { Container, Title, Text, ContainerButton, LoginButton } from './styles';

const RegisterConfirm: React.FC = () => {
  return (
    <Container>
      <img alt="" src={IconCONFIRM} />
      <Title>Parabéns! Seu cadastro foi concluído com sucesso.</Title>
      <Text>
        <p>
          Agora você faz parte da nossa comunidade de segurança escolar. Nossa
          missão é garantir que todos os alunos estejam protegidos e se sintam
          seguros em seu ambiente escolar.
        </p>
      </Text>
      <ContainerButton>
        <LoginButton type="primary" href="/" size="large">
          Realizar login
        </LoginButton>
      </ContainerButton>
    </Container>
  );
};

export default RegisterConfirm;
