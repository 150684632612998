import React from 'react';
import {
  Address,
  GroupAvatarColor,
  Marker,
  P,
  PanicModal,
  UserAvatar,
} from './styles';
import { AlertI } from '../../utils/types';
import Lottie from 'react-lottie';
import PanicGIF from '../../assets/file/panic-animation.json';
import { Avatar, Popover } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import useSound from 'use-sound';

const PanicAlert: React.FC<AlertI> = ({ activate, disable, occurrence }) => {
  const [play, { stop }] = useSound(
    'https://storage.googleapis.com/iescolar-static-01/default/alarm-sound.mp3',
    { loop: true },
  );

  if (activate) {
    play();
  } else {
    stop();
  }

  return (
    <PanicModal
      title="PÂNICO ACIONADO"
      open={activate}
      onCancel={() => {
        disable(`${occurrence?._id}`);
      }}
      width={700}
      footer={null}
      centered
    >
      <Lottie
        width={'80%'}
        options={{
          loop: true,
          autoplay: true,
          animationData: PanicGIF,
        }}
        isClickToPauseDisabled={true}
      />

      <P>ACIONADO POR: </P>
      <Avatar.Group
        maxCount={5}
        maxStyle={{
          cursor: 'pointer',
          backgroundColor: GroupAvatarColor.backgroundColor,
        }}
        maxPopoverTrigger="focus"
      >
        {occurrence?.triggers.map((trigger, index) => {
          return (
            <Popover
              key={index}
              content={
                <div>
                  Nome: {trigger.user.name}{' '}
                  {trigger.user.responsible !== undefined ? (
                    <>
                      <br /> Responsável: {trigger.user.responsible?.name} -
                      {trigger.user.responsible?.telephone}
                    </>
                  ) : (
                    <br />
                  )}
                </div>
              }
              placement="top"
            >
              <UserAvatar
                size={29}
                icon={
                  trigger.user.image === null ||
                  trigger.user.image === undefined ? (
                    <UserOutlined />
                  ) : (
                    <img src={trigger.user.image} />
                  )
                }
              />
            </Popover>
          );
        })}
      </Avatar.Group>
      <Address>
        <p>
          <Marker />
          {`${occurrence?.institution.address?.street}, nº ${occurrence?.institution.address?.numbering}  |  ${occurrence?.institution.address?.neighborhood}, ${occurrence?.institution.address?.city}-${occurrence?.institution.address?.uf}`}
        </p>
      </Address>
    </PanicModal>
  );
};

export default PanicAlert;
