import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const Title = styled.p`
  font-size: 20;
  font-weight: 600;
`;

const SwitchContainer = styled.div`
  margin: 4%;
`;

const Description = styled.div`
  width: 40%;
  @media (max-width: 1500px) {
    width: 60%;
  }
  p {
    text-align: center;
  }
`;

export { Container, Title, SwitchContainer, Description };
