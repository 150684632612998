import styled from 'styled-components';
import { Layout, Button, Avatar } from 'antd';
import { MdOutlineSpaceDashboard } from 'react-icons/md';
import { BsShieldCheck, BsShieldExclamation } from 'react-icons/bs';
import { PiSuitcaseBold, PiNotebookLight } from 'react-icons/pi';
import { LiaFolderMinusSolid } from 'react-icons/lia';
import { FaRegMap } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const Container = styled(Layout)`
  background-color: #ffff;
  height: 100vh;
`;

const LateralSider = styled(Layout.Sider)`
  display: flex;
  justify-content: flex-start;
  min-width: 270px !important;
  .ant-layout-sider-children {
    margin-left: 1em;
  }
  .ant-menu-item.ant-menu-item-selected {
    background-color: #ff0000;
  }
`;

const ContainerButton = styled.div`
  display: flex;
  height: 10%;
`;

const ExitButton = styled(Button)`
  color: #ffff !important;
  display: flex;
  align-items: center;
`;

const Space = styled.div`
  height: 25px;
`;

const LayoutHeader = styled(Layout.Header)`
  padding: 0;
  background: #ffff;
  height: auto;
`;

const LayoutContent = styled(Layout.Content)`
  overflow-y: auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background: #ffff;
  height: 100%;
`;

const Div1 = styled.div`
  display: flex;
  align-items: center;
  height: 20%;
`;

const PrifileImage = styled(Avatar)``;

const Div2 = styled.div`
  height: 70%;
`;

const Label = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
`;

const LabelLink = styled(Link)`
  font-size: 16px;
  margin-left: 4px;
`;

const DashboardIcon = styled(MdOutlineSpaceDashboard)`
  font-size: 18px;
`;

const OcurrencesIcon = styled(BsShieldExclamation)`
  font-size: 17px;
`;

const AssistanceIcon = styled(PiSuitcaseBold)`
  font-size: 18px;
`;

const ValidationsIcon = styled(BsShieldCheck)`
  font-size: 17px;
`;

const RulesIcon = styled(PiNotebookLight)`
  font-size: 18px;
`;

const RegisterDataIcon = styled(LiaFolderMinusSolid)`
  font-size: 18px;
`;
const MapIcon = styled(FaRegMap)`
  font-size: 17px;
`;

const IconColor = {
  ok: {
    color: '#33c455',
  },
  cancel: {
    color: '#c53f3f',
  },
};

const ButtonColor = {
  ok: {
    background: '#33c455',
  },
  cancel: {
    background: '#c53f3f',
  },
};

export {
  Container,
  LateralSider,
  ContainerButton,
  ExitButton,
  Space,
  LayoutHeader,
  LayoutContent,
  Div1,
  PrifileImage,
  Div2,
  Label,
  LabelLink,
  DashboardIcon,
  OcurrencesIcon,
  AssistanceIcon,
  ValidationsIcon,
  RulesIcon,
  RegisterDataIcon,
  MapIcon,
  IconColor,
  ButtonColor,
};
