import { Avatar, Button } from 'antd';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 10%;
  margin-top: 10%;
  scale: 1;
  transition: 0.1s;
  &:hover {
    scale: 1.1;
  }
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-style: solid;
  border-radius: 10px;
  border-color: #a7a7a7;
  border-width: 1px;
  padding: 15px;
  width: 80%;
  height: 100%;
`;

const TitleContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5%;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 80%;
`;

const UserAvatarContainer = styled.div`
  width: 20%;
`;

const UserAvatar = styled(Avatar)`
  min-width: 18%;
`;

const NameContainer = styled.div`
  width: 77%;
`;

const Name = styled.p`
  font-size: 20px;
  font-weight: 600;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const State = styled.p`
  font-size: 20px;
  font-weight: 600;
`;

const Infos = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 7%;
`;

const Tel = styled.p`
  font-size: 16px;
  font-weight: 600;
`;

const Number = styled.p`
  font-size: 16px;
  margin-left: 2%;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const EditButton = styled(Button)`
  margin-right: 3%;
  background-color: #ff9900 !important;
`;

const DeleteButton = styled(Button)`
  margin-left: 3%;
  background-color: #c53f3f !important;
`;

const IconColor = {
  cancel: {
    color: '#c53f3f',
  },
};

const ButtonColor = {
  cancel: {
    background: '#c53f3f',
  },
};

export {
  Container,
  Card,
  TitleContent,
  Header,
  UserAvatarContainer,
  UserAvatar,
  NameContainer,
  Name,
  State,
  Infos,
  Tel,
  Number,
  Buttons,
  EditButton,
  DeleteButton,
  IconColor,
  ButtonColor,
};
