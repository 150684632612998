import styled from 'styled-components';
import { LoadingOutlined } from '@ant-design/icons';

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
`;

const Loading = styled(LoadingOutlined)`
  font-size: 64px !important;
  color: #000;
`;

const Cards = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
  height: 20%;
`;

const Frame = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  top: 0;
  left: 0;
  height: 80%;
`;

const FrameImage = styled.img`
  height: 90%;
  width: 90%;
`;

const Warnings = styled.div`
  position: absolute;
  top: 0;
  padding: 30px;
  width: 90%;
  height: 100%;
`;

const Div1 = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20%;
`;

const Div2 = styled.div`
  height: 55%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Div3 = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 25%;
`;

export {
  Container,
  Loading,
  Cards,
  Frame,
  FrameImage,
  Warnings,
  Div1,
  Div2,
  Div3,
};
