import styled from 'styled-components';
import { Avatar, Layout, Modal } from 'antd';
import { MdOutlineSpaceDashboard } from 'react-icons/md';
import { BiLocationPlus } from 'react-icons/bi';
import { TbShieldLock } from 'react-icons/tb';
import { PiNotepadBold } from 'react-icons/pi';
import { IoSettingsOutline } from 'react-icons/io5';
import { IoMdExit } from 'react-icons/io';
import { Link } from 'react-router-dom';

const Container = styled(Layout)`
  height: 100vh;
`;

const LayoutHeader = styled(Layout.Header)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
  padding-inline: 90px !important;
`;

const RoutesHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 70%;
  justify-content: flex-end;
`;

const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const UserAvatar = styled(Avatar)`
  cursor: pointer;
  margin-left: 3%;
  background-color: #bfbfbf;
`;

const LayoutContent = styled(Layout.Content)`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffff;
  height: auto;
  overflow-x: hidden;
`;

const ViewModal = styled(Modal)`
  top: 5%;
`;

const ContainerButton = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 2%;
`;

const Label = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
`;

const DashboardIcon = styled(MdOutlineSpaceDashboard)`
  font-size: 18px;
`;

const ReisterUFIcon = styled(BiLocationPlus)`
  font-size: 18px;
`;

const SecurityIcon = styled(TbShieldLock)`
  font-size: 18px;
`;

const ReportsIcon = styled(PiNotepadBold)`
  font-size: 18px;
`;

const LabelLink = styled(Link)`
  font-size: 16px;
  margin-left: 4px;
  @media (max-width: 1500px) {
    font-size: 14px;
  }
`;

const EditIcon = styled(IoSettingsOutline)`
  margin: 4px;
  font-size: 16px;
`;

const ExitIcon = styled(IoMdExit)`
  margin: 4px;
  font-size: 16px;
`;

const ProfileOptions = styled.a`
  line-height: 1;
  display: flex;
  align-items: center;
  font-size: 14px;
`;

export {
  Container,
  LayoutHeader,
  RoutesHeader,
  UserAvatar,
  LayoutContent,
  LogoContainer,
  ViewModal,
  ContainerButton,
  Label,
  DashboardIcon,
  ReisterUFIcon,
  SecurityIcon,
  ReportsIcon,
  EditIcon,
  ExitIcon,
  LabelLink,
  ProfileOptions,
};
