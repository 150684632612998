import React from 'react';
import ReportCard from '../../../../components/ReportCard';
import { Container, Categories, CategoriesContent } from './styles';
import { CollapseProps } from 'antd';

const Reports: React.FC = () => {
  const items: CollapseProps['items'] = [
    {
      key: '1',
      label: 'LOGS',
      children: (
        <CategoriesContent>
          <ReportCard
            title="ACESSO"
            icon={require('../../../../assets/img/changes-image.png')}
          />
          <ReportCard
            title="PÂNICO"
            icon={require('../../../../assets/img/panic-image.png')}
          />
          <ReportCard
            title="EMERGÊNCIA"
            icon={require('../../../../assets/img/emergency-image.png')}
          />
          <ReportCard
            title="ALTERAÇÃO"
            icon={require('../../../../assets/img/access-image.png')}
          />
        </CategoriesContent>
      ),
    },
    {
      key: '2',
      label: 'INSTITUIÇÕES',
      children: '',
    },
  ];

  return (
    <Container>
      <Categories
        items={items}
        bordered={false}
        accordion
        defaultActiveKey={['1']}
      />
    </Container>
  );
};

export default Reports;
