import React, { useEffect, useState } from 'react';
import { useMap } from 'react-leaflet';
import { MapI } from '../../utils/types';
import * as L from 'leaflet';

// eslint-disable-next-line
export class MapView {
  private static MAP: L.Map;
  static getMap(): L.Map {
    if (MapView.MAP) return MapView.MAP;
    MapView.MAP = useMap(); // eslint-disable-line
    return MapView.MAP;
  }
}

const MapController: React.FC<MapI> = ({ coordinates }) => {
  const map = MapView.getMap();
  useEffect(() => {
    map.setView(coordinates, map.getZoom());
  }, [coordinates]);

  return null;
};

export default MapController;
