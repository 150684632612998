import styled from 'styled-components';
import { Button } from 'antd';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid;
  border-width: 0.5px;
  border-radius: 5px;
  border-color: #b0b0b0;
  margin: 20px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 15px;
  padding-bottom: 15px;
`;

const Title = styled.p`
  font-weight: 600;
  margin-bottom: 15px;
`;

const ReportButton = styled(Button)`
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 15px;
  font-size: 12;
  background-color: #33c455 !important;
`;

export { Container, Content, Title, ReportButton };
