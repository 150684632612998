import styled from 'styled-components';
import { Button, DatePicker, Form, Modal } from 'antd';
import { AlertFilled, WarningFilled } from '@ant-design/icons';

const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
`;

const Col1 = styled.div`
  width: 60%;
  display: flex;
  flex-direction: row;
`;

const Col2 = styled.div`
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
  margin-bottom: 2%;
`;

const ListContainer = styled.div`
  width: 100%;
  height: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Filter = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

const List = styled.div`
  width: 80%;
`;

const PrintModal = styled(Modal)`
  .ant-modal-title {
    display: flex;
    justify-content: center;
  }
`;

const RangePicker = styled(DatePicker.RangePicker)`
  width: 100%;
`;

const PrintButtonContainer = styled(Form.Item)`
  display: flex;
  justify-content: center;
  margin: 0;
`;

const PrintButton = styled(Button)`
  background-color: #33c455 !important;
`;

const FloatEmergency = styled(WarningFilled)`
  color: #ffe100;
`;

const FloatPanic = styled(AlertFilled)`
  color: #ff0d00;
`;

const ListHeader = styled.div`
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 7%;
  padding-left: 5%;
`;

const ShowAllButton = styled.button`
  white-space: nowrap;
  border: none;
  border-radius: 6px;
  background-color: #00b2ff;
  color: #ffffff;
  font-size: 15px;
  padding: 1% 8%;
  cursor: pointer;
`;

const GoBackButton = styled.button`
  width: 15%;
  height: fit-content;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: none;
  border: none;
  font-size: 15px;
  white-space: nowrap;
  cursor: pointer;

  &:hover {
    border-bottom: 2px solid black;
  }
`;

export {
  Container,
  Col1,
  Col2,
  ListContainer,
  Filter,
  List,
  PrintModal,
  RangePicker,
  PrintButtonContainer,
  PrintButton,
  FloatEmergency,
  FloatPanic,
  ListHeader,
  ShowAllButton,
  GoBackButton,
};
