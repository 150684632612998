import styled from 'styled-components';
import { Form, Button, Alert } from 'antd';

const Container = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Title = styled.p`
  margin-top: 2%;
  margin-bottom: 2%;
  font-size: 24px;
  font-weight: 600;
`;

const Text = styled.p`
  margin-bottom: 2%;
  width: 533px;
  text-align: center;
  color: #5a5a5a;
`;

const Field = styled(Form)`
  width: 20%;
`;

const FormItem = styled(Form.Item)`
  text-align: center;
`;

const RegisterButton = styled(Button)`
  background-color: #ffc107 !important;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ErrorAlert = styled(Alert)`
  margin-bottom: 5%;
  background-color: #fac3c3;
`;

export { Container, Title, Text, Field, FormItem, RegisterButton, ErrorAlert };
