import React from 'react';
import { Container, ImageSchool, Hello, Title, Text } from './styles';
import { UserOutlined } from '@ant-design/icons';
import { ManagerCardI } from '../../utils/types';

const ManagerCard: React.FC<ManagerCardI> = ({ image }) => {
  return (
    <Container>
      <ImageSchool
        shape="square"
        size={45}
        src={image === null || image === undefined ? <UserOutlined /> : image}
        draggable={false}
      />
      <Hello>
        <Text>Olá! 👋</Text>
        <Title>Instituição</Title>
      </Hello>
    </Container>
  );
};

export default ManagerCard;
