import React from 'react';
import { Tooltip, Popconfirm, Avatar } from 'antd';
import {
  Card,
  Container,
  Header,
  UserAvatarContainer,
  UserAvatar,
  Infos,
  NameContainer,
  Name,
  State,
  Tel,
  TitleContent,
  Number,
  Buttons,
  EditButton,
  DeleteButton,
  IconColor,
  ButtonColor,
} from './styles';
import { SecurityServiceI } from '../../utils/types';
import { ExclamationCircleOutlined, UserOutlined } from '@ant-design/icons';

const SecurityService: React.FC<SecurityServiceI> = ({
  id,
  title,
  number,
  image,
  state,
  onEdit,
  onDelete,
}) => {
  function edit() {
    onEdit?.(id);
  }

  function deleteService() {
    onDelete?.(id);
  }

  return (
    <Container>
      <Card>
        <TitleContent>
          <Header>
            <UserAvatarContainer>
              <UserAvatar
                icon={image === null ? <UserOutlined /> : <img src={image} />}
                alt=""
              />
            </UserAvatarContainer>
            <NameContainer>
              <Tooltip title={title} arrow={false}>
                <Name>{title}</Name>
              </Tooltip>
            </NameContainer>
          </Header>
          <State>{state}</State>
        </TitleContent>
        <Infos>
          <Tel>Tel:</Tel>
          <Number>{number}</Number>
        </Infos>
        <Buttons>
          <EditButton type="primary" size="small" onClick={edit}>
            Editar
          </EditButton>
          <Popconfirm
            title="Remover Serviço?"
            onConfirm={deleteService}
            okText="Sim"
            cancelText="Não"
            icon={<ExclamationCircleOutlined style={IconColor.cancel} />}
            okButtonProps={{ style: ButtonColor.cancel }}
          >
            <DeleteButton type="primary" size="small">
              Remover
            </DeleteButton>
          </Popconfirm>
        </Buttons>
      </Card>
    </Container>
  );
};

export default SecurityService;
