import React, { useEffect } from 'react';
import { PdfOccurrenceI } from '../../utils/types';
import { View, Text } from '@react-pdf/renderer';
import { Styles } from './styles';
import OcurrencePerson from '../OcurrencePerson';
import { OccurrenceE } from '../../utils/entities';
import { base64ToObject } from '../../utils/converters';
import { getStorage } from '../../utils/storage';
import OcurrenceTriggerTable from '../OcurrenceTriggerTable';

const PdfOccurrence: React.FC<PdfOccurrenceI> = ({
  title,
  address,
  date,
  description,
  hour,
  type,
  triggers,
  institution,
  telephone,
  triggerBy,
}) => {
  return (
    <View style={Styles.containerOcurrence}>
      <View style={Styles.subtitle}>
        <Text style={Styles.titleFont}>{title}</Text>
      </View>
      <View style={Styles.infosContainer}>
        <View style={Styles.infosCol}>
          <View style={Styles.infosRow}>
            <Text style={Styles.titleFont}>Instituição: </Text>
            <Text>{institution}</Text>
          </View>
          <View style={Styles.infosRow}>
            <Text style={Styles.titleFont}>Endereço: </Text>
            <Text>{address}</Text>
          </View>
          <View style={Styles.infosRow}>
            <Text style={Styles.titleFont}>Acionado por: </Text>
            <Text>{triggerBy}</Text>
          </View>
        </View>
        <View style={Styles.infosCol}>
          <View style={Styles.infosRow}>
            <Text style={Styles.titleFont}>Contato: </Text>
            <Text>{telephone}</Text>
          </View>
          <View style={Styles.infosRow}>
            <Text style={Styles.titleFont}>Data: </Text>
            <Text>{date}</Text>
          </View>
          <View style={Styles.infosRow}>
            <Text style={Styles.titleFont}>Hora: </Text>
            <Text>{hour}</Text>
          </View>
        </View>
      </View>
      <View style={Styles.description}>
        <Text>
          {description === null
            ? 'Aguarde a Instituição descrever a ocorrência...'
            : description}
        </Text>
      </View>
      {type === 'PANIC' ? (
        <OcurrenceTriggerTable triggers={triggers} />
      ) : (
        <View />
      )}
    </View>
  );
};

export default PdfOccurrence;
