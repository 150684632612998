import styled from 'styled-components';
import { Button, Form, Input, Modal } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
`;

const Loading = styled(LoadingOutlined)`
  font-size: 64px !important;
  color: #000;
`;

const Cards = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
  height: 20%;
`;

const Frame = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  top: 0;
  left: 0;
  height: 80%;
`;

const FrameImage = styled.img`
  height: 90%;
  width: 80%;
  @media (max-width: 1500px) {
    width: 100%;
  }
`;

const Warnings = styled.div`
  position: absolute;
  top: 0;
  padding: 30px;
  width: 80%;
  height: 100%;
  @media (max-width: 1500px) {
    width: 100%;
  }
`;

const Div1 = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20%;
`;

const Div2 = styled.div`
  height: 55%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Div3 = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 25%;
`;

const ModalButton = styled(Button)`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #627a60 !important;
`;

const ViewModal = styled(Modal)`
  .ant-modal-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const ModalForm = styled(Form)`
  display: flex;
  flex-direction: column;
  margin-top: 5%;
`;

const FormItem = styled(Form.Item)`
  .ant-form-item-label {
    font-weight: 600;
  }
`;

const FormInput = styled(Input)`
  width: 400px;
`;

const ContainerButton = styled(Form.Item)`
  display: flex;
  justify-content: center;
  margin: 0;
`;

const SaveButton = styled(Button)`
  background-color: #33c455 !important;
  width: 200px;
`;

export {
  Container,
  Loading,
  Cards,
  Frame,
  FrameImage,
  Warnings,
  Div1,
  Div2,
  Div3,
  ModalButton,
  ViewModal,
  ModalForm,
  FormItem,
  FormInput,
  ContainerButton,
  SaveButton,
};
