import { initializeApp } from 'firebase/app';
import {
  getDatabase,
  query,
  ref,
  onValue,
  limitToLast,
} from 'firebase/database';
import Key from '../assets/file/key.json';
import { base64ToObject } from '../utils/converters';

const firebaseConfig = initializeApp(base64ToObject(Key.value));
const connection = getDatabase(firebaseConfig);

export { connection, query, ref, onValue, limitToLast };
