import { gql } from '@apollo/client';

export const SECURITY_SERVICE_BY_PROFILE = gql`
  query GetSecurityBodyByProfileId($id: MongoID!) {
    getSecurityBodyByProfileId(id: $id) {
      profile {
        image
      }
      address {
        localization {
          coordinates
        }
      }
    }
  }
`;

export const ALL_OCCURRENCES = gql`
  query GetOccurrenceAllBySecurityBodyYear($id: MongoID!, $year: Int!) {
    getOccurrenceAllBySecurityBodyYear(id: $id, year: $year) {
      _id
      type
      description
      level
      institution {
        profile {
          _id
          name
        }
        address {
          street
          neighborhood
          numbering
          localization {
            coordinates
          }
        }
      }
      triggers {
        user {
          name
          type
          image
          responsible {
            name
            telephone
          }
        }
        actuation
      }
    }
  }
`;

export const OCCURRENCE_BY_SECURITY_BODY = gql`
  query GetOccurrenceBySecurityBody($body: MongoID, $occuID: MongoID) {
    getOccurrenceBySecurityBody(body: $body, occurrence: $occuID) {
      _id
      date
      type
      level
      institution {
        profile {
          _id
          name
        }
        address {
          cep
          uf
          city
          neighborhood
          street
          numbering
        }
      }
      triggers {
        actuation
        user {
          name
          cpf
          image
        }
      }
    }
  }
`;

export const ALL_OCCURRENCES_BY_DATE_AND_TYPE = gql`
  query GetOccurrenceAllBySecurityBodyTypeDate(
    $body: MongoID!
    $type: String
    $level: String
    $dateStart: DateTime
    $dateEnd: DateTime
  ) {
    getOccurrenceAllBySecurityBodyTypeDate(
      body: $body
      type: $type
      level: $level
      dateStart: $dateStart
      dateEnd: $dateEnd
    ) {
      date
      type
      level
      institution {
        profile {
          name
        }
        address {
          uf
          city
          street
          neighborhood
          numbering
        }
        telephone
      }
      triggers {
        actuation
        user {
          name
          cpf
        }
      }
      description
    }
  }
`;
