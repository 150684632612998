import React from 'react';
import { View, Text } from '@react-pdf/renderer';
import { Styles } from './styles';
import { OcurrencePersonI } from '../../utils/types';

const OcurrencePerson: React.FC<OcurrencePersonI> = ({
  name,
  cpf,
  type,
  responsible,
  responsibleNumber,
  hour,
  index,
}) => {
  return (
    <View style={Styles.container}>
      <View style={{ width: 20 }}>
        <Text>{index}</Text>
      </View>
      <View style={Styles.infoCol}>
        <Text style={{ width: 125 }}>{name}</Text>
        <Text style={{ width: 85 }}>{cpf}</Text>
        <Text style={{ width: 85 }}>{type}</Text>
        <Text style={{ width: 125 }}>{responsible || '-'}</Text>
        <Text style={{ width: 100 }}>{responsibleNumber || '-'}</Text>
      </View>
      <View style={{ width: 50 }}>
        <Text>{hour}</Text>
      </View>
    </View>
  );
};

export default OcurrencePerson;
