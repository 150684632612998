import { getStorage, setStorage } from './storage';

function closeAlert(id: string): void {
  const closes: string[] = getStorage(`${process.env.REACT_APP_PANICS}`);
  closes.push(id);
  setStorage(`${process.env.REACT_APP_PANICS}`, closes);
}

function wasOpenAlert(id: string, type?: string): boolean {
  const closes: string[] = getStorage(`${process.env.REACT_APP_PANICS}`);
  const result = closes.indexOf(id) > -1;
  if (type !== undefined) return false;
  return result;
}

export { closeAlert, wasOpenAlert };
