import React, { useEffect, useState } from 'react';
import Card from '../../../../components/Card';
import {
  Container,
  Cards,
  Frame,
  FrameImage,
  Warnings,
  Div1,
  Div2,
  Div3,
  ViewModal,
  ModalForm,
  FormItem,
  ContainerButton,
  SaveButton,
  ModalButton,
  FormInput,
  Loading,
} from './styles';
import Warning from '../../../../components/Warning';
import { Input, Select, Spin } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import Legend from '../../../../assets/img/legend-frame.svg';
import WarningImage from '../../../../assets/img/warnings.svg';
import { useQuery, useMutation } from '@apollo/client';
import {
  ADD_NOTES,
  DASHADMIN,
  DELETE_NOTE,
  NOTES,
} from '../../../../utils/queries/adminQueries';
import { DashAdminE, NoteE } from '../../../../utils/entities';

const AdminDashboard: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [dashAdmin, setDashAdmin] = useState<DashAdminE>();
  const [form] = ModalForm.useForm();
  const [notes, setNotes] = useState<NoteE[]>([]);
  const queryNote = useQuery(NOTES);
  const queryDashData = useQuery(DASHADMIN);
  const [loading, setLoading] = useState(true);
  const [addNote, addNoteReturn] = useMutation(ADD_NOTES);
  const [note, setNote] = useState<NoteE>();
  const [deleteNote, deletNoteReturn] = useMutation(DELETE_NOTE);

  useEffect(() => {
    if (
      queryNote.loading ||
      queryDashData.loading ||
      addNoteReturn.loading ||
      deletNoteReturn.loading
    ) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [
    queryNote.loading,
    queryDashData.loading,
    addNoteReturn.loading,
    deletNoteReturn.loading,
  ]);

  useEffect(() => {
    setNotes(
      queryNote.data?.getNoteAll === undefined
        ? []
        : queryNote.data?.getNoteAll,
    );
    setDashAdmin(queryDashData.data?.getDataAdmin);
  }, [queryNote.data, queryDashData.data]);

  function saveNote() {
    addNote({
      variables: {
        noteInput: {
          title: note?.title,
          level: note?.level,
          content: note?.content,
        },
      },
      refetchQueries: [{ query: NOTES }],
    });
    setNote(undefined);
    setIsModalOpen(false);
  }

  function showModal() {
    setIsModalOpen(true);
  }

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <Container>
      <Spin spinning={loading} fullscreen indicator={<Loading />} />
      <Cards>
        <Card
          title="CLIENTES CADASTRADOS"
          cont={dashAdmin?.clients !== undefined ? dashAdmin.clients : 0}
          icon={require('../../../../assets/img/uf-image.png')}
        />
        <Card
          title="INSTITUIÇÕES"
          cont={
            dashAdmin?.institutions !== undefined ? dashAdmin.institutions : 0
          }
          icon={require('../../../../assets/img/school-image.png')}
        />
        <Card
          title="EMERGÊNCIAS ACIONADAS"
          cont={
            dashAdmin?.emergencies !== undefined ? dashAdmin.emergencies : 0
          }
          icon={require('../../../../assets/img/emergency-image.png')}
        />
        <Card
          title="PÂNICOS ACIONADOS"
          cont={dashAdmin?.panics !== undefined ? dashAdmin.panics : 0}
          icon={require('../../../../assets/img/panic-image.png')}
        />
      </Cards>
      <Frame>
        <FrameImage src={require('../../../../assets/img/frame.png')} alt="" />
        <Warnings>
          <Div1>
            <img src={WarningImage} alt="" width={'20%'} />
          </Div1>
          <Div2>
            {notes.map((note: NoteE) => (
              <Warning
                key={note._id}
                id={note._id}
                value={note.level?.toLowerCase()}
                title={note.title}
                text={note.content}
                type="admin"
                onDelete={(id: string) => {
                  deleteNote({
                    variables: {
                      idNote: id,
                    },
                    refetchQueries: [{ query: NOTES }],
                  });
                  setLoading(true);
                }}
              />
            ))}
          </Div2>
          <Div3>
            <img src={Legend} width={'9%'} alt="" />

            <ModalButton type="primary" onClick={showModal}>
              <PlusOutlined />
            </ModalButton>
          </Div3>
        </Warnings>
      </Frame>
      <ViewModal
        centered
        open={isModalOpen}
        onCancel={handleCancel}
        footer={null}
        width={500}
        title="Adicionar aviso"
        afterClose={() => form.resetFields()}
      >
        <ModalForm
          onFinish={() => {
            saveNote();
            setLoading(true);
          }}
          form={form}
          layout="vertical"
        >
          <FormItem
            label="Título:"
            name="titulo"
            rules={[{ required: true, message: 'Por favor, informe o TÍTULO' }]}
          >
            <FormInput
              placeholder="Digite o título do aviso"
              maxLength={20}
              value={note?.title}
              onChange={(e: any) => {
                form.setFieldValue('titulo', e.target.value);
                setNote({ ...note, title: e.target.value });
              }}
            />
          </FormItem>
          <FormItem
            label="Tipo:"
            name="tipo"
            rules={[{ required: true, message: 'Por favor, selecione o TIPO' }]}
          >
            <Select
              placeholder="Selecione a importância"
              value={note?.level}
              options={[
                {
                  label: 'Informativo',
                  value: 'INFORMATIVO',
                },
                {
                  label: 'Aviso',
                  value: 'AVISO',
                },
                {
                  label: 'Urgente',
                  value: 'URGENTE',
                },
              ]}
              onChange={(value: string) => {
                form.setFieldValue('tipo', value);
                setNote({ ...note, level: value });
              }}
            />
          </FormItem>
          <FormItem
            label="Conteúdo:"
            name="conteudo"
            rules={[
              { required: true, message: 'Por favor, informe o CONTEÚDO' },
            ]}
          >
            <Input.TextArea
              maxLength={80}
              placeholder="Digite o conteúdo do aviso"
              value={note?.content}
              rows={6}
              onChange={(e: any) => {
                form.setFieldValue('conteudo', e.target.value);
                setNote({ ...note, content: e.target.value });
              }}
            />
          </FormItem>
          <ContainerButton>
            <SaveButton type="primary" htmlType="submit">
              Fixar aviso
            </SaveButton>
          </ContainerButton>
        </ModalForm>
      </ViewModal>
    </Container>
  );
};

export default AdminDashboard;
