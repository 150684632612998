import styled from 'styled-components';
import { Avatar, Modal } from 'antd';
import { FaMapMarkerAlt } from 'react-icons/fa';

const PanicModal = styled(Modal)`
  .ant-modal-header {
    background-color: red;
    display: flex;
    justify-content: center;
  }
  .ant-modal-body {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  .ant-modal-title {
    color: #ffff;
    font-size: 36px;
  }
  .ant-modal-content {
    background-color: red;
  }
  .ant-modal-content p {
    color: #ffff;
    font-size: 18px;
    text-align: justify;
  }
  .ant-modal-close-x {
    color: #ffff;
  }
`;

const P = styled.p`
  font-weight: 600;
`;

const GroupAvatarColor = {
  backgroundColor: '#d90000',
};

const UserAvatar = styled(Avatar)`
  background-color: #d90000;
  margin-bottom: 20%;
`;

const Address = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const Marker = styled(FaMapMarkerAlt)`
  font-size: 14px;
`;

export { PanicModal, P, GroupAvatarColor, UserAvatar, Address, Marker };
