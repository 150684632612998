import { Text, View } from '@react-pdf/renderer';
import React from 'react';
import { PdfTriggerTableI } from '../../utils/types';

import { Styles } from './styles';
import OcurrencePerson from '../OcurrencePerson';

const OcurrenceTriggerTable: React.FC<PdfTriggerTableI> = ({ triggers }) => {
  return (
    <>
      <View style={Styles.titleOccurrenceList}>
        <Text style={Styles.titleFont}>LISTA DE ACIONAMENTOS</Text>
      </View>

      <View style={Styles.list}>
        <View style={Styles.listHeader}>
          <View style={{ width: 20 }}>
            <Text>#</Text>
          </View>
          <View style={Styles.headerInfo}>
            <Text style={{ width: 125 }}>Nome</Text>
            <Text style={{ width: 85 }}>CPF</Text>
            <Text style={{ width: 85 }}>Tipo</Text>
            <Text style={{ width: 125 }}>Responsável</Text>
            <Text style={{ width: 100 }}>Tel. Responsável</Text>
          </View>
          <View style={{ width: 50 }}>
            <Text>Hora</Text>
          </View>
        </View>
        {triggers.map((trigger: any, index: number) => {
          return (
            <OcurrencePerson
              key={index}
              index={`${index + 1}`}
              name={trigger.user.name}
              type={
                // `${JSON.stringify(trigger)}`
                trigger.user.type === 'STUDENT'
                  ? 'Aluno(a)'
                  : trigger.user.type === 'TEACHER'
                    ? 'Professor(a)'
                    : 'Funcionário(a)'
              }
              responsible={trigger.user.responsible?.name}
              responsibleNumber={trigger.user.responsible?.telephone}
              cpf={trigger.user.cpf}
              hour={new Date(trigger.actuation!).toLocaleTimeString('pt-BR')}
            />
          );
        })}
      </View>
    </>
  );
};

export default OcurrenceTriggerTable;
