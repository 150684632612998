import { Input, TreeSelect } from 'antd';
import styled from 'styled-components';
import { LoadingOutlined } from '@ant-design/icons';

const Container = styled.div`
  width: 90%;
`;

const Loading = styled(LoadingOutlined)`
  font-size: 64px !important;
  color: #000;
`;

const SearchContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 2%;
  margin-right: 4%;
`;

const SearchInput = styled(Input)`
  width: 30%;
`;

const View = styled.div`
  position: relative;
  margin-top: 4%;
  margin-bottom: -5.5% !important;
  margin-left: -4.5% !important;
  width: 100%;
  /* margin-left: 3%; */
  /* flex-direction: column; */
`;

const Cards = styled.div`
  display: grid;
  grid-template-columns: 30% 30% 30%;
  justify-content: space-around;
  @media (max-width: 1500px) {
    grid-template-columns: 40% 40%;
  }
`;

const Filter = styled(TreeSelect)`
  width: 17%;
`;

const DisableTreeSelect = styled.p`
  color: #000;
  cursor: default;
`;

export {
  SearchContainer,
  SearchInput,
  Container,
  Loading,
  View,
  Cards,
  Filter,
  DisableTreeSelect,
};
