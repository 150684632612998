import React, { useState } from 'react';
import { Input } from 'antd';
import IconRECPASSWORD from '../../../assets/img/new-password.svg';
import {
  Container,
  Title,
  Text,
  FormItem,
  RegisterButton,
  Field,
  ErrorAlert,
} from './styles';
import { Api } from '../../../services/api';
import { useNavigate } from 'react-router-dom';
import { setStorage } from '../../../utils/storage';

const EmailVerification: React.FC = () => {
  const [requiredEmail, setRequiredEmail] = useState(true);
  const [inputText, setInputText] = useState('');
  const [email, setEmail] = useState('');
  const [code, setCode] = useState('');
  const [verificationError, setVerificationError] = useState(false);
  const navigate = useNavigate();

  const handleEmailSubmit = async () => {
    await Api.post('/general/forget-pass', {
      email: inputText,
    }).then(
      resp => {
        setEmail(inputText);
        setInputText('');
        setRequiredEmail(false);
      },
      resp => setVerificationError(true),
    );
  };

  const handleCodeSubmit = async () => {
    if (!inputText) {
      setVerificationError(true);
      return;
    }

    setCode(inputText);
    await Api.post('/general/forget-pass', {
      email,
      code: inputText,
    }).then(
      resp => {
        // OK
        navigate('/forgot-password', { state: { email } });
      },
      resp => {
        // UNAUTHORIZED
        setVerificationError(true);
      },
    );
  };

  return (
    <Container>
      <img alt="" src={IconRECPASSWORD} />
      <Title>Verifique seu email para recuperar a sua senha!</Title>
      <Text>
        {requiredEmail
          ? 'Aproveite nossos recursos de segurança. Confirme a recuperação de senha no seu email.'
          : 'Enviamos um código para seu email. Digite o código para completar a verificação.'}
      </Text>
      <Field>
        {verificationError ? (
          <ErrorAlert
            message={requiredEmail ? 'Email não cadastrado' : 'Código inválido'}
            type="error"
            showIcon
            closable
            onClose={() => {
              setVerificationError(false);
            }}
          />
        ) : null}
        <FormItem>
          <Input
            size="large"
            placeholder={
              requiredEmail ? 'Digite seu email' : 'Digite seu código'
            }
            value={inputText}
            onChange={e => {
              setInputText(e.target.value);
            }}
          />
        </FormItem>
        <FormItem>
          {requiredEmail ? (
            <RegisterButton
              type="primary"
              size="large"
              onClick={async () => {
                await handleEmailSubmit();
              }}
            >
              Enviar Email
            </RegisterButton>
          ) : (
            <RegisterButton
              type="primary"
              // href="/forgot-password"
              size="large"
              onClick={async () => {
                await handleCodeSubmit();
              }}
            >
              Confirmar código
            </RegisterButton>
          )}
        </FormItem>
      </Field>
    </Container>
  );
};

export default EmailVerification;
