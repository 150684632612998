import React, { useState } from 'react';
import { Form } from 'antd';
import IconLOCK from '../../../assets/img/password-image.svg';

import {
  Container,
  Content,
  Title,
  PasswordForm,
  FormItem,
  PasswordInput,
  SaveButton,
  Fields,
  ErrorAlert,
} from './styles';
import { Api } from '../../../services/api';
import { getStorage } from '../../../utils/storage';
import { useLocation, useNavigate } from 'react-router-dom';

const ForgotPassword: React.FC = () => {
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [passwordError, setPasswordError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();
  const { state } = useLocation();

  const handleSubmit = async () => {
    if (!password) {
      setErrorMessage('A chave não pode estar vazia');
      setPasswordError(true);
      return;
    }

    if (password !== passwordConfirm) {
      setErrorMessage('As chaves não coincidem');
      setPasswordError(true);
      return;
    }

    const { email } = state;

    await Api.post('/general/redefine-pass', {
      email,
      code: '1234',
      password,
    })
      .then(resp => {
        navigate('/');
      })
      .catch(() => {
        setPasswordError(true);
      });
  };

  return (
    <Container>
      <Content>
        <PasswordForm>
          <img src={IconLOCK} alt="" />
          <Title>Recuperar senha</Title>
          <Fields layout="vertical">
            {passwordError ? (
              <ErrorAlert
                message={errorMessage}
                type="error"
                showIcon
                closable
                onClose={() => {
                  setPasswordError(false);
                }}
              />
            ) : null}
            <FormItem label="Nova chave:">
              <PasswordInput
                size="large"
                placeholder="Digite a sua nova Chave"
                onChange={e => setPassword(e.target.value)}
              />
            </FormItem>

            <FormItem label="Confirme a nova chave:">
              <PasswordInput
                size="large"
                placeholder="Digite a sua nova Chave novamente"
                onChange={e => setPasswordConfirm(e.target.value)}
              />
            </FormItem>
            <Form.Item>
              <SaveButton
                type="primary"
                size="large"
                onClick={async () => {
                  await handleSubmit();
                }}
              >
                Salvar
              </SaveButton>
            </Form.Item>
          </Fields>
        </PasswordForm>
      </Content>
    </Container>
  );
};

export default ForgotPassword;
