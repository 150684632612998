import styled from 'styled-components';
import { Modal, Form, Button, Avatar } from 'antd';

const Container = styled.div`
  display: flex;
  align-items: center;
  height: 110px;
  position: relative;
  margin-top: 20%;
  margin-bottom: 10%;
  scale: 1;
  transition: 0.1s;
  &:hover {
    scale: 1.1;
  }
  @media (max-width: 1500px) {
    margin-top: 15%;
    margin-bottom: 5%;
  }
`;

const Card = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  border: solid;
  border-width: 1px;
  border-color: #c5c5c5;
  border-radius: 10px;
`;

const AvatarContainer = styled.div`
  width: 25%;
  height: 100%;
  display: flex;
  align-items: center;
`;

const TypeServiceAvatar = styled(Avatar)`
  color: #000;
  background-color: #ffff;
`;

const Infos = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const Text = styled.p`
  font-size: 14px;
  margin-bottom: 1.5%;
`;

const Options = styled.div`
  display: flex;
  justify-content: flex-end;
  position: absolute;
  top: 90%;
  right: 5%;
`;

const UpdateButton = styled(Button)`
  background-color: #33c455 !important;
  margin-right: 2%;
`;

const DeleteButton = styled(Button)`
  background-color: #c53f3f !important;
  margin-left: 2%;
`;

const ViewModal = styled(Modal)`
  .ant-modal-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const ModalForm = styled(Form)`
  display: flex;
  flex-direction: column;
  margin-top: 5%;
`;

const InfosContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const Infos1 = styled.div`
  margin-right: 2%;
`;

const Infos2 = styled.div`
  margin-left: 2%;
`;

const FormItem = styled(Form.Item)`
  .ant-form-item-label {
    font-weight: 600;
  }
`;

const ContainerButton = styled(Form.Item)`
  display: flex;
  justify-content: center;
  margin: 0;
`;

const SaveButton = styled(Button)`
  background-color: #33c455 !important;
  width: 200px;
`;

const IconColor = {
  ok: {
    color: '#33c455',
  },
  cancel: {
    color: '#c53f3f',
  },
};

const ButtonColor = {
  ok: {
    background: '#33c455',
  },
  cancel: {
    background: '#c53f3f',
  },
};

export {
  Container,
  Card,
  AvatarContainer,
  TypeServiceAvatar,
  Infos,
  Text,
  Options,
  UpdateButton,
  DeleteButton,
  ViewModal,
  ModalForm,
  InfosContainer,
  Infos1,
  Infos2,
  FormItem,
  ContainerButton,
  SaveButton,
  IconColor,
  ButtonColor,
};
