import React, { useEffect, useState } from 'react';
import {
  Button,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Spin,
  notification,
} from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import IconREGISTER from '../../../assets/img/register-image.svg';
import IescolarBRAND from '../../../assets/img/logo-iescolar.svg';
import {
  Div1,
  RegisterContent,
  RegisterImage,
  RegisterTitle,
  RegisterText,
  Div2,
  RegisterForm,
  Inputs,
  FormItem,
  BackToLogin,
  RegisterQuestionAccount,
  LogoIescolar,
  ButtonGeolocation,
  InputNumbers,
  Container,
  Loading,
} from './styles';
import { Api, ViaCep } from '../../../services/api';
import { InstitutionE } from '../../../utils/entities';
import {
  maskInep,
  maskCellPhone,
  maskCep,
  maskLandline,
} from '../../../utils/masks';
import { captalizeString } from '../../../utils/converters';
import Messages from '../../../assets/file/messages.json';
import { useMutation } from '@apollo/client';
import UFs from '../../../utils/ufs';
import { ADD_INSTITUTION } from '../../../utils/queries/institutionQueries';

const Register: React.FC = () => {
  const [disabled, setDisabled] = useState(true);
  const [disabledInep, setDisabledInep] = useState(true);
  const [typePhone, setTypePhone] = useState('1');
  const [enableInput, setEnableInput] = useState(true);
  const [enableButton, setEnableButton] = useState(true);
  const [loading, setLoading] = useState(true);
  const [form] = Form.useForm();
  const [api, contextHolder] = notification.useNotification();
  const [institution, setInstitution] = useState<InstitutionE | undefined>(
    undefined,
  );
  const [addInstitution, addInstitutionReturn] = useMutation(ADD_INSTITUTION);
  const navigate = useNavigate();

  function getGeolocation() {
    api.destroy('1');
    navigator.geolocation.getCurrentPosition(position => {
      setInstitution({
        ...institution,
        address: {
          ...institution?.address,
          localization: {
            ...institution?.address?.localization,
            type: 'Point',
            coordinates: [position.coords.longitude, position.coords.latitude],
          },
        },
      });

      Api.get(
        `/general/point-within-geometry?lat=${position.coords.latitude}&lon=${position.coords.longitude}`,
      )
        .then(() => {
          setDisabledInep(false);
        })
        .catch(() => {
          api.error({
            message: Messages.clientNotFound,
            placement: 'top',
          });
        });
    });
  }

  useEffect(() => {
    if (addInstitutionReturn.loading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [addInstitutionReturn.loading]);

  useEffect(() => {
    if (navigator.geolocation) {
      api.warning({
        message: 'Acesso a sua Geolocalização',
        description: Messages.geolocation,
        placement: 'topLeft',
        duration: 0,
        key: '1',
        btn: (
          <ButtonGeolocation
            type="primary"
            size="small"
            onClick={getGeolocation}
          >
            Dar acesso
          </ButtonGeolocation>
        ),
      });
    }
  }, [0]);

  useEffect(() => {
    if (institution?.inep !== undefined && institution?.inep?.length === 8) {
      Api.get(`/general/dados-abertos-inep/${institution?.inep}`)
        .then(async resp => {
          setDisabled(false);

          const response = await ViaCep.get(
            `/${resp.data.siglaUf}/${resp.data.nomeMunicipio}/Centro/json/`,
          ).then(res => {
            return res.data;
          });

          setInstitution({
            ...institution,
            category:
              resp.data.dependenciaAdministrativa === 4 ? 'PRIVATE' : 'PUBLIC',
            profile: {
              ...institution?.profile,
              name: captalizeString(resp.data.nome),
            },
            address: {
              ...institution.address,
              cep: response.length === 1 ? maskCep(response[0].cep) : undefined,
              uf: resp.data.siglaUf,
              city:
                response.length > 0
                  ? response[0].localidade
                  : captalizeString(resp.data.nomeMunicipio),
              zone: resp.data.tipoLocalizacao === 1 ? 'URBAN' : 'RURAL',
            },
          });

          form.setFieldsValue({
            nome: captalizeString(resp.data.nome),
            categoria:
              resp.data.dependenciaAdministrativa === 4 ? 'PRIVATE' : 'PUBLIC',
            cidade:
              response.length > 0
                ? response[0].localidade
                : captalizeString(resp.data.nomeMunicipio),
            cep: response.length === 1 ? maskCep(response[0].cep) : undefined,
            uf: resp.data.siglaUf,
            zona: resp.data.tipoLocalizacao === 1 ? 'URBAN' : 'RURAL',
          });
        })
        .catch(() => {
          api.error({
            message: 'Escola não Encontrada',
            description: Messages.schoolNotFound,
            placement: 'topRight',
            duration: 0,
            key: '2',
          });
        });
    }
  }, [institution?.inep]);

  function complementAddressByCep(cep: string) {
    ViaCep.get(`/${cep.replace('-', '')}/json`)
      .then(resp => {
        setInstitution({
          ...institution,
          address: {
            ...institution?.address,
            cep: resp.data.cep,
            uf: resp.data.uf,
            street: resp.data.logradouro,
            city: resp.data.localidade,
            neighborhood: resp.data.bairro,
          },
        });

        form.setFieldsValue({
          uf: resp.data.uf,
          rua: resp.data.logradouro,
          cidade: resp.data.localidade,
          bairro: resp.data.bairro,
        });
      })
      .catch(() => {});
  }

  function add() {
    addInstitution({
      variables: {
        institutionInput: {
          ...institution,
          profile: {
            ...institution?.profile,
            type: 'INSTITUTION',
          },
        },
      },
    });
    setLoading(true);
    Api.post('/general/confirmation-code', {
      email: institution?.profile?.email,
    });
    navigate('/register-verification', {
      state: { email: institution?.profile?.email },
    });
  }

  return (
    <Container justify={'center'} align={'middle'}>
      <Spin spinning={loading} fullscreen indicator={<Loading />} />
      <Div2 span={14} push={10}>
        <RegisterForm form={form} layout="vertical" onFinish={add}>
          <Inputs>
            <FormItem
              label="INEP:"
              name="inep"
              rules={[
                {
                  required: true,
                  message: 'Por favor, informe o INEP',
                },
              ]}
            >
              <Input
                placeholder="Digite o INEP da Instituição"
                value={institution?.inep}
                disabled={disabledInep}
                onChange={e => {
                  form.setFieldValue('inep', maskInep(e.target.value));
                  setInstitution({
                    ...institution,
                    inep: maskInep(e.target.value),
                  });
                }}
              />
            </FormItem>
            <FormItem
              label="Nome:"
              name="nome"
              rules={[
                {
                  required: true,
                  message: 'Por favor, informe o NOME',
                },
              ]}
            >
              <Input
                placeholder="Digite o Nome da Instituição"
                value={institution?.profile?.name}
                disabled={disabled}
                onChange={e => {
                  form.setFieldValue('nome', e.target.value);
                  setInstitution({
                    ...institution,
                    profile: {
                      ...institution?.profile,
                      name: e.target.value,
                    },
                  });
                }}
              />
            </FormItem>
          </Inputs>
          <Inputs>
            <FormItem
              label="E-mail:"
              name="email"
              rules={[
                {
                  required: true,
                  message: 'Por favor, informe o E-MAIL',
                },
              ]}
            >
              <Input
                type="email"
                placeholder="Digite o seu E-mail"
                value={institution?.profile?.email}
                disabled={disabled}
                onChange={e => {
                  form.setFieldValue('email', e.target.value);
                  setInstitution({
                    ...institution,
                    profile: {
                      ...institution?.profile,
                      email: e.target.value,
                    },
                  });
                }}
              />
            </FormItem>
            <FormItem
              label="Categoria:"
              name="categoria"
              rules={[
                {
                  required: true,
                  message: 'Por favor, informe a CATEGORIA',
                },
              ]}
            >
              <Select
                placeholder="Selecione a sua Categoria"
                value={institution?.category}
                disabled={disabled}
                options={[
                  {
                    label: 'Púbilica',
                    value: 'PUBLIC',
                  },
                  {
                    label: 'Privada',
                    value: 'PRIVATE',
                  },
                ]}
                onChange={value => {
                  form.setFieldValue('categoria', value);
                  setInstitution({
                    ...institution,
                    category: value,
                  });
                }}
              />
            </FormItem>
          </Inputs>
          <Inputs>
            <FormItem
              label="Chave:"
              name="chave"
              rules={[
                {
                  required: true,
                  message: 'Por favor, informe a CHAVE',
                },
              ]}
            >
              <Input.Password
                size="small"
                placeholder="Digite a sua Chave de acesso"
                value={institution?.profile?.password}
                disabled={disabled}
                onChange={e => {
                  form.setFieldValue('chave', e.target.value);
                  setInstitution({
                    ...institution,
                    profile: {
                      ...institution?.profile,
                      password: e.target.value,
                    },
                  });

                  if (e.target.value.length >= 7) {
                    setEnableInput(false);
                    setEnableButton(true);
                  } else {
                    if (e.target.value.length >= 0) {
                      setEnableInput(true);
                      setEnableButton(false);
                    }
                  }
                }}
              />
            </FormItem>
            <FormItem
              label="Repita a Chave:"
              name="repitachave"
              rules={[
                {
                  required: true,
                  message: 'Por favor, repita a CHAVE',
                },
              ]}
            >
              <Input.Password
                size="small"
                placeholder="Digite novamente sua Chave de acesso"
                disabled={disabled || enableInput}
                onChange={e => {
                  if (
                    institution?.profile?.password !== undefined &&
                    institution?.profile?.password?.length ===
                      e.target.value.length &&
                    institution?.profile?.password === e.target.value
                  ) {
                    setEnableButton(false);
                  } else {
                    if (
                      institution?.profile?.password !== undefined &&
                      e.target.value.length <
                        institution?.profile?.password?.length
                    ) {
                      setEnableButton(true);
                    }
                  }
                }}
              />
            </FormItem>
          </Inputs>
          <Inputs>
            <FormItem
              label="Telefone:"
              name="telefone"
              rules={[
                {
                  required: true,
                  message: 'Por favor, informe o TELEFONE',
                },
              ]}
            >
              <Input
                placeholder="Digite o seu Telefone"
                value={institution?.telephone}
                addonBefore={
                  <Select
                    defaultValue="Celular"
                    onChange={(value: string) => {
                      setTypePhone(value);
                    }}
                  >
                    <Select.Option value="1">Celular</Select.Option>
                    <Select.Option value="2">Fixo</Select.Option>
                  </Select>
                }
                disabled={disabled}
                onChange={e => {
                  form.setFieldValue(
                    'telefone',
                    typePhone === '1'
                      ? maskCellPhone(e.target.value)
                      : maskLandline(e.target.value),
                  );
                  setInstitution({
                    ...institution,
                    telephone:
                      typePhone === '1'
                        ? maskCellPhone(e.target.value)
                        : maskLandline(e.target.value),
                  });
                }}
              />
            </FormItem>
            <FormItem
              label="CEP:"
              name="cep"
              rules={[
                {
                  required: true,
                  message: 'Por favor, informe o CEP',
                },
              ]}
            >
              <Input
                placeholder="Digite o seu CEP"
                value={institution?.address?.cep}
                disabled={disabled}
                onChange={e => {
                  form.setFieldValue('cep', maskCep(e.target.value));
                  setInstitution({
                    ...institution,
                    address: {
                      ...institution?.address,
                      cep: maskCep(e.target.value),
                    },
                  });
                  complementAddressByCep(e.target.value);
                }}
              />
            </FormItem>
          </Inputs>
          <Inputs>
            <FormItem
              label="UF:"
              name="uf"
              rules={[
                {
                  required: true,
                  message: 'Por favor, informe o ESTADO',
                },
              ]}
            >
              <Select
                placeholder="Selecione o Estado da Instituição"
                value={institution?.address?.uf}
                disabled={disabled}
                options={UFs.ufs.map(item => {
                  return {
                    label: item.nome,
                    value: item.sigla,
                  };
                })}
                onChange={value => {
                  form.setFieldValue('uf', value);
                  setInstitution({
                    ...institution,
                    address: {
                      ...institution?.address,
                      uf: value,
                    },
                  });
                }}
              />
            </FormItem>
            <FormItem
              label="Cidade:"
              name="cidade"
              rules={[
                {
                  required: true,
                  message: 'Por favor, informe a CIDADE',
                },
              ]}
            >
              <Input
                placeholder="Digite a sua Cidade"
                value={institution?.address?.city}
                disabled={disabled}
                onChange={e => {
                  form.setFieldValue('cidade', e.target.value);
                  setInstitution({
                    ...institution,
                    address: {
                      ...institution?.address,
                      city: e.target.value,
                    },
                  });
                }}
              />
            </FormItem>
          </Inputs>
          <Inputs>
            <FormItem
              label="Bairro:"
              name="bairro"
              rules={[
                {
                  required: true,
                  message: 'Por favor, informe o BAIRRO',
                },
              ]}
            >
              <Input
                placeholder="Digite o seu Bairro"
                value={institution?.address?.neighborhood}
                disabled={disabled}
                onChange={e => {
                  form.setFieldValue('bairro', e.target.value);
                  setInstitution({
                    ...institution,
                    address: {
                      ...institution?.address,
                      neighborhood: e.target.value,
                    },
                  });
                }}
              />
            </FormItem>
            <FormItem
              label="Zona:"
              name="zona"
              rules={[
                {
                  required: true,
                  message: 'Por favor, informe a ZONA',
                },
              ]}
            >
              <Select
                placeholder="Selecione a Zona da Instituição"
                value={institution?.address?.zone}
                disabled={disabled}
                options={[
                  {
                    label: 'Urbana',
                    value: 'URBAN',
                  },
                  {
                    label: 'Rural',
                    value: 'RURAL',
                  },
                ]}
                onChange={value => {
                  form.setFieldValue('zona', value);
                  setInstitution({
                    ...institution,
                    address: {
                      ...institution?.address,
                      zone: value,
                    },
                  });
                }}
              />
            </FormItem>
          </Inputs>
          <Inputs>
            <FormItem
              label="Rua:"
              name="rua"
              rules={[
                {
                  required: true,
                  message: 'Por favor, informe a RUA',
                },
              ]}
            >
              <Input
                placeholder="Digite a sua Rua"
                value={institution?.address?.street}
                disabled={disabled}
                onChange={e => {
                  form.setFieldValue('rua', e.target.value);
                  setInstitution({
                    ...institution,
                    address: {
                      ...institution?.address,
                      street: e.target.value,
                    },
                  });
                }}
              />
            </FormItem>
            <FormItem
              label="Número:"
              name="numero"
              rules={[
                {
                  required: true,
                  message: 'Por favor, informe o NÚMERO',
                },
              ]}
            >
              <InputNumbers
                placeholder="Digite o Número da Instituição"
                type="number"
                value={institution?.address?.numbering}
                disabled={disabled}
                onChange={value => {
                  form.setFieldValue('numero', value);
                  setInstitution({
                    ...institution,
                    address: {
                      ...institution?.address,
                      numbering: Number(value),
                    },
                  });
                }}
              />
            </FormItem>
          </Inputs>
          <FormItem>
            <Button disabled={enableButton} htmlType="submit" type="primary">
              Finalizar cadastro
            </Button>
          </FormItem>
          <BackToLogin>
            <RegisterQuestionAccount>Já tem uma conta?</RegisterQuestionAccount>
            <Link className="link" to={'/'}>
              Entrar
            </Link>
          </BackToLogin>
        </RegisterForm>
        {/* <LogoIescolar src={IescolarBRAND} alt="" width={36} height={18} /> */}
      </Div2>
      <Div1 span={10} pull={14}>
        {contextHolder}
        <RegisterContent>
          <RegisterTitle>Segurança dos alunos em Primeiro Lugar!</RegisterTitle>
        </RegisterContent>

        <RegisterImage src={IconREGISTER} alt="" />

        <RegisterContent>
          <RegisterText>
            Cadastre-se e faça parte da nossa missão de garantir a segurança e
            cuidado para todos os alunos da escola
          </RegisterText>
        </RegisterContent>
      </Div1>
    </Container>
  );
};

export default Register;
