import styled from 'styled-components';
import { Form, Divider, InputNumber } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: column;
`;

const Loading = styled(LoadingOutlined)`
  font-size: 64px !important;
  color: #000;
`;

const EditForm = styled(Form)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
  width: 100%;
  .ant-form-item {
    width: 45%;
  }
`;

const Inputs = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 80%;
`;

const FormItem = styled(Form.Item)`
  width: 25% !important;
  .ant-form-item-control-input-content {
    display: flex;
    justify-content: center;
  }
  .ant-input {
    width: 100%;
  }
  .ant-select-selector {
    width: 100%;
  }
  .ant-form-item-label {
    padding: 0;
  }
  .ant-btn {
    margin-top: 7%;
    background-color: #33c455 !important;
    width: 70%;
  }
`;

const InputNumbers = styled(InputNumber)`
  width: 100%;
`;

const HiddenItem = styled(Form.Item)`
  width: 25% !important;
  visibility: hidden;
  .ant-form-item-control-input-content {
    display: flex;
    justify-content: center;
  }
  .ant-input {
    width: 100%;
  }
  .ant-select-selector {
    width: 100%;
  }
  .ant-form-item-label {
    padding: 0;
  }
`;

const Line = styled(Divider)`
  margin-top: 0 !important;
  .ant-divider-inner-text {
    font-weight: 600;
  }
`;

export {
  Container,
  Loading,
  EditForm,
  Inputs,
  FormItem,
  InputNumbers,
  HiddenItem,
  Line,
};
