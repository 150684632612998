import React, { useState, useEffect } from 'react';
import { Container, UploadContainer, Loading, ImageContainer } from './styles';
import { Button, Upload, Spin, Image, Divider } from 'antd';
import { base64ToObject } from '../../../../utils/converters';
import { getStorage } from '../../../../utils/storage';
import { MAP_INSTITUTION_BY_ID } from '../../../../utils/queries/institutionQueries';
import { useQuery } from '@apollo/client';
import { Api } from '../../../../services/api';

const Map: React.FC = () => {
  const auth = base64ToObject(getStorage(`${process.env.REACT_APP_AUTH}`));
  const [loading, setLoading] = useState(true);
  const queryMap = useQuery(MAP_INSTITUTION_BY_ID, {
    variables: {
      id: auth.id,
    },
  });

  useEffect(() => {
    if (queryMap.loading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [queryMap.loading]);

  // useEffect(() => {}, [queryMap.data?.getInstitutionByProfileId]);
  function upload(file: any) {
    setLoading(true);
    const form = new FormData();
    form.append('map', file);
    form.append('id', auth.id);

    Api.post('/upload/map/', form, {
      headers: {
        Authorization: auth.token,
      },
    })
      .then(() => {
        setLoading(false);
        const list = document.querySelector(
          '.ant-upload-list-item-container',
        ) as any;
        list.innerHTML = null;
        queryMap.refetch({ id: auth.id });
      })
      .catch(() => {
        setLoading(false);
      });
  }

  return (
    <Container>
      <Spin spinning={loading} fullscreen indicator={<Loading />} />
      <UploadContainer>
        <Upload
          accept="image/*"
          maxCount={1}
          multiple={false}
          beforeUpload={() => {
            return false;
          }}
          onChange={file => {
            upload(file.fileList[0]?.originFileObj);
          }}
        >
          <Button type="primary" size="large">
            Importar mapa
          </Button>
        </Upload>
      </UploadContainer>
      <Divider orientation="left">
        <b>| GERENCIAMENTO DO MAPA DA ESCOLA & INFORMAÇÕES DE SEGURANÇA</b>
      </Divider>
      <ImageContainer>
        <Image
          draggable={false}
          src={queryMap.data?.getInstitutionByProfileId.map}
        />
      </ImageContainer>
    </Container>
  );
};

export default Map;
