import React, { useState } from 'react';
import { ValidationI } from '../../utils/types';
import {
  Container,
  ValidateButton,
  ChangeButton,
  ViewModal,
  ModalForm,
  FormItem,
  ContainerButton,
  SaveButton,
  IconColor,
  ButtonColor,
  Card,
  AvatarContainer,
  TypePersonAvatar,
  Infos,
  Text,
  Options,
  Complement,
  UserInfo,
  InactivateButton,
} from './styles';
import {
  Avatar,
  Button,
  Input,
  Modal,
  Popconfirm,
  Select,
  Tooltip,
  message,
} from 'antd';
import {
  QuestionCircleOutlined,
  ExclamationCircleOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { HiOutlineUpload } from 'react-icons/hi';
import { TiInputChecked } from 'react-icons/ti';
import { TbForbid2Filled } from 'react-icons/tb';

const Validation: React.FC<ValidationI> = ({
  id,
  name,
  type,
  image,
  icon,
  cpf,
  validate = false,
  reponsible,
  responsibleNumber,
  imported = false,
  isStudent,
  email,
  active,
  onAlterType,
  onActive,
  onStatus,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectType, setSelectType] = useState(`${type}`);

  const showModal = () => {
    setIsModalOpen(true);
  };

  function alterType() {
    setIsModalOpen(false);
    onAlterType(id, selectType);
  }

  function validation() {
    setIsModalOpen(false);
    onStatus(id);
  }

  function activate(value: boolean) {
    setIsModalOpen(false);
    onActive(id, value);
  }

  function confirmValidateModal() {
    Modal.confirm({
      title: 'Tem certeza?',
      icon: <ExclamationCircleOutlined style={IconColor.cancel} />,
      content: `Ao clicar em Sim, ${name} não terá acesso ao APP. Tem certeza que deseja clicar em SIM?`,
      okText: 'Sim',
      cancelText: 'Não',
      okButtonProps: {
        style: ButtonColor.cancel,
      },
      onOk() {
        message.error(`${name} não validado`);
      },
      onCancel: () => {
        setIsModalOpen(false);
      },
    });
  }

  return (
    <Container>
      <Card>
        <AvatarContainer>
          <Avatar
            shape="circle"
            size={76}
            icon={image === null ? <UserOutlined /> : <img src={image} />}
          />
          <UserInfo>
            {validate ? (
              <Tooltip title="Usuário Validado">
                <TiInputChecked size={22} color="#33c455" />
              </Tooltip>
            ) : null}

            {!active && validate ? (
              <Tooltip title="Usuário Inativo">
                <TbForbid2Filled size={16} color="#c43333" />
              </Tooltip>
            ) : null}
          </UserInfo>
        </AvatarContainer>
        <Infos>
          <Text>
            {name} - {type}
          </Text>
          <Text>
            {cpf !== undefined && cpf !== null
              ? `${cpf.substring(0, 3)}******${cpf.substring(12)}`
              : undefined}
          </Text>
          {isStudent ? (
            <>
              <Text>
                {reponsible !== undefined && reponsible !== null
                  ? reponsible
                  : 'Responsável não Informado'}
              </Text>
              <Text>
                {responsibleNumber !== undefined && responsibleNumber !== null
                  ? responsibleNumber
                  : 'Telefone do Responsável não Informado'}
              </Text>
            </>
          ) : (
            <Text>{email}</Text>
          )}
        </Infos>
      </Card>
      <Complement>
        <TypePersonAvatar icon={icon} />
        {imported ? <TypePersonAvatar icon={<HiOutlineUpload />} /> : null}
      </Complement>

      <Options>
        {validate ? (
          <>
            {!active ? (
              <Popconfirm
                title="Tem certeza"
                description={`Ativar ${name}?`}
                onConfirm={() => {
                  activate(true);
                }}
                okText="Sim"
                cancelText="Não"
              >
                <Button type="primary">Ativar</Button>
              </Popconfirm>
            ) : (
              <Popconfirm
                title="Tem certeza"
                description={`Inativar ${name}?`}
                onConfirm={() => {
                  activate(false);
                }}
                okText="Sim"
                cancelText="Não"
                icon={<QuestionCircleOutlined style={IconColor.cancel} />}
                okButtonProps={{ style: ButtonColor.cancel }}
              >
                <InactivateButton type="primary">Inativar</InactivateButton>
              </Popconfirm>
            )}
          </>
        ) : (
          <>
            <Popconfirm
              title={`${'Validar'} ${name}?`}
              description="Você deseja validar esse cadastro?"
              onConfirm={validation}
              onCancel={confirmValidateModal}
              okText="Sim"
              cancelText="Não"
              icon={<QuestionCircleOutlined style={IconColor.ok} />}
              okButtonProps={{ style: ButtonColor.ok }}
            >
              <ValidateButton type="primary">Validar</ValidateButton>
            </Popconfirm>
            <ChangeButton type="primary" onClick={showModal}>
              Alterar
            </ChangeButton>
          </>
        )}
      </Options>
      <ViewModal
        centered
        open={isModalOpen}
        onCancel={() => {
          setIsModalOpen(false);
        }}
        footer={null}
        width={300}
      >
        <ModalForm layout="vertical">
          <FormItem label="Nome:">
            <Input value={name} disabled={true} />
          </FormItem>
          <FormItem label="Tipo:">
            <Select
              defaultValue={type}
              options={[
                {
                  label: 'Aluno',
                  value: 'STUDENT',
                },
                {
                  label: 'Professor',
                  value: 'TEACHER',
                },
                {
                  label: 'Funcionário',
                  value: 'EMPLOYEE',
                },
              ]}
              onChange={value => {
                setSelectType(value);
              }}
            />
          </FormItem>
          <ContainerButton>
            <SaveButton type="primary" onClick={alterType}>
              Salvar
            </SaveButton>
          </ContainerButton>
        </ModalForm>
      </ViewModal>
    </Container>
  );
};

export default Validation;
