import styled from 'styled-components';
import { Button } from 'antd';

const Container = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Title = styled.p`
  margin-top: 2%;
  margin-bottom: 2%;
  font-size: 24px;
  font-weight: 600;
  color: #33c455;
`;

const Text = styled.p`
  margin-bottom: 2%;
  width: 533px;
  text-align: center;
  color: #5a5a5a;
`;

const ContainerButton = styled.div`
  width: 15%;
`;

const LoginButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #33c455 !important;
  text-decoration: none;
`;

export { Container, Title, Text, ContainerButton, LoginButton };
