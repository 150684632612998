import React, { useEffect, useState } from 'react';
import Brazil from '@svg-maps/brazil';
import { SVGMap } from 'react-svg-map';
import {
  Container,
  Loading,
  Col1,
  Col2,
  SaveButton,
  StateHeader,
  Flag,
  Title,
  StateInfos,
  Item,
  Regiao,
  Municipios,
  Area,
  Label,
  Info,
  RegisteredStates,
  ListItem,
  StateArea,
  SelectMunicipiosContainer,
  SelectMunicipios,
  ListFlag,
  BackButton,
} from './styles';
import { UfI, TreeSelectI } from '../../../../utils/types';
import UF from '../../../../utils/ufs';
import Brasil from '../../../../assets/uf/flags/square-rounded/01-brasil-square-rounded.svg';
import {
  ADD_CITY_BY_IDS,
  ADD_CITY_BY_UF,
  CITIES_BY_UF,
  DELETE_CITY_BY_IDS,
  LAST_CLIENTS,
} from '../../../../utils/queries/adminQueries';
import { useQuery, useMutation } from '@apollo/client';
import { Button, Spin } from 'antd';

const RegisterClient: React.FC = () => {
  const [acronym, setAcronym] = useState();
  const [ufSelected, setUfSelected] = useState<UfI>();
  const [selecteds, setSelecteds] = useState<string[]>([]);
  const [allSelecteds, setAllSelecteds] = useState<string[]>([]);
  const [treeData, setTreeData] = useState<TreeSelectI[]>([]);
  const [loading, setLoading] = useState(true);
  const queryLastClients = useQuery(LAST_CLIENTS);
  const queryCitiesByUF = useQuery(CITIES_BY_UF, {
    variables: {
      uf: acronym,
    },
  });
  const [addCitiesByUF, addCitiesByUFReturn] = useMutation(ADD_CITY_BY_UF);
  const [addCitiesByIds, addCitiesByIdsReturn] = useMutation(ADD_CITY_BY_IDS);
  const [deleteCitiesByIds, deleteCitiesByIdsReturn] =
    useMutation(DELETE_CITY_BY_IDS);

  useEffect(() => {
    setUfSelected(UF.ufs.find(e => e.sigla === acronym));
  }, [acronym]);

  useEffect(() => {
    if (ufSelected !== undefined && countTotalUf() === ufSelected?.municipios) {
      setSelecteds([ufSelected.sigla]);
    } else {
      setSelecteds(getCitiesUf());
    }
    setAllSelecteds(getCitiesUf());
  }, [treeData]);

  useEffect(() => {
    if (
      queryCitiesByUF.loading ||
      addCitiesByUFReturn.loading ||
      addCitiesByIdsReturn.loading
    ) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [
    queryCitiesByUF.loading,
    addCitiesByUFReturn.loading,
    addCitiesByIdsReturn.loading,
    selecteds,
  ]);

  useEffect(() => {
    setTreeData([
      {
        title: ufSelected?.nome,
        value: acronym,
        key: acronym,
        children: queryCitiesByUF.data?.getCityByUF.map((city: any) => {
          return {
            title: city.name,
            value: city._id,
            key: city._id,
            enable: city.enable,
          };
        }),
      },
    ]);
  }, [queryCitiesByUF.data?.getCityByUF, ufSelected]);

  function addCities() {
    if (selecteds[0]?.length <= 2) {
      addCitiesByUF({
        variables: {
          uf: selecteds[0],
        },
        refetchQueries: [{ query: LAST_CLIENTS }],
      });
    } else {
      addCitiesByIds({
        variables: {
          ids: selecteds,
        },
        refetchQueries: [{ query: LAST_CLIENTS }],
      });
    }
    remove();
    setLoading(true);
  }

  function remove() {
    let citiesDeletes: string[] = [];
    if (selecteds.length === 0) {
      citiesDeletes = allSelecteds;
    } else {
      allSelecteds.forEach(select => {
        if (!selecteds.includes(select)) citiesDeletes.push(select);
      });
    }

    if (citiesDeletes.length > 0) {
      deleteCitiesByIds({
        variables: {
          ids: citiesDeletes,
        },
      });
    }
  }

  function onLocationClick(event: any) {
    setAcronym(event.target.getAttribute('id').toUpperCase());
    setUfSelected(UF.ufs.find(e => e.sigla === acronym));
  }

  function countTotalUf() {
    let count = 0;
    if (treeData[0]?.children !== undefined) {
      for (const city of treeData[0]?.children) {
        if (city.enable) count++;
      }
    }
    return count;
  }

  function getCitiesUf() {
    const cities = [];
    if (treeData[0]?.children !== undefined) {
      for (const city of treeData[0]?.children) {
        if (city.enable) cities.push(`${city?.value}`);
      }
    }
    return cities;
  }

  return (
    <Container>
      {ufSelected !== undefined ? (
        <BackButton
          onClick={() => {
            setUfSelected(undefined);
          }}
        />
      ) : null}
      <Spin spinning={loading} fullscreen indicator={<Loading spin />} />
      <Col1>
        <StateHeader>
          {ufSelected?.bandeira === undefined ? (
            <Flag src={Brasil} alt="" draggable={false} />
          ) : (
            <Flag src={ufSelected.bandeira} alt="" draggable={false} />
          )}
          {ufSelected === undefined ? (
            <Title>BRASIL - BR</Title>
          ) : (
            <Title>
              {ufSelected.nome === 'Distrito Federal'
                ? ufSelected.nome.toUpperCase() + ' - ' + ufSelected.sigla
                : 'ESTADO D' +
                  ufSelected.artigo.toUpperCase() +
                  ' ' +
                  ufSelected.nome.toUpperCase() +
                  ' - ' +
                  ufSelected.sigla}
            </Title>
          )}
        </StateHeader>

        <StateInfos>
          {ufSelected === undefined ? (
            <>
              <Item>
                <Regiao>
                  <Label>Região:</Label>
                  <Info>Selecione um Estado</Info>
                </Regiao>
                <Municipios>
                  <Label>Número de Municipios:</Label>
                  <Info>Selecione um Estado</Info>
                </Municipios>
              </Item>
              <RegisteredStates>
                <Label>Lista de cadastros recentes:</Label>
                {queryLastClients.data?.getLastFiveClients.map((city: any) => {
                  const flag = UF.ufs.find(e => e.sigla === city.uf);
                  return (
                    <ListItem key={city._id}>
                      {<ListFlag src={flag?.bandeira} draggable={false} />}
                      {city.name} - {city.uf}
                    </ListItem>
                  );
                })}
              </RegisteredStates>
            </>
          ) : (
            <Item>
              <Regiao>
                <Label>Região:</Label>
                <Info>{ufSelected.regiao}</Info>
              </Regiao>
              <Municipios>
                <Label>Número de Municípios:</Label>
                <Info>{ufSelected.municipios}</Info>
              </Municipios>
              <SelectMunicipiosContainer>
                <Label>Municípios:</Label>
                <SelectMunicipios
                  treeDefaultExpandAll
                  placeholder="Selecione"
                  value={selecteds}
                  treeData={treeData}
                  treeCheckable
                  showCheckedStrategy="SHOW_PARENT"
                  showSearch={false}
                  onChange={(value: any) => {
                    setSelecteds(value);
                  }}
                />
              </SelectMunicipiosContainer>
            </Item>
          )}
          {ufSelected?.area === undefined ? null : (
            <>
              <Area>Área:</Area>
              <StateArea src={ufSelected.area} alt="" />
              <SaveButton
                // disabled={selecteds.length <= 0}
                type="primary"
                onClick={() => {
                  addCities();
                  location.reload();
                }}
              >
                Salvar
              </SaveButton>
            </>
          )}
        </StateInfos>
      </Col1>
      <Col2>
        <SVGMap
          map={Brazil}
          className="map"
          onLocationClick={onLocationClick}
        />
      </Col2>
    </Container>
  );
};

export default RegisterClient;
