import React from 'react';
import { AlertI } from '../../utils/types';
import {
  ActuationEmergencyInfo,
  Address,
  EmergencyModal,
  Marker,
} from './styles';
import Lottie from 'react-lottie';
import EmergencyGIF from '../../assets/file/emergency-animation.json';
import { Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';

const EmergencyAlert: React.FC<AlertI> = ({
  activate,
  disable,
  occurrence,
}) => {
  return (
    <EmergencyModal
      title={`EMERGÊNCIA ${
        occurrence?.level === 'LOW'
          ? 'BAIXA'
          : occurrence?.level === 'MEDIUM'
            ? 'MÉDIA'
            : 'ALTA'
      } ACIONADA`}
      open={activate}
      onCancel={() => {
        disable(`${occurrence?._id}`);
      }}
      width={700}
      footer={null}
      centered
    >
      <Lottie
        width={'50%'}
        options={{
          loop: true,
          autoplay: true,
          animationData: EmergencyGIF,
        }}
        isClickToPauseDisabled={true}
      />
      <ActuationEmergencyInfo>
        <div>
          <Avatar
            size={29}
            icon={
              occurrence?.triggers[0].user.image !== undefined ? (
                <img src={occurrence?.triggers[0]?.user?.image} />
              ) : (
                <UserOutlined />
              )
            }
          />
        </div>
        <div>
          {occurrence?.triggers?.map((trigger, index) => {
            return (
              <p key={index}>
                {trigger.user.name} -{' '}
                {trigger.user.responsible !== undefined ? (
                  <>
                    <b>Responsável:</b> {trigger.user.responsible?.name} -{' '}
                    {trigger.user.responsible?.telephone}
                  </>
                ) : (
                  <div />
                )}
              </p>
            );
          })}
        </div>
      </ActuationEmergencyInfo>
      <Address>
        <p>
          <Marker />
          {`${occurrence?.institution.address?.street}, nº ${occurrence?.institution.address?.numbering}  ${occurrence?.institution.address?.city}-${occurrence?.institution.address?.uf}`}
        </p>
      </Address>
    </EmergencyModal>
  );
};

export default EmergencyAlert;
