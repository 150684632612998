import styled from 'styled-components';
import { LoadingOutlined } from '@ant-design/icons';

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Cards = styled.div`
  display: grid;
  grid-template-columns: 22% 22% 22% 22%;
  justify-content: space-between;
  width: 80%;
  margin-top: 2%;
  margin-bottom: 2%;
  @media (max-width: 1500px) {
    grid-template-columns: 31% 31% 31%;
  }
`;

const Filters = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 2%;
`;

const Filter1 = styled.div`
  margin-right: 2%;
`;

const Filter2 = styled.div`
  margin-right: 5%;
`;

const Loading = styled(LoadingOutlined)`
  font-size: 64px !important;
  color: #000;
`;

export { Container, Cards, Filters, Filter1, Filter2, Loading };
