import axios from 'axios';

const Api = axios.create({
  baseURL: `${process.env.REACT_APP_API}`,
});

const ViaCep = axios.create({
  baseURL: `${process.env.REACT_APP_API_CEP}`,
});

export { Api, ViaCep };
