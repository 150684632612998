function maskCpf(value: string): string {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1');
}

function maskCellPhone(value: string) {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '($1) $2')
    .replace(/(\d{5})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{4})\d+?$/, '$1');
}

function maskLandline(value: string) {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '($1) $2')
    .replace(/(\d{4})(\d)/, '$1 $2')
    .replace(/(\d{4})(\d)/, '$1');
}

function maskWhatsApp(value: string) {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '+$1 $2')
    .replace(/(\d{2})(\d)/, '($1) $2')
    .replace(/(\d{5})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{4})\d+?$/, '$1');
}

function maskCep(value: string) {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{5})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{3})\d+?$/, '$1');
}

function maskInep(value: string) {
  return value.replace(/\D/g, '').replace(/(\d{8})(\d)/, '$1');
}

function maskNumber(value: string) {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{1,2})$/, '$1')
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
}

export {
  maskCpf,
  maskCellPhone,
  maskLandline,
  maskWhatsApp,
  maskCep,
  maskInep,
  maskNumber,
};
