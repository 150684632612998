import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { HiMiniArrowLeftOnRectangle } from 'react-icons/hi2';
import { Layout, Menu, Popconfirm } from 'antd';
import {
  Container,
  LateralSider,
  ContainerButton,
  ExitButton,
  LayoutHeader,
  LayoutContent,
  Div1,
  Div2,
  Label,
  LabelLink,
  DashboardIcon,
  OcurrencesIcon,
  AssistanceIcon,
  ValidationsIcon,
  RulesIcon,
  RegisterDataIcon,
  ButtonColor,
  Space,
  MapIcon,
} from './styles';
import ManagerCard from '../../../../components/ManagerCard';
import Routes from '../../../../assets/file/routes.json';
import { getStorage, setStorage } from '../../../../utils/storage';
import { useQuery } from '@apollo/client';
import {
  IMAGE_INSTITUTION_BY_ID,
  OCCURRENCE_BY_INSTITUTION_ACTIVES,
} from '../../../../utils/queries/institutionQueries';
import { base64ToObject } from '../../../../utils/converters';
import PanicAlert from '../../../../components/PanicAlert';
import EmergencyAlert from '../../../../components/EmergencyAlert';
import {
  connection,
  query,
  ref,
  onValue,
  limitToLast,
} from '../../../../services/firebase';
import { CollectionRealTimeDatabase } from '../../../../utils/enums';
import { OccurrenceE } from '../../../../utils/entities';
import { closeAlert, wasOpenAlert } from '../../../../utils/manageralerts';

const InstitutionLayout: React.FC = () => {
  const page = getStorage(`${process.env.REACT_APP_SELECTED_PAGE}`, {
    value: '1',
  });
  const auth = base64ToObject(getStorage(`${process.env.REACT_APP_AUTH}`));
  const queryImageInstitution = useQuery(IMAGE_INSTITUTION_BY_ID, {
    variables: {
      id: auth.id,
    },
  });
  const [occurrence, setOccurrence] = useState<OccurrenceE>();
  const navigate = useNavigate();
  const queryOccurrence = useQuery(OCCURRENCE_BY_INSTITUTION_ACTIVES, {
    variables: {
      insID: auth.id,
      occuID: null,
    },
  });
  const [isPanicModalOpen, setIsPanicModalOpen] = useState(false);
  const [isEmergencyModalOpen, setIsEmergencyModalOpen] = useState(false);

  function clearLocalStorage() {
    localStorage.clear();
  }

  function showPanicModal() {
    setIsPanicModalOpen(true);
  }

  function handlePanicCancel(id: string) {
    setIsPanicModalOpen(false);
    closeAlert(id);
  }

  function showEmergencyModal() {
    setIsEmergencyModalOpen(true);
  }

  function handleEmergencyCancel(id: string) {
    setIsEmergencyModalOpen(false);
    closeAlert(id);
  }

  function refetch(occurrence: any) {
    queryOccurrence
      .refetch({ insID: auth.id, occuID: occurrence.mongoId })
      .then(resp => {
        setOccurrence(resp.data.getOccurrenceInstitutionActive);

        if (resp.data.getOccurrenceInstitutionActive !== null) {
          const time = Math.abs(
            Math.round(
              (new Date(
                resp.data.getOccurrenceInstitutionActive.date,
              ).getTime() -
                new Date().getTime()) /
                1000 /
                60,
            ),
          );

          if (time <= 7 && !wasOpenAlert(occurrence.mongoId, occurrence.type)) {
            if (occurrence.type === 'PANIC') {
              showPanicModal();
            } else {
              if (
                occurrence.type === 'EMERGENCY' &&
                !wasOpenAlert(occurrence.mongoId)
              )
                showEmergencyModal();
            }
          }
        }
      });
  }

  console.log(queryOccurrence);

  useEffect(() => {
    onValue(
      query(
        ref(connection, `${CollectionRealTimeDatabase.OCCURRENCE}`),
        limitToLast(1),
      ),
      snapshot => {
        snapshot.forEach(child => {
          refetch(child.val());
        });
      },
    );
  }, []);

  return (
    <Container>
      <EmergencyAlert
        activate={isEmergencyModalOpen}
        disable={id => {
          handleEmergencyCancel(id);
        }}
        occurrence={occurrence}
      />
      <PanicAlert
        activate={isPanicModalOpen}
        disable={id => {
          handlePanicCancel(id);
        }}
        occurrence={occurrence}
      />
      <LateralSider>
        <Div1>
          <ManagerCard
            image={
              queryImageInstitution.data?.getInstitutionByProfileId.profile
                .image
            }
          />
        </Div1>
        <Div2>
          <Menu
            theme="dark"
            mode="vertical"
            defaultSelectedKeys={[page.value]}
            items={[
              {
                key: '1',
                label: (
                  <Label>
                    <DashboardIcon />
                    <LabelLink to={'dashboard'}>Dashboard</LabelLink>
                  </Label>
                ),

                onClick: () => {
                  setStorage(
                    `${process.env.REACT_APP_SELECTED_PAGE}`,
                    Routes.private.instituicao.dashboard,
                  );
                },
              },
              {
                key: '2',
                label: (
                  <Label>
                    <OcurrencesIcon />
                    <LabelLink to={'occurrence'}>Ocorrências</LabelLink>
                  </Label>
                ),
                onClick: () => {
                  setStorage(
                    `${process.env.REACT_APP_SELECTED_PAGE}`,
                    Routes.private.instituicao.occurrences,
                  );
                },
              },
              {
                key: '3',
                label: (
                  <Label>
                    <AssistanceIcon />
                    <LabelLink to={'assistance'}>
                      Serviços de Assistência
                    </LabelLink>
                  </Label>
                ),
                onClick: () => {
                  setStorage(
                    `${process.env.REACT_APP_SELECTED_PAGE}`,
                    Routes.private.instituicao.assistance,
                  );
                },
              },
              {
                key: '4',
                label: (
                  <Label>
                    <ValidationsIcon />

                    <LabelLink to={'validation'}>Validações</LabelLink>
                  </Label>
                ),
                onClick: () => {
                  setStorage(
                    `${process.env.REACT_APP_SELECTED_PAGE}`,
                    Routes.private.instituicao.validations,
                  );
                },
              },
              {
                key: '5',
                label: (
                  <Label>
                    <RulesIcon />
                    <LabelLink to={'rules'}>Regras</LabelLink>
                  </Label>
                ),
                onClick: () => {
                  setStorage(
                    `${process.env.REACT_APP_SELECTED_PAGE}`,
                    Routes.private.instituicao.rules,
                  );
                },
              },
              {
                key: '6',
                label: (
                  <Label>
                    <MapIcon />
                    <LabelLink to={'map'}>Mapa</LabelLink>
                  </Label>
                ),
                onClick: () => {
                  setStorage(
                    `${process.env.REACT_APP_SELECTED_PAGE}`,
                    Routes.private.instituicao.map,
                  );
                },
              },
              {
                key: '7',
                label: (
                  <Label>
                    <RegisterDataIcon />
                    <LabelLink to={'register-data'}>Dados cadastrais</LabelLink>
                  </Label>
                ),
                onClick: () => {
                  setStorage(
                    `${process.env.REACT_APP_SELECTED_PAGE}`,
                    Routes.private.instituicao.registerData,
                  );
                },
              },
            ]}
          />
        </Div2>
        <Popconfirm
          title={''}
          description="Tem certeza que deseja sair da sua conta?"
          onConfirm={() => {
            clearLocalStorage();
            navigate('/');
          }}
          okText="Sim"
          cancelText="Não"
          icon={null}
          okButtonProps={{ style: ButtonColor.cancel }}
        >
          <ContainerButton>
            <ExitButton
              type="text"
              icon={<HiMiniArrowLeftOnRectangle className="svg-icons" />}
            >
              Sair da conta
            </ExitButton>
          </ContainerButton>
        </Popconfirm>
      </LateralSider>

      <Layout>
        <LayoutHeader>
          <Space />
          {/*           <SchoolName
            image={require('../../../../assets/img/prefeitura-cajazeiras-pb.png')}
            title="GOVERNO DO ESTADO DA PARAÍBA"
            text="E.E.F.M  ANDRÉ PAIVA GADELHA  FIRMININO"
          /> */}
        </LayoutHeader>
        <LayoutContent>
          <Outlet />
        </LayoutContent>
      </Layout>
    </Container>
  );
};

export default InstitutionLayout;
