import styled from 'styled-components';
import { Collapse } from 'antd';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 70%;
  justify-content: center;
`;

const Categories = styled(Collapse)`
  width: auto;
  min-width: 60%;
  background-color: #ffff;
  user-select: none;
  @media (max-width: 1500px) {
    min-width: 80%;
  }
`;

const CategoriesContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
`;

export { Container, Categories, CategoriesContent };
