import React, { useState } from 'react';
import { Input, Form } from 'antd';
import IconEMAIL from '../../../assets/img/email.svg';
import {
  Container,
  Title,
  Text,
  RegisterButton,
  Field,
  ErrorAlert,
} from './styles';
import { Api } from '../../../services/api';
import { useLocation, useNavigate } from 'react-router-dom';

const RegisterVerification: React.FC = () => {
  const [code, setCode] = useState('');
  const [codeError, setCodeError] = useState(false);
  const { state } = useLocation();
  const navigate = useNavigate();

  const handleSubmit = async () => {
    await Api.post('/general/confirmation-code', {
      email: state.email,
      code,
    })
      .then(resp => {
        if (resp.status === 200) navigate('/register-confirm');
        else setCodeError(true);
      })
      .catch(() => {
        setCodeError(true);
      });
  };

  return (
    <Container>
      <img alt="" src={IconEMAIL} />
      <Title>Verifique seu email para confirmar o cadastro!</Title>
      <Text>
        Parabéns! Você está a um passo de tornar nossa comunidade escolar ainda
        mais segura. Acesse seu email para confirmar o cadastro e aproveitar
        todos os benefícios do nosso sistema de proteção aos alunos.
      </Text>
      <Field>
        {codeError ? (
          <ErrorAlert
            message="Código inválido"
            type="error"
            showIcon
            closable
            onClose={() => {
              setCodeError(false);
            }}
          />
        ) : null}
        <Form.Item>
          <Input
            size="large"
            placeholder="Digite seu código aqui"
            onChange={e => {
              setCode(e.target.value);
            }}
          />
        </Form.Item>
        <Form.Item>
          <RegisterButton
            type="primary"
            size="large"
            onClick={async () => {
              await handleSubmit();
            }}
          >
            Confirmar código
          </RegisterButton>
        </Form.Item>
      </Field>
    </Container>
  );
};

export default RegisterVerification;
