import styled from 'styled-components';
import { Modal, Form, Input, Button, Avatar, Divider } from 'antd';
import { FileTextOutlined, PrinterOutlined } from '@ant-design/icons';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 180px;
  border: solid;
  border-width: 0.2px;
  border-radius: 5px;
  border-color: #c5c5c5;
  padding: 5%;
  scale: 1;
  transition: 0.1s;
  margin-bottom: 25%;
  &:hover {
    scale: 1.1;
  }
`;

const Title = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-weight: 600;
  font-size: 14px;
  @media (max-width: 1500px) {
    font-size: 13px;
  }
`;

const TypeCard = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Line = styled(Divider)`
  background-color: #000;
`;

const EditModal = styled(FileTextOutlined)`
  scale: 1;
  transition: 0.1s;
  font-size: 16px !important;
  margin-left: 10px;
  &:hover {
    scale: 1.1;
  }
`;

const PrintIcon = styled(PrinterOutlined)`
  scale: 1;
  transition: 0.1s;
  font-size: 18px !important;
  margin-left: 10px;
  &:hover {
    scale: 1.1;
  }
`;

const ContentDate = styled.div`
  display: flex;
  flex-direction: column;
`;

const DateLabel = styled.p`
  font-weight: 600;
`;

const ContentName = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const AvatarContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ContentDescription = styled.div`
  height: 20%;
`;

const Description = styled.div`
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  white-space: pre-wrap;
  color: #5f5a59;
`;

const ModalButton = styled(Button)`
  background-color: #00b2ff !important;
`;

const ViewModal = styled(Modal)`
  .ant-modal-title {
    display: flex;
    justify-content: center;
  }
  .ant-modal-content {
    display: flex;
    flex-direction: column;
  }
`;
const ModalInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1%;
`;

const ModalLabel = styled.p`
  font-weight: 600;
  font-size: 13px;
`;

const Info = styled.p`
  color: #666666;
  font-size: 15px;
`;

const ModalForm = styled(Form)`
  display: flex;
  flex-direction: column;
  margin-top: 5%;
`;

const FormItem = styled(Form.Item)`
  font-weight: 600;
`;

const TextArea = styled(Input.TextArea)`
  width: 100%;
  resize: none;
`;

const ContainerButton = styled(Form.Item)`
  display: flex;
  justify-content: center;
  margin: 0;
`;

const SaveButton = styled(Button)`
  background-color: #33c455 !important;
  width: 200px;
`;

const UserAvatar = styled(Avatar)``;

const GroupAvatarColor = {
  backgroundColor: '#0033cc',
};

export {
  Container,
  Title,
  TypeCard,
  Line,
  EditModal,
  PrintIcon,
  ContentDate,
  DateLabel,
  ContentName,
  AvatarContent,
  ContentDescription,
  Description,
  ModalButton,
  ViewModal,
  ModalInfo,
  ModalLabel,
  Info,
  ModalForm,
  FormItem,
  TextArea,
  ContainerButton,
  SaveButton,
  UserAvatar,
  GroupAvatarColor,
};
