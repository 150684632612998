import styled from 'styled-components';
import { Button, Form } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const Container = styled.div`
  display: flex;
  width: 50%;
  justify-content: center;
  flex-direction: column;
`;

const Loading = styled(LoadingOutlined)`
  font-size: 64px !important;
  color: #000;
`;

const EditForm = styled(Form)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
  margin-top: 3%;
  .ant-form-item {
    width: 100%;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: row;
  width: 70%;
  @media (max-width: 1500px) {
    width: 90%;
  }
`;

const FormItem = styled(Form.Item)`
  margin-right: 2%;
  margin-left: 2%;
  margin-bottom: 5%;
  .ant-form-item-label {
    padding: 0;
  }
  .ant-form-item-label * {
    font-weight: 600;
  }
`;

const ContainerButton = styled.div`
  display: flex;
  justify-content: center;
`;

const SaveButton = styled(Button)`
  display: flex;
  width: 50%;
  align-items: center;
  justify-content: center;
  background-color: #33c455 !important;
`;

export {
  Container,
  Loading,
  Column,
  EditForm,
  FormItem,
  ContainerButton,
  SaveButton,
};
