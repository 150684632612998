import { FloatButton, InputNumber } from 'antd';
import styled from 'styled-components';
import { LoadingOutlined } from '@ant-design/icons';

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Loading = styled(LoadingOutlined)`
  font-size: 64px !important;
  color: #000;
`;

const DefinitionsContent = styled.div`
  display: grid;
  grid-template-columns: 33% 33% 33%;
  width: 90%;
  height: 70%;
  align-items: center;
  justify-content: center;
`;

const CheckButton = styled(FloatButton)`
  right: 5%;
  .ant-float-btn-body {
    background-color: #33c455 !important;
  }
`;

const TriggerLocationContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const InputsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5%;
  width: 100%;
`;

const InputLocation = styled(InputNumber)`
  width: 100%;
`;

export {
  Container,
  Loading,
  DefinitionsContent,
  CheckButton,
  TriggerLocationContent,
  InputsContainer,
  InputLocation,
};
